import React from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, Card, CardActionArea, CardContent, Typography, Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from "@material-ui/lab";
import { checkNotifications, fetchAllNotifications } from '../../redux/actions/notificationsActions';
import '../../assets/css/online-inventory.css';
import { fetchInventoryHistory } from '../../redux/actions/inventoryHistoryActions';
import { faEnvelope, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class InventoryHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            textDialog: '',
            page: 1,
            loading: '',
            heightWindow: window.innerHeight - 200,
        }
    }

    componentDidMount() {
        this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: this.state.page, mount: true }));
        this.props.dispatch(fetchInventoryHistory({ user_susi_id: this.props.user.user_susi_id, mount: false }));
    }

    toggleDialog = async (notification, text, read) => {
        let currentState = this.state.dialogOpen;
        this.setState({ dialogOpen: !currentState, textDialog: text });
        if (read === 0) {
            await this.checkRead(notification);
        }
    };

    checkRead = async (notification) => {
        await checkNotifications({
            user_susi_id: this.props.user.user_susi_id,
            notification: notification
        })
        this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1, mount: false }));
    };

    fetchMore = () => {
        setTimeout(() => {
            if (this.state.page) {
                return (this.setState({ loading: " " }));
            }

            var currentState = this.state.page;
            this.setState({ page: currentState++ },
                this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: this.state.page, mount: false }))
            );
        }, 1500);
    }

    render() {

        window.addEventListener("resize", () => {
            if ((window.innerHeight - 200) != this.state.heightWindow) {
                this.setState({ heightWindow: window.innerHeight - 200 })
            }
        })

        return (
            <div className='contenedorNotificaciones'>
                <Dialog className='dialogNotifications'
                    open={this.state.dialogOpen}>
                    <DialogContent style={{ color: '#80849D' }}>
                        <Typography style={{ fontSize: '0.875rem' }}>Señor usuario</Typography>
                        {this.state.textDialog.split('\n').map((text, key) => {
                            return (
                                <Typography dangerouslySetInnerHTML={{ __html: text }} key={key} style={{ marginTop: '20px', fontSize: '0.875rem' }} />
                            )
                        })
                        }
                    </DialogContent>
                    <DialogActions style={{ paddingRight: '1.5rem' }}>
                        <Button className='btnPrimary' onClick={() => this.setState({ dialogOpen: false })} color="primary">CERRAR</Button>
                    </DialogActions>
                </Dialog>
                <h6 className='reportTittle'>NOTIFICACIONES</h6>
                {!this.props.notify.mount && this.props.notify.notifys.length > 0 ?
                    <div className='listaNotificaiones'>
                        <InfiniteScroll
                            dataLength={this.props.notify.notifys.length}
                            hasMore={this.props.notify.hasMore}
                            className="InfiniteScroll"
                            next={this.fetchMore}
                            height={this.state.heightWindow}
                            loader={
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <CircularProgress className="circular-progress" />
                                </div>
                            }
                        >
                            {this.props.notify.notifys.map((item, cont) => {
                                return (
                                    <Card key={item.codeNotification} style={item.read == 1 ?
                                        {
                                            color: '#80849D', backgroundColor: '#FDFDFF', marginBottom: '15px',
                                            border: '0', borderRadius: '1rem', boxShadow: '3px 3px 20px rgb(214, 224, 244, 30%)'
                                        } :
                                        {
                                            color: '#5F6273', backgroundColor: '#FFFFFF', marginBottom: '15px',
                                            border: '0', borderRadius: '1rem', boxShadow: '3px 3px 20px rgb(214, 224, 244, 30%)'
                                        }}>
                                        <CardActionArea className='notificacion' onClick={() => this.toggleDialog(item.codeNotification, item.message, item.read)}>
                                            <Grid item container xs>
                                                <Grid container className='contenedorMensaje' justifyContent='space-evenly'>
                                                    <Grid item xs={1} lg="auto">
                                                        {(item.read == 1) ?
                                                            <CardContent style={{ padding: '0', textAlign: 'center' }}>
                                                                <FontAwesomeIcon style={{ color: '#9E9FA8' }} icon={faEnvelopeOpen} size="2x" />
                                                            </CardContent>
                                                            :
                                                            <CardContent style={{ padding: '0', textAlign: 'center' }}>
                                                                <FontAwesomeIcon style={{ color: '#6CE1F5' }} icon={faEnvelope} size="2x" />
                                                            </CardContent>
                                                        }
                                                    </Grid>

                                                    <Grid item xs sm lg={11} container justifyContent='space-between' className='mensajeTituloP'>
                                                        <Grid item xs={12} sm lg>
                                                            <Typography gutterBottom className='tittleNotification' >
                                                                {item.title}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} sm="auto" lg="auto">
                                                            <Typography variant="body2" className='fechaNotificacion'>
                                                                {new Intl.DateTimeFormat('es-Es', { dateStyle: 'long', timeStyle: 'short', hour12: true }).format(new Date(item.dateAt))}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} className='mensajePrincipal'>
                                                            <Typography dangerouslySetInnerHTML={{ __html: item.message }} className='contentMensaje' variant="body2" color="inherit" component="p" />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} className='mensajeResponsive'>
                                                        <Typography dangerouslySetInnerHTML={{ __html: item.message }} className='contentMensaje' variant="body2" color="inherit" component="p" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardActionArea>
                                    </Card>
                                )
                            })}
                        </InfiniteScroll>
                    </div> : this.props.notify.mount ?
                        <div style={{ display: "flex", justifyContent: "center", marginTop: '1rem' }}>
                            <CircularProgress className="circular-progress" />
                        </div>
                        : <div className="container" style={{ padding: "20px" }}>
                            <Alert severity="info">
                                <AlertTitle>Info</AlertTitle>
                                No hay notificaciones nuevas.
                            </Alert>
                        </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    notify: state.callNotifys,
    outList: state.withoutFillingOut,
    assignedAssets: state.assignedAssets,
    user: state.user,
    locations: state.locations.locations,
    assetsStatus: state.SUSILists.assetsStatus,
    changesTypes: state.SUSILists.changesTypes,
    missingReasons: state.SUSILists.missingReasons,
    sectionalsUser: state.sectionals,
    stages: state.stages,
    SyncSIAF: state.syncSIAF.isFetching,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(InventoryHistory)