import { combineReducers } from "redux";
import assignedAssets from "./assignedAssetsReducer";
import assetsCategories from "./assetsCategoriesReducer";
import SUSILists from "./SUSIListsReducer";
import user from "./userReducer";
import locations from "./locationsReducer";
import dashboard from "./dashboardReducer";
import members from "./membersReducer";
import withoutFillingOut from "./withoutFillingOutReducer";
import sectionals from "./sectionalsReducer";
import stages from './assetStageReducer';
import syncSIAF from "./syncWithSIAFReducer";
import getActualParams from './paramsSearchReducer';
import inventoryHistory from './inventoryHistoryReducer';
import evaluations from './evaluationsReducer';
import evaluators from './evaluatorReducer';
import score from './scoreReducer';
import callNotifys from './notificationsReducer'
import assetSurplus from "./assetSurplusReducer";
import listSurplus from "./surplusListReducer";
import surplusSearched from "./surplusSearched";
import getUserToken from "./loginSessionReducer";
import missingReducer from "./missingReducer.js";
import reportMissingReducer from "./reportMissingReducer";
import filesReducer from "./filesReducer";
import dataUserReducer from "./dataUserReducer";

export default combineReducers({
    assignedAssets, assetsCategories, user, SUSILists, locations, dashboard, members, withoutFillingOut, sectionals, stages, syncSIAF, 
    getActualParams, inventoryHistory, evaluations, evaluators, score, callNotifys, assetSurplus, listSurplus, surplusSearched, getUserToken, 
    missingReducer, reportMissingReducer, filesReducer, dataUserReducer
})