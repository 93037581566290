import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import {
  List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, RadioGroup, FormControlLabel,
  Radio, Button, TextField, Grid, withStyles, CircularProgress
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { connect } from "react-redux";
import { fetchInventoryHistory } from "../../redux/actions/inventoryHistoryActions";
import "../../assets/css/online-inventory.css";
import { getCertificate } from "../../redux/actions/assignedAssetActions";
import { fetchAssetsStatus, fetchChangesTypes, fetchMissingReasons, getIdOptionSUSIList, getNameOptionSUSIList } from "../../redux/actions/SUSIListsActions";
import { DocumentService } from "../../services/generateDocumentService";
import { pdf } from "@react-pdf/renderer";
import { fetchAssetStages } from "../../redux/actions/assetStagesActions";
import { fetchLocations } from "../../redux/actions/locationsActions";
import { fetchSectionalsUser } from "../../redux/actions/sectionalsActions";
import { fetchAllNotifications } from "../../redux/actions/notificationsActions";
import { Label } from "@material-ui/icons";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {DOCUMENTS_FOLDER} from "../../config";


const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5',
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class InventoryHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textSearch: "",
      openDialogSort: false,
      openDialogFilter: false,
      valueSort: 'ASC',
      valueFilter: '',
      optionSelected: null,
      filterApplied: false,
      orderBy: 'articleTitle',
      order: 'asc',
      filterText: '',
      filteredData: [],
      paramsFetchSectionals: {
        user_susi_id: props.user.user_susi_id,
      },
      clean: false,
      openView: false,
      pdfBlobUrl: '',
      selectedFileIndex: 0
    };
  }

  componentDidMount() {
    console.log("TLA en toreport (inventoryhistory 56");
    this.getInformation();
    //this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
    this.props.dispatch(fetchAssetsStatus());
    this.props.dispatch(fetchChangesTypes());
    this.props.dispatch(fetchAssetStages());
    this.props.dispatch(fetchMissingReasons());
    this.props.dispatch(fetchLocations());
    this.props.dispatch(fetchSectionalsUser(this.state.paramsFetchSectionals));
    this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1 }));
  }

  getInformation = () => {
    const params = {
      idUser: this.props.user.numero_documento,
      order: this.state.valueSort,
      vigencia: this.state.valueFilter
    };
    this.props.dispatch(fetchInventoryHistory(params));
  }


  componentDidUpdate() {
    if (this.state.clean) {
      this.setState({ clean: false });
      this.appliedFilter()
    }
  }



  handleSortRequest = (property) => {
    const orderBy = property;
    let order = 'desc';
  
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
  
    this.setState({ order, orderBy });
  };
  
  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };


  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };
  
  handleFilterChange = (event) => {
    const filterText = event.target.value.toLowerCase(); // Convertir a minúsculas
  
    const { history } = this.props.historyList;
  
    if (!Array.isArray(history)) {
      return;
    }
  
    this.setState({
      filterText: filterText
    }, () => {
      const filteredData = history.filter((item) => {
        return (
          item.articleTitle.toLowerCase().includes(this.state.filterText) ||
          item.inscriptionDate.toLowerCase().includes(this.state.filterText) ||
          item.competitionId.toString().includes(this.state.filterText) // Convertir a cadena de texto
        );
      });
  
      this.setState({
        filteredData
      });
    });
  };

  downloadFile = async (row) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const filePaths = [];

    // PDF principal
    if (row.inscriptionId) {
        const pdfPath = `${process.env.PUBLIC_URL}${DOCUMENTS_FOLDER}${currentYear}/ArticlePdf_${row.inscriptionId}.pdf`;
        filePaths.push(pdfPath);
    }

    // Anexo 1
    if (row.articleFile1) {
        const extAnexo1 = row.articleFile1.substring(row.articleFile1.lastIndexOf('.') + 1);
        const anexo1Path = `${process.env.PUBLIC_URL}${DOCUMENTS_FOLDER}${currentYear}/Anexo1_${row.inscriptionId}.${extAnexo1}`;
        filePaths.push(anexo1Path);
    }

    // Anexo 2
    if (row.articleFile2) {
        const extAnexo2 = row.articleFile2.substring(row.articleFile2.lastIndexOf('.') + 1);
        const anexo2Path = `${process.env.PUBLIC_URL}${DOCUMENTS_FOLDER}${currentYear}/Anexo2_${row.inscriptionId}.${extAnexo2}`;
        filePaths.push(anexo2Path);
    }

    // Anexo 3
    if (row.articleFile3) {
        const extAnexo3 = row.articleFile3.substring(row.articleFile3.lastIndexOf('.') + 1);
        const anexo3Path = `${process.env.PUBLIC_URL}${DOCUMENTS_FOLDER}${currentYear}/Anexo3_${row.inscriptionId}.${extAnexo3}`;
        filePaths.push(anexo3Path);
    }

    // Anexo 4
    if (row.articleFile4) {
        const extAnexo4 = row.articleFile4.substring(row.articleFile4.lastIndexOf('.') + 1);
        const anexo4Path = `${process.env.PUBLIC_URL}${DOCUMENTS_FOLDER}${currentYear}/Anexo4_${row.inscriptionId}.${extAnexo4}`;
        filePaths.push(anexo4Path);
    }

  try {
    // Descargar los archivos como Blob
    const fileResponses = await Promise.all(filePaths.map(path => fetch(path)));
    
    // Convertir las respuestas a Blob y obtener el tipo MIME
    const fileBlobs = await Promise.all(fileResponses.map(response => response.blob()));
    
    // Obtener las URLs y los tipos MIME
    const fileData = fileBlobs.map(blob => ({
      url: URL.createObjectURL(blob),
      type: blob.type  // MIME type (e.g., "application/pdf", "image/jpeg", "application/vnd.ms-excel")
    }));

    // Actualizar el estado con los archivos descargados
    this.setState({ fileData });

  } catch (error) {
    console.error("Error al descargar los archivos: ", error);
  }

  }


  handleOpenView = (row) => {
    this.downloadFile(row);
    this.setState({
      openView: true
    });
  };

  handleCloseView = () => {
    this.setState({
      openView: false
    });
  };

  handleEditar = (inscriptionId) => {
    this.props.history.push({
      pathname: '/',
      search: inscriptionId ? `?pendienteId=${inscriptionId}` : ''
    });
  };

    // Método para ir al archivo siguiente
    handleNextFile = () => {
      this.setState((prevState) => ({
        selectedFileIndex: (prevState.selectedFileIndex + 1) % this.state.fileData.length,
      }));
    };
  
    // Método para ir al archivo anterior
    handlePrevFile = () => {
      this.setState((prevState) => ({
        selectedFileIndex: (prevState.selectedFileIndex - 1 + this.state.fileData.length) % this.state.fileData.length,
      }));
    };

  render() {
    const { historyList } = this.props;
    const { order, orderBy, filterText, filteredData } = this.state;
    const dataToRender = filterText ? filteredData : historyList.history;
    const { selectedFileIndex } = this.state;
    const currentFile = this.state.fileData && this.state.fileData.length > 0 ? this.state.fileData[selectedFileIndex] : null;

    return (
      <div style={{ maxHeight: '600px', overflowX: 'auto',  overflowY: 'auto' }}>
        <h5 style={{ fontSize: '1.375rem', fontWeight: '800', color: '#5f6273' }}>INSCRIPCIONES REALIZADAS</h5>

        <TextField
        label="Filtrar"
        value={filterText}
        onChange={this.handleFilterChange}
        style={{ marginBottom: '1rem' }}
      />

      {dataToRender.length > 0 ? (
            <Table responsive={true} style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'articleTitle'}
                    direction={orderBy === 'articleTitle' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('articleTitle')}
                  >
                    Título del Trabajo
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'inscriptionDate'}
                    direction={orderBy === 'inscriptionDate' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('inscriptionDate')}
                  >
                    Fecha-Hora
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'inscriptionId'}
                    direction={orderBy === 'inscriptionId' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('inscriptionId')}
                  >
                    Concurso-Inscripción
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {this.stableSort(dataToRender, this.getComparator(order, orderBy)).map((row) => (
              <TableRow key={row.inscriptionId}>
                <TableCell>{row.articleTitle}</TableCell>
                <TableCell>{row.inscriptionDate}</TableCell>
                <TableCell>{row.competitionId}-{row.inscriptionId}</TableCell>
                <TableCell align="center">
                  <IconButton aria-label="editar" size="small" onClick={() => this.handleEditar(row.inscriptionId)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell> 
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={() => this.handleOpenView(row)}>
                    <VisibilityIcon fontSize="large" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>

                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <StarHalfIcon fontSize="large" />
                  </IconButton>
                </TableCell>
                {/* <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <GetAppIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <AssignmentTurnedInIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <AssessmentIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <StarHalfIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Button variant="contained" color="secondary" onClick={() => this.handlePrint(row.inscriptionId)}>
                    Imprimir
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
          </Table>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No se econtraron Inscripciones.
            </Alert>
          )}



{/* DIALOGO DE VISUALIZACIÓN */}
<Dialog  
          PaperProps={{
            style: {
              paddingBottom: '0 !important',
              overflowY: 'auto',
              maxHeight: '90vh',
              width: '80vw',          // Ancho al 100% de la ventana del navegador
              maxWidth: 'none !important',  // Sin límite de ancho máximo
              opacity: '1 !important',              // Desactivar la opacidad
              // scrollbarWidth: 'auto',  // Ancho del scrollbar
              // scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
            }
          }} 
          fullWidth={false}
          maxWidth={false}
          className='dialogReounded dialogUbication' 
          open={this.state.openView} 
          aria-labelledby="form-dialog-title"
        >
            <DialogTitle className='dialogTttle' id="alert-dialog-title">TRABAJO INSCRITO</DialogTitle>

            <DialogContent style={{ paddingLeft: '2rem', overflowY: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        {/* Botón para archivo anterior con ícono de flecha atrás */}
        <IconButton onClick={this.handlePrevFile}>
          <ArrowBackIcon />
        </IconButton>
        
        {/* Botón para archivo siguiente con ícono de flecha adelante */}
        <IconButton onClick={this.handleNextFile}>
          <ArrowForwardIcon />
        </IconButton>
      </div>

  <DialogContentText id="alert-dialog-description">
    {this.state.fileData && this.state.fileData.length > 0 && (
      <div style={{ marginBottom: '1.5rem' }}>
        {/* Obtener el archivo actual basado en selectedFileIndex */}
        

        {/* Si es un PDF, renderizar iframe */}
        {currentFile.type === 'application/pdf' && (
          <>
            <h3>Documento/Anexo {this.state.selectedFileIndex + 1}</h3>
            <iframe 
              src={currentFile.url} 
              width="100%" 
              height="600px" 
              title={`PDF Viewer ${this.state.selectedFileIndex + 1}`}>
            </iframe>
          </>
        )}

        {/* Si es una imagen, renderizar img */}
        {currentFile.type.startsWith('image/') && (
          <>
            <h3>Documento/Anexo {this.state.selectedFileIndex + 1}</h3>
            <img 
              src={currentFile.url} 
              alt={`Imagen ${this.state.selectedFileIndex + 1}`} 
              width="100%" 
              style={{ maxHeight: '400px', objectFit: 'contain' }}
            />
          </>
        )}

        {/* Si es un Excel, ofrecer enlace de descarga */}
        {(currentFile.type === 'application/vnd.ms-excel' || currentFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && (
          <>
            <h3>Documento/Anexo {this.state.selectedFileIndex + 1}</h3>
            <a 
              href={currentFile.url} 
              download={`Archivo_${this.state.selectedFileIndex + 1}.xls`} 
              style={{ color: 'blue', textDecoration: 'underline' }}>
              Descargar Excel {this.state.selectedFileIndex + 1}
            </a>
          </>
        )}
      </div>
    )}
  </DialogContentText>
</DialogContent>





            <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
              <Button className='btnPrimary' onClick={this.handleCloseView} color="primary">
                Salir
              </Button>
            </DialogActions>
          </Dialog>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  historyList: state.inventoryHistory,
  params: state.getActualParams,
  withoutFillingOuts: state.withoutFillingOut,
  user: state.user,
  assignedAssets: state.assignedAssets,
  locations: state.locations.locations,
  assetsStatus: state.SUSILists.assetsStatus,
  changesTypes: state.SUSILists.changesTypes,
  missingReasons: state.SUSILists.missingReasons,
  sectionalsUser: state.sectionals,
  stages: state.stages,
  SyncSIAF: state.syncSIAF.isFetching,
})
export default connect(mapStateToProps, null)(withRouter(InventoryHistory));

