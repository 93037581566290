import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createScore, getScore } from "../../redux/actions/scoreAction";
import { updateState } from "../../redux/actions/inscriptionAction";
import { Tooltip } from '@material-ui/core';
import {
  List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, RadioGroup, FormControlLabel,
  Radio, Button, TextField, Grid, withStyles, CircularProgress
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import SearchIcon from '@material-ui/icons/Search';
import DoneAllTwoToneIcon from '@material-ui/icons/DoneAllTwoTone';
import SaveIcon from '@material-ui/icons/Save';
import EmailIcon from '@material-ui/icons/Email';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import Send from '@material-ui/icons/Send';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Star from '@material-ui/icons/Star';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import CancelScheduleSendTwoToneIcon from '@material-ui/icons/CancelScheduleSendTwoTone';
import StarsTwoToneIcon from '@material-ui/icons/StarsTwoTone';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Alert, AlertTitle } from "@material-ui/lab";
import React from 'react';
import { connect } from "react-redux";
import { fetchInventoryHistory } from "../../redux/actions/inventoryHistoryActions";
import { fetchEvaluations } from "../../redux/actions/evaluationsActions";
import { fetchScore } from "../../redux/actions/scoreActions";
import "../../assets/css/online-inventory.css";
import { getCertificate } from "../../redux/actions/assignedAssetActions";
import { fetchAssetsStatus, fetchChangesTypes, fetchMissingReasons, getIdOptionSUSIList, getNameOptionSUSIList } from "../../redux/actions/SUSIListsActions";
import { DocumentService } from "../../services/generateDocumentService";
import { pdf } from "@react-pdf/renderer";
import { fetchAssetStages } from "../../redux/actions/assetStagesActions";
import { fetchLocations } from "../../redux/actions/locationsActions";
import { fetchSectionalsUser } from "../../redux/actions/sectionalsActions";
import { fetchAllNotifications } from "../../redux/actions/notificationsActions";
import { Label } from "@material-ui/icons";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Slider from '@material-ui/core/Slider';
import {DOCUMENTS_FOLDER} from "../../config";


const CustomSlider = withStyles({
  mark: {
    backgroundColor: 'transparent',
    height: 8,
    width: 8,
    border: '2px solid #3f51b5',
    borderRadius: '50%',
    marginTop: -3,
  },
  markActive: {
    backgroundColor: '#3f51b5',
  },
})(Slider);

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5',
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class InventoryLeftOver extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textSearch: "",
      openDialogSort: false,
      openDialogFilter: false,
      valueSort: 'ASC',
      valueFilter: '',
      optionSelected: null,
      filterApplied: false,
      orderBy: 'articleTitle',
      order: 'asc',
      filterText: '',
      filteredData: [],
      openView: false,
      openAnex: false,
      openScore: false,
      newScore : [],
      idUser: props.user.user_susi_id,
      clean: false,
      pdfBlobUrl: '',
      selectedFileIndex: 0,
      totalScore:0
    };
  }

  componentDidMount() {
    console.log("TLA en toreport (inventoryhistory 56");
    this.getInformation();
    this.setState({ groupedItems: this.props.groupedItems }, this.updateTotal);
    //this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
    // this.props.dispatch(fetchAssetsStatus());
    // this.props.dispatch(fetchChangesTypes());
    // this.props.dispatch(fetchAssetStages());
    // this.props.dispatch(fetchMissingReasons());
    // this.props.dispatch(fetchLocations());
    // this.props.dispatch(fetchSectionalsUser(this.state.paramsFetchSectionals));
    this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1 }));
  }

  getInformation = () => {
    const params = {
      idUser: this.props.user.numero_documento,
      order: this.state.valueSort,
      vigencia: this.state.valueFilter
    };
    //this.props.dispatch(fetchEvaluations(params));
    this.props.dispatch(fetchScore(params));
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.state.clean) {
      this.setState({ clean: false });
      this.appliedFilter()
    }

  }


  handleSortRequest = (property) => {
    const orderBy = property;
    let order = 'desc';
  
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
  
    this.setState({ order, orderBy });
  };
  
  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };


  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };
  
  handleFilterChange = (event) => {
    const filterText = event.target.value.toLowerCase(); // Convertir a minúsculas
  
    const { evaluations } = this.props.evaluationsList;
  
    if (!Array.isArray(evaluations)) {
      return;
    }
  
    this.setState({
      filterText: filterText
    }, () => {
      const filteredData = evaluations.filter((item) => {
        return (
          item.articleTitle.toLowerCase().includes(this.state.filterText) ||
          item.inscriptionDate.toLowerCase().includes(this.state.filterText) ||
          item.competitionId.toString().includes(this.state.filterText) // Convertir a cadena de texto
        );
      });
  
      this.setState({
        filteredData
      });
    });
  };

  downloadFile = async (row) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const filePaths  = [];
    const filePathsA = [];

    // PDF principal
    if (row.inscriptionId) {
        const pdfPath = `${process.env.PUBLIC_URL}${DOCUMENTS_FOLDER}${currentYear}/SummaryPdf_${row.inscriptionId}.pdf`;
        filePaths.push(pdfPath);
    }

    // Anexo 1
    if (row.articleFile1) {
        const extAnexo1 = row.articleFile1.substring(row.articleFile1.lastIndexOf('.') + 1);
        const anexo1Path = `${process.env.PUBLIC_URL}${DOCUMENTS_FOLDER}${currentYear}/Anexo1_${row.inscriptionId}.${extAnexo1}`;
        filePathsA.push(anexo1Path);
    }

    // Anexo 2
    if (row.articleFile2) {
        const extAnexo2 = row.articleFile2.substring(row.articleFile2.lastIndexOf('.') + 1);
        const anexo2Path = `${process.env.PUBLIC_URL}${DOCUMENTS_FOLDER}${currentYear}/Anexo2_${row.inscriptionId}.${extAnexo2}`;
        filePathsA.push(anexo2Path);
    }

  try {
    // Descargar los archivos como Blob
    const fileResponses = await Promise.all(filePaths.map(path => fetch(path)));
    const fileResponsesA = await Promise.all(filePathsA.map(path => fetch(path)));
    
    // Convertir las respuestas a Blob y obtener el tipo MIME
    const fileBlobs = await Promise.all(fileResponses.map(response => response.blob()));
    const fileBlobsA = await Promise.all(fileResponsesA.map(response => response.blob()));
    
    // Obtener las URLs y los tipos MIME
    const fileData = fileBlobs.map(blob => ({
      url: URL.createObjectURL(blob),
      type: blob.type  // MIME type (e.g., "application/pdf", "image/jpeg", "application/vnd.ms-excel")
    }));
    const fileDataA = fileBlobsA.map(blob => ({
      url: URL.createObjectURL(blob),
      type: blob.type  // MIME type (e.g., "application/pdf", "image/jpeg", "application/vnd.ms-excel")
    }));

    // Actualizar el estado con los archivos descargados
    this.setState({ fileData });
    this.setState({ fileDataA });
    console.log("FICHEROS");
    console.log("resumen", fileData);
    console.log("amigos", fileDataA);

  } catch (error) {
    console.error("Error al descargar los archivos: ", error);
  }

  }


  handleOpenView = (row) => {
    this.downloadFile(row);
    this.setState({
      openView: true, selectedInscriptionId: row.inscriptionId
    });
    const newScore = this.props.scoreList.score.filter((score) => score.inscriptionId == row.inscriptionId);
    this.setState({ newScore: newScore });
  };

  handleCloseView = () => {
    this.setState({
      openView: false
    });
  };

  handleOpenAnex = () => {
    this.setState({
      openAnex: true
    });
  };

  handleCloseAnex = () => {
    this.setState({
      openAnex: false
    });
  };


  handleOpenScore = (inscriptionId) => {
    this.setState({ openScore: true, selectedInscriptionId: inscriptionId });
    const newScore = this.props.scoreList.score.filter((score) => score.inscriptionId == inscriptionId);
    this.setState({ newScore: newScore });
  };

  handleState = (inscriptionId, newState) => {
    // Lógica para guardar la página o los datos
    console.log("Guardando los datos...");
    console.log(inscriptionId);
    updateState(inscriptionId,this.props.user.user_susi_id,newState)
      .then(dataAssign => {
        if (dataAssign) {
          console.log("actualización estado realizado con éxito");
          console.log(dataAssign);
          this.props.dispatch(fetchEvaluations({ idUser: this.props.user.user_susi_id }));
        }
      })
      .catch(error => {
        console.error("Error en la actualización estado:", error);
      });
      

  };

  // updateTotal = () => {
  //   console.log("CALCULANDO *****************");
  //   let total = 0;
  //   console.log(this.state.groupedItems);
  //   // Verifica que groupedItems esté definido y sea un array
  //   if (Array.isArray(this.state.groupedItems)) {
  //     this.state.groupedItems.forEach(group => {
  //       if (Array.isArray(group.items)) { // Verifica que items sea un array
  //         group.items.forEach(item => {
  //           if (item.haveScore == 1) {
  //             total += item.sliderValue || 0; // Sumar los valores de los sliders
  //           }
  //         });
  //       }
  //     });
  //   }
  //   console.log(total);
  //   this.setState({ totalScore: total }); // Actualiza el estado del total
  // };

  updateTotal = () => {
    console.log("CALCULANDO *****************");
    let total = 0;
  
    // Verifica que items esté definido y sea un array
    if (Array.isArray(this.state.items)) {
      // Sumar directamente los valores de todos los items
      this.state.items.forEach(item => {
        if (item.haveScore === 1) {
          total += item.sliderValue || 0; // Sumar los valores de los sliders
        }
      });
    }
  
    console.log(total);
    this.setState({ totalScore: total }); // Actualiza el estado del total
  };

handleSliderChange = (groupId, itemIndex, newValue) => {
  // Clonar el estado actual de newScore
  const updatedScore = [...this.state.newScore];
  // Encontrar el índice del elemento dentro del grupo correspondiente
  const itemInGroupIndex = updatedScore.findIndex((item) => item.groupId == groupId && item.formOrderItem == itemIndex);
  
  if (itemInGroupIndex != -1) {
    // Actualizar el valor del slider correspondiente utilizando el índice dentro del grupo
    this.setState({ totalScore: this.state.totalScore - updatedScore[itemInGroupIndex].sliderValue});
    updatedScore[itemInGroupIndex].sliderValue = newValue;
    this.setState({ totalScore: this.state.totalScore + newValue});
    console.log("TOTAL");
    console.log(this.state.totalScore);
    // Actualizar el estado con el nuevo valor del slider
    this.updateTotal();
  }
};


handleInputChange = (groupId, itemIndex, event) => {
    // Clonar el estado actual de newScore
    const updatedScore = [...this.state.newScore];
    //   // Encontrar el índice del elemento dentro del grupo correspondiente
    const itemInGroupIndex = updatedScore.findIndex((item) => item.groupId == groupId & item.formOrderItem == itemIndex);
    console.log(itemInGroupIndex);
    if (itemInGroupIndex != -1) {
      // Actualizar el valor del slider correspondiente utilizando el índice dentro del grupo
      updatedScore[itemInGroupIndex].inputValue = event.target.value;
      console.log(updatedScore);
      // Actualizar el estado con el nuevo valor del slider
      this.setState({ newScore: updatedScore });
      console.log(this.state.newScore);
      this.state.updateTotal();
    }

    // Encontrar el índice del grupo dentro de newScore
    // const groupIndex = updatedScore.findIndex((item) => item.groupId === groupId);
    // if (groupIndex !== -1) {
    //   // Encontrar el índice del elemento dentro del grupo correspondiente
    //   const itemInGroupIndex = updatedScore[groupIndex].items.findIndex((item) => item.formId === itemIndex);
    //   if (itemInGroupIndex !== -1) {
    //     // Actualizar el valor del textarea correspondiente utilizando el índice dentro del grupo
    //     updatedScore[groupIndex].items[itemInGroupIndex].inputValue = event.target.value;
    //     // Actualizar el estado con el nuevo valor del textarea
    //     this.setState({ newScore: updatedScore });
    //   }
    // }
  };


  handleCloseScore = () => {
    this.setState({ openScore: false, selectedInscriptionId: null });   
  };

  addScore = async () => {
  const allSlidersHaveScore = this.state.newScore.every(score => {
      if (score.dataType == 1) {
          return score.sliderValue != null && score.sliderValue != 0;
      }
      return true;
  });

  if (!allSlidersHaveScore) {
      alert('Por favor, asegúrate de que todos los sliders con dataType 1 tengan un puntaje.');
      return;
  }
    console.log("puntajes");
    console.log(this.state.newScore);
    // const formData = new FormData();
      // Agrega archivos al FormData
    //formData.append('articleImage1', selectedFile1 ? selectedFile1 : null);
    // formData.append('scores', JSON.stringify(this.state.newScore));
  
    let errors = [];
  
    // if (!newRegister.memberIdcard) {
    //   errors.push('Sin identificación del miembro ACHO .');
    // }

  
    if (errors.length == 0 ) {  
        console.log("INSCRIPCION");
  
            //Graba el registro      TLA nuevo graba

            createScore(this.state.newScore)
            .then(dataAssign => {
              if (dataAssign) {
                console.log("asignación realizada con éxito");
                console.log(dataAssign);
                this.getInformation();
              }
            })
            .catch(error => {
              console.error("Error en la asignación de evaluación:", error);
            });


        // let dataInscription= await createScore(formData) 
        // if(dataInscription) {
        //       console.log("INSCRIPCION");
        //       console.log(dataInscription);
        // //ENVIAR CORREO
        //       let email = window.sessionStorage.getItem('_displayMail');
        //       let params = {
        //       subject: 'Inscripción concurso ACHO'.toString('utf8'),
        //       emailEnd: email,
        //       contenido: 'Se ha inscrito satisfactoriamente al concurso',
        //       // inscriptionId: dataInscription['inscriptionId'];
        //       }
        //      // let result = await sendMail(params)
        //      let result = [];
        //       if (result) {
        //         // setEmailAlert(result)
        //         alert('Ha sido enviado a su correo registrado el soporte de inscripción. Favor verifica')
        //       } else {
        //         // setEmail{Alert('')
        //         alert('Ha sido enviado a su correo registrado el soporte de inscripción. Favor verifica')
        //       }
  

            
            // }
            
    } else {
      //state.inscriptionState=0;
      //formData.append('inscriptionState', state.inscriptionState);
      //await createScore(formData)
      // Concatenar todos los errores en una sola cadena de texto
      const errorMessage = errors.join('\n');
      
      // Mostrar mensaje de alerta con todos los errores
      alert(errorMessage);

    }
    this.handleCloseScore();
    this.handleCloseView();
  };

      // Método para ir al archivo siguiente
      // handleNextFile = () => {
      //   this.setState((prevState) => ({
      //     selectedFileIndex: (prevState.selectedFileIndex + 1) % this.state.fileData.length,
      //   }));
      // };
    
      // Método para ir al archivo anterior
      // handlePrevFile = () => {
      //   this.setState((prevState) => ({
      //     selectedFileIndex: (prevState.selectedFileIndex - 1 + this.state.fileData.length) % this.state.fileData.length,
      //   }));
      // };

  render() {
    console.log("estados");
    console.log(this.props);
    const userrole =  window.sessionStorage.getItem('userrole');
    const { selectedFileIndex } = this.state;
    const currentFile = this.state.fileData && this.state.fileData.length > 0 ? this.state.fileData[selectedFileIndex] : null;

    const getRowStyle = (state) => {

      switch (state) {
        case 1:
          return { backgroundColor: '#ffcccc' }; // Rojo claro
        case 2:
          return { backgroundColor: '#ffebcc' }; // Naranja claro
        case 3:
          return { backgroundColor: '#ffffcc' }; // Amarillo claro
        case 4:
          return { backgroundColor: '#ccffcc' }; // Verde claro
        case 5:
          return { backgroundColor: '#cce5ff' }; // Azul claro
        default:
          return {};
      }
    }

        // Función de ayuda para obtener el estado basado en el evaluador actual
    const getStateForUser = (row, idUser) => {
          if (row.evaluatorId1 == idUser) {
            return row.stateId1;
          } else if (row.evaluatorId2 == idUser) {
            return row.stateId2;
          } else if (row.evaluatorId3 == idUser) {
            return row.stateId3;
          }
          return null; // O algún valor predeterminado si no se encuentra
    };

    const { evaluationsList } = this.props;
    const { order, orderBy, filterText, filteredData, openScore, selectedInscriptionId } = this.state; 
    const dataToRender = filterText ? filteredData : evaluationsList.evaluations;



   // Agrupar los ítems por groupId
   const groupedItems = this.state.newScore.reduce((groups, item) => {
    const groupIndex = groups.findIndex(group => group.groupId == item.groupId);
    
    if (groupIndex == -1) {
      groups.push({
        groupId: item.groupId,
        groupDescription: item.groupDescription,
        items: [item]
      });
    } else {
      groups[groupIndex].items.push(item);
    }

    return groups;
  }, []);

  // Ordena los grupos por su orden
  groupedItems.sort((a, b) => a.groupOrder - b.groupOrder);

  // Calcular el total del puntaje por grupo
  // const totalScoreByGroup = groupedItems.map(group => ({
  //   groupId: group.groupId,
  //   totalScore: group.items.reduce((total, item) => total + (item.sliderValue || 0), 0)
  // }));
 
  const totalScore = dataToRender.reduce((total, row) => {
    const sliderValues = ['evaluatorId1', 'evaluatorId2', 'evaluatorId3'].map(evaluatorId => {
      if (row[evaluatorId] === this.state.idUser) {
        return row.sliderValue || 0; // Asegúrate de que la propiedad sliderValue exista
      }
      return 0;
    });
    return total + sliderValues.reduce((sum, value) => sum + value, 0);
  }, 0);

    return (
      <div style={{ overflowX: 'auto', overflowY: 'auto', maxHeight:'800px' }}>
        <h5 style={{ fontSize: '1.375rem', fontWeight: '800', color: '#5f6273' }}>EVALUACIONES ASIGNADAS</h5>

        <TextField
        label="Filtrar"
        value={filterText}
        onChange={this.handleFilterChange}
        style={{ marginBottom: '1rem' }}
      />

      {evaluationsList.isFetching ? (
        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
          <h4 className="sincronizandoTittle">Obteniendo inscripciones a evaluar...</h4>
          <CircularProgress className="circular-progress" />
        </div>
      ) : dataToRender.length > 0 ? (
        <>
            <Table responsive={true} style={{ overflowX: 'auto', overflowY: 'auto', maxHeight:'700px' }}>
            <TableHead>
              <TableRow>
              <TableCell>
                  <TableSortLabel
                    active={orderBy === 'articleShortTitle'}
                    direction={orderBy === 'articleShortTitle' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('articleShortTitle')}
                  >
                    Título corto
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'articleTitle'}
                    direction={orderBy === 'articleTitle' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('articleTitle')}
                  >
                    Título del Trabajo
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'inscriptionDate'}
                    direction={orderBy === 'inscriptionDate' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('inscriptionDate')}
                  >
                    Fecha-Hora
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'inscriptionId'}
                    direction={orderBy === 'inscriptionId' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('inscriptionId')}
                  >
                    Inscripción
                  </TableSortLabel>
                </TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Aceptar Evaluar</TableCell>
                <TableCell>Rechazar Evaluar</TableCell>
                <TableCell>Evaluar</TableCell>
                {userrole > 97 ? (
                <TableCell>Evaluar</TableCell>
                ): null}

              </TableRow>
            </TableHead>
            <TableBody>
            {this.stableSort(dataToRender, this.getComparator(order, orderBy)).map((row) => {
              const userState = getStateForUser(row, this.state.idUser);

              return (
              <TableRow sx={{ backgroundColor: 'lightblue !important' }} key={row.inscriptionId} >
                <TableCell>{row.articleShortTitle}</TableCell>
                <TableCell>{row.articleTitle}</TableCell>
                <TableCell>{row.inscriptionDate}</TableCell>
                <TableCell>{row.inscriptionId}</TableCell>
                {/* <TableCell>{row.evaluatorId1}-{row.evaluatorId2}-{row.evaluatorId3}</TableCell>
                <TableCell>{this.state.idUser}</TableCell>
                <TableCell>{row.stateId1}-{row.stateId2}-{row.stateId3}</TableCell>
                <TableCell>{
                      (row.evaluatorId1 === this.state.idUser && row.stateId1 !== 3) ||
                      (row.evaluatorId2 === this.state.idUser && row.stateId2 !== 3) || 
                      (row.evaluatorId3 === this.state.idUser && row.stateId3 !== 3)
                    }</TableCell> */}
                {['evaluatorId1', 'evaluatorId2', 'evaluatorId3'].map((evaluatorId, index) => (
                row[evaluatorId] == this.state.idUser && (
                  <TableCell align="center" key={index} style={{ color: 'var(--backBlue)' }}>
                    {userState == 0 && (
                      <Tooltip title="Sin Asignar">
                        <HelpOutlineIcon fontSize="large" style={{ color: 'gray' }} />
                      </Tooltip>
                    )}
                    {userState == 1 && (
                      <Tooltip title="Rechazado">
                        <CancelScheduleSendTwoToneIcon fontSize="large" style={{ color: 'red' }} />
                      </Tooltip>
                    )}
                    {userState == 2 && (
                      <Tooltip title="Asignado">
                        <DoneAllTwoToneIcon fontSize="large" style={{ color: 'green' }} />
                      </Tooltip>
                    )}
                    {userState == 3 && (
                      <Tooltip title="Solicitud aceptar enviada">
                        <Send fontSize="large" style={{ color: '#2196F3' }} />
                      </Tooltip>
                    )}
                    {userState == 4 && (
                      <Tooltip title="Evaluación aceptada">
                        <ThumbUpIcon fontSize="large" style={{ color: '#4CAF50' }} />
                      </Tooltip>
                    )}
                    {userState == 5 && (
                      <Tooltip title="Evaluación calificada">
                        <StarsTwoToneIcon fontSize="large" style={{ color: 'orange' }} />
                      </Tooltip>
                    )}
                    {userState == 6 && (
                      <Tooltip title="Evaluación entregada">
                        <CheckCircleTwoToneIcon fontSize="large" style={{ color: 'green' }} />
                      </Tooltip>
                    )}
                  </TableCell>
                )
              ))}
 
                
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="medium !important" 
                  onClick={() => this.handleState(row.inscriptionId, 4)}
                  disabled={(row.evaluatorId1 == this.state.idUser && row.stateId1 !=3) ||
                    (row.evaluatorId2 == this.state.idUser && row.stateId2 !=3) || 
                    (row.evaluatorId3 == this.state.idUser && row.stateId3 !=3)
                  }>
                    <ThumbUpIcon fontSize="default" 
                    style={{
                      color: (
                        (row.evaluatorId1 == this.state.idUser && row.stateId1 != 3) ||
                        (row.evaluatorId2 == this.state.idUser && row.stateId2 != 3) ||
                        (row.evaluatorId3 == this.state.idUser && row.stateId3 != 3)
                      ) ? 'gray' : 'blue'
                    }}/>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="visualizar"
                    size="medium !important"
                    onClick={() => this.handleState(row.inscriptionId, 2)}
                    disabled={
                      (row.evaluatorId1 == this.state.idUser && row.stateId1 != 3) ||
                      (row.evaluatorId2 == this.state.idUser && row.stateId2 != 3) || 
                      (row.evaluatorId3 == this.state.idUser && row.stateId3 != 3)
                    }
                  >
                    <CancelScheduleSendTwoToneIcon fontSize="default" 
                          style={{
                            color: (
                              (row.evaluatorId1 == this.state.idUser && row.stateId1 != 3) ||
                              (row.evaluatorId2 == this.state.idUser && row.stateId2 != 3) ||
                              (row.evaluatorId3 == this.state.idUser && row.stateId3 != 3)
                            ) ? 'gray' : 'red'
                          }} />
                  </IconButton>
                </TableCell>              
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" 
                  onClick={() => this.handleOpenView(row)}
                  disabled={(row.evaluatorId1 == this.state.user && row.state1 !=4 && row.state1 !=5) ||
                    (row.evaluatorId2 == this.state.user && row.state2 !=4 && row.state2 !=5) || 
                    (row.evaluatorId3 == this.state.user && row.state3 !=4 && row.state3 !=5)
                  }>
                    <VisibilityIcon fontSize="large" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                
                {userrole > 97 ? (
                <TableCell align="center">
                <IconButton aria-label="visualizar" size="small" 
                onClick={() => this.handleOpenScore(row.inscriptionId)}
                disabled={(row.evaluatorId1 == this.state.user && row.state1 !=4 && row.state1 !=5) ||
                  (row.evaluatorId2 == this.state.user && row.state2 !=4 && row.state2 !=5) || 
                  (row.evaluatorId3 == this.state.user && row.state3 !=4 && row.state3 !=5)
                }>
                  {/* <IconButton aria-label="visualizar" size="small" onClick={this.handleOpenScore(row.inscriptionId)}> */}
                    <AssessmentIcon fontSize="large" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                ): null}
                </TableRow>
            )})}
          </TableBody>
          </Table>

        </>
          
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No se econtraron Inscripciones.
            </Alert>
          )}


<Dialog  
  PaperProps={{
    style: {
      paddingBottom: '0 !important',
      overflowY: 'auto',
      maxHeight: '98vh',
      width: '90vw',
      maxWidth: 'none !important',
      opacity: 1,
    }
  }} 
  fullWidth={false}
  maxWidth={false}
  className='dialogReounded dialogUbication' 
  open={this.state.openAnex} 
  aria-labelledby="form-dialog-title"
>
<DialogTitle 
  className='dialogTttle' 
  id="alert-dialog-title" 
  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
>
  <span>FIGURAS Y TABLAS</span>

</DialogTitle>

  <DialogContent style={{ paddingLeft: '2rem', overflowY: 'auto' }}>

  <DialogContentText id="alert-dialog-description">
  {this.state.fileDataA && this.state.fileDataA.length > 0 && (
    <div>
      {this.state.fileDataA.map((file, index) => (
        <div key={index} style={{ marginBottom: '1.5rem' }}>
          {file.type === 'application/pdf' && (
            <>
              <h3>Anexo {index + 1}</h3>
              <iframe
                src={file.url}
                width="100%"
                height="600px"
                title={`PDF Viewer ${index + 1}`}>
              </iframe>
            </>
          )}

          {file.type.startsWith('image/') && (
            <>
              <h3>Anexo {index + 1}</h3>
              <img
                src={file.url}
                alt={`Imagen ${index + 1}`}
                width="100%"
                style={{ maxHeight: '400px', objectFit: 'contain' }}
              />
            </>
          )}

          {(file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && (
            <>
              <h3>Anexo {index + 1}</h3>
              <a
                href={file.url}
                download={`Archivo_${index + 1}.xls`}
                style={{ color: 'blue', textDecoration: 'underline' }}>
                Descargar Excel {index + 1}
              </a>
            </>
          )}
        </div>
      ))}
    </div>
  )}
</DialogContentText>

  </DialogContent>
  
  <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
    <Button className='btnPrimary' onClick={this.handleCloseAnex} color="primary">
      Salir
    </Button>
  </DialogActions>
</Dialog>


<Dialog  
  PaperProps={{
    style: {
      paddingBottom: '0 !important',
      overflowY: 'auto',
      maxHeight: '98vh',
      width: '90vw',
      maxWidth: 'none !important',
      opacity: 1,
    }
  }} 
  fullWidth={false}
  maxWidth={false}
  className='dialogReounded dialogUbication' 
  open={this.state.openView} 
  aria-labelledby="form-dialog-title"
>
<DialogTitle 
  className='dialogTttle' 
  id="alert-dialog-title" 
  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
>
  <span>RESUMEN TRABAJO A EVALUAR</span>
  <span style={{ marginLeft: '1rem', fontWeight: 'bold' }}>
    Total: {this.state.totalScore} {/* Muestra el total aquí */}
  </span>
</DialogTitle>

  <DialogContent style={{ paddingLeft: '2rem', overflowY: 'auto' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
      {/* this.state.fileDataA && this.state.fileDataA.length > 0 */}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {this.state.fileDataA && this.state.fileDataA.length > 0 && (
          <Button
            style={{ width: '300px', backgroundColor: 'green', color: 'white' }}
            className='btnPrimary'
            onClick={this.handleOpenAnex}
            startIcon={<SearchIcon />}
          >
            Ver Tablas y Figuras
          </Button>
        )}
      </div>      
      {/* Botón para archivo siguiente con ícono de flecha adelante
      <IconButton onClick={this.handleNextFile}>
        <ArrowForwardIcon />
      </IconButton> */}
    </div>

    <DialogContentText id="alert-dialog-description">
    {this.state.fileData && this.state.fileData.length > 0 && (

      <div style={{ marginBottom: '1.5rem' }}>
        {currentFile.type === 'application/pdf' && (
          <>
            <h3>Resumen </h3>
            <iframe 
              src={currentFile.url} 
              width="100%" 
              height="600px" 
              title={`PDF Viewer ${this.state.selectedFileIndex + 1}`}>
            </iframe>
          </>
        )}

      
        {currentFile.type.startsWith('image/') && (
          <>
            <h3>Anexo {this.state.selectedFileIndex + 1}</h3>
            <img 
              src={currentFile.url} 
              alt={`Imagen ${this.state.selectedFileIndex + 1}`} 
              width="100%" 
              style={{ maxHeight: '400px', objectFit: 'contain' }}
            />
          </>
        )}

        {(currentFile.type === 'application/vnd.ms-excel' || currentFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && (
          <>
            <h3>Anexo {this.state.selectedFileIndex + 1}</h3>
            <a 
              href={currentFile.url} 
              download={`Archivo_${this.state.selectedFileIndex + 1}.xls`} 
              style={{ color: 'blue', textDecoration: 'underline' }}>
              Descargar Excel {this.state.selectedFileIndex + 1}
            </a>
          </>
        )}
        
      </div>
    )}
  </DialogContentText>


    {/* Sección para captura de datos */}

    <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <div style={{ display: 'flex', marginBottom: '1rem' }}>
        <div style={{ flex: '1 1 70%' }}></div> {/* Espacio para la descripción del grupo */}
        <div style={{ flex: '1 1 30%', textAlign: 'center' }}>
          <h4 style={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}>
            Calificación (acepta decimales con punto)
          </h4>
        </div>
      </div>
      {groupedItems.map((group, groupIndex) => (
        <div key={groupIndex} style={{ marginBottom: '1rem', maxWidth: '95%' }}>
          {group.items.map((item, itemIndex) => (
            <div key={itemIndex} style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
              <h5 style={{ fontSize: '1.3rem', marginBottom: '0.5rem', flex: '1 1 70%' }}>
                {group.groupDescription}-{item.formItem}
              </h5>
              
              {item.haveScore == 1 ? (
                <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 30%', gap: '2rem' }}>
                  <TextField

                      fullWidth // Esta propiedad hace que el campo ocupe todo el ancho disponible
                      sx={{ '& input': { fontSize: '1.8rem !important' } }}
                    type="number"
                    inputProps={{
                      min: 0,
                      max: item.maxScore,
                    }}
                    value={item.sliderValue > 0 ? item.sliderValue : ''}
                    onChange={(event) => {
                      const value = Math.max(0, Math.min(item.maxScore, Number(event.target.value)));
                      this.handleSliderChange(group.groupId, item.formOrderItem, value);
                    }}
                  />
                  <Slider
                    style={{ width: '100%',
                        visibility: 'hidden' // Oculta visualmente el slider pero mantiene su funcionalidad
                    }}
                    value={item.sliderValue || 0}
                    disabled={true}
                    onChange={(event, newValue) => this.handleSliderChange(group.groupId, item.formOrderItem, newValue)}
                    aria-labelledby="input-slider"
                    marks={Array.from(Array(item.maxScore + 1).keys()).map(value => ({
                      value: value,
                      label: value.toString(),
                      style: {
                        height: 8,
                        width: 8,
                        borderRadius: '50%',
                        backgroundColor: '#3f51b5',
                        marginTop: -3,
                      }
                    }))}
                    min={0}
                    max={item.maxScore}
                    sx={{
                      '& .MuiSlider-thumb': {
                        color: '#3f51b5',
                      },
                      '& .MuiSlider-track': {
                        color: '#3f51b5',
                      },
                      '& .MuiSlider-rail': {
                        color: '#3f51b5',
                      },
                      '& .MuiSlider-mark': {
                        color: '#3f51b5',
                      },
                      '& .MuiSlider-markLabel': {
                        color: '#3f51b5',
                      }
                    }}
                  />
                </div>
              ) : (
                <div style={{ flex: '1 1 70%' }}>
                  <textarea 
                    value={item.inputValue || ''} 
                    onChange={(event) => this.handleInputChange(group.groupId, item.formOrderItem, event)}
                    style={{ width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
                    rows={2}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>

  </DialogContent>
  
  <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
    <Button className='btnPrimary' onClick={this.handleCloseView} color="primary">
      Salir
    </Button>
    <Button style={{ width: '100px' }} className='btnPrimary' onClick={this.addScore} color="primary">
      Guardar
    </Button>
  </DialogActions>
</Dialog>





{/* DIALOGO DE EVALUACIÓN */}

<Dialog  
  PaperProps={{
    style: {
      paddingBottom: '0 !important',
      overflowY: 'auto',
      maxHeight: '98vh',
      width: '80vw',
      maxWidth: 'none !important',
      opacity: 1,
      scrollbarWidth: 'auto',
      scrollbarColor: '#742239 #f7f6fa',
    }
  }} 
  fullWidth={false}
  maxWidth={false}
  className='dialogReounded dialogUbication' 
  open={openScore} 
  aria-labelledby="form-dialog-title"
>
  <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '4rem !important', color: '#5f6273', margin: 0, marginTop: '0rem'}}>
    EVALUACIÓN DEL TRABAJO ACHO-ID: {selectedInscriptionId}
  </DialogTitle>
  <DialogContent style={{ paddingBottom: '0 !important', overflowY: 'auto', maxHeight: '90vh' }}>
    <div>
      {groupedItems.map((group, groupIndex) => (
        <div key={groupIndex}>
          <h5>{group.groupDescription}</h5>
          {group.items.map((item, itemIndex) => (
            <div key={itemIndex} style={{ marginBottom: '1rem' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem' }}>
                <h5 style={{ width: '80%', margin: 0 }}>{item.formItem}</h5>
                {item.haveScore == 1 && (
                  <TextField
                  style={{ width: '80px', marginLeft: '1rem' }}
                  type="number"
                  inputProps={{
                    min: 0,
                    max: item.maxScore,
                    style: {
                      // Hides the spinner controls on WebKit browsers
                      WebkitAppearance: 'none',
                      // Hides the spinner controls on Firefox
                      MozAppearance: 'textfield',
                      // Removes the outline for the number input
                      outline: 'none'
                    }
                  }}
                    value={item.sliderValue || 0}
                    onChange={(event) => {
                      const value = Math.max(0, Math.min(item.maxScore, Number(event.target.value)));
                      this.handleSliderChange(group.groupId, item.formOrderItem, value);
                    }}
                  />
                )}
              </div>
              {item.haveScore == 1 ? (
                <Slider
                  style={{ width: '50%' }}
                  value={item.sliderValue || 0}
                  disabled={true}
                  onChange={(event, newValue) => this.handleSliderChange(group.groupId, item.formOrderItem, newValue)}
                  aria-labelledby="input-slider"
                  marks={Array.from(Array(item.maxScore + 1).keys()).map(value => ({
                    value: value,
                    label: value.toString(),
                    style: {
                      height: 12,
                      width: 12,
                      borderRadius: '50%',
                      backgroundColor: '#3f51b5',
                      marginTop: -5,
                    }
                  }))}
                  min={0}
                  max={item.maxScore}
                  sx={{
                    '& .MuiSlider-thumb': {
                      color: '#3f51b5', // Color azul para el thumb (botón del slider)
                    },
                    '& .MuiSlider-track': {
                      color: '#3f51b5', // Color azul para la pista
                    },
                    '& .MuiSlider-rail': {
                      color: '#3f51b5', // Color azul para el rail (la línea que va por detrás)
                    },
                    '& .MuiSlider-mark': {
                      color: '#3f51b5', // Color azul para las marcas cuando está deshabilitado
                    },
                    '& .MuiSlider-markLabel': {
                      color: '#3f51b5', // Color de las etiquetas de las marcas
                    }
                  }}
                />
              ) : (
                <textarea 
                  value={item.inputValue || ''} 
                  onChange={(event) => this.handleInputChange(group.groupId, item.formOrderItem, event)}
                  style={{ marginBottom: '1rem', width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
                  rows={4}
                />
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  </DialogContent>
  <DialogActions style={{ justifyContent: 'center', margin: '1rem', marginTop: 0 }}>
    <Button style={{ width: '100px' }} className='btnPrimary' onClick={this.handleCloseScore} color="primary">
      Cancelar
    </Button>
    <Button style={{ width: '100px' }} className='btnPrimary' onClick={this.addScore} color="primary">
      Guardar
    </Button>
  </DialogActions>
</Dialog>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  evaluationsList: state.evaluations,
  scoreList      : state.score,
  params: state.getActualParams,
  withoutFillingOuts: state.withoutFillingOut,
  user: state.user,
  assignedAssets: state.assignedAssets,
  locations: state.locations.locations,
  assetsStatus: state.SUSILists.assetsStatus,
  changesTypes: state.SUSILists.changesTypes,
  missingReasons: state.SUSILists.missingReasons,
  sectionalsUser: state.sectionals,
  stages: state.stages,
  SyncSIAF: state.syncSIAF.isFetching,
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  InventoryLeftOver
);
