import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import useStyles from "../../assets/jss/online-inventory/online-inventory";
import {
  Paper, Grid, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, CircularProgress,
  Chip, Menu, MenuItem, ButtonBase, Radio, withStyles
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AutoComplete from '@material-ui/lab/Autocomplete';
import { Alert, Autocomplete } from "@material-ui/lab";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import { Editor } from "@tinymce/tinymce-react";
import { sendMail } from "../../redux/actions/sendMailActions";
import { withRouter } from "react-router";
import { fetchAssetSurplus, updateSurplus } from "../../redux/actions/assetSurplusAction";
import { updateUbication } from "../../redux/actions/ubicationsActions";
import { fetchLocations } from "../../redux/actions/locationsActions";

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5'
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const AssetSurplus = (props) => {

  const getnameLocation = (idLocation) => {
    let dataname = props.locations.filter((t) => t.id == idLocation);
    if (dataname.length > 0) {
      return dataname[0].fullname;
    }
    return "";
  };
  let mailReplace = '';
  if (props.assetSurplus.asset != null && props.assetSurplus.asset.email != null) {
    mailReplace = props.assetSurplus.asset.email
  }

  let location = '';
  let headChs = '';
  if (props.assetSurplus.asset && props.assetSurplus.asset.location != null) {
    location = props.assetSurplus.asset.location;
    headChs = getnameLocation(props.assetSurplus.asset.location);
  }
  let complement = '';
  if (props.assetSurplus.asset && props.assetSurplus.asset.location_complement != null) {
    complement = props.assetSurplus.asset.location_complement;
  }
  let saveLocation = false;
  const editorRef = useRef(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(props.user.CORREO_REPORTAR_REIMPRESION_PLACA);
  const [ccmail, setCcEmail] = useState([mailReplace]);
  const [asuntoMail, setAsuntoMail] = useState(`Solicitud Renovación de plaqueta adhesiva`);
  const [sended, setSended] = useState(false);
  const [emailAlert, setEmailAlert] = useState('');
  const [listHeadQuarters, setListHeadQuarters] = useState(false);
  const [open3, setOpen3] = useState(saveLocation);
  const loading = listHeadQuarters && props.locations.length === 0;
  const [recordsLocation, setRecordsLocation] = useState(location);
  const [emailSended, setEmailSended] = useState(false);
  const [headquarterChs, setHeadQuarterChs] = useState(headChs);
  const [locationChs, setLocationChs] = useState(complement);

  const [saveHeadquarterChs, setSaveHeadquarterChs] = useState(headChs);
  const [saveLocationChs, setSaveLocationChs] = useState(complement);

  const [estadoBien, setEstdoBien] = useState(props.assetSurplus.asset?.estado != null ? props.assetSurplus.asset.estado : '');
  const [menuEdit, setMenuEdit] = useState(false)
  const [razonReimpresion, setRazonReimpresion] = useState(null)
  const [state, setState] = useState({
    mouseX: null,
    mouseY: null,
    serie: null,
    placa: null,
    cont: null
  });

  useEffect(() => {
    setHeadQuarterChs(headChs);
    setLocationChs(complement);
    setRecordsLocation(location);
  }, [headChs, location, complement])

  const handleClose3 = async () => {
    await updateUbication({ idAsset: props.assetSurplus.asset.id, surpId: props.assetSurplus.asset.surpId, complement: locationChs, idLocation: recordsLocation })
    setOpen3(false);
    setSaveHeadquarterChs(headquarterChs)
    setSaveLocationChs(locationChs)
  };

  /* Templates correos */
  const templateText = () => {
    return props.assetSurplus.asset ? `
      <p style="color: #5f6273 !important; font-size: 0.875rem !important">
        Señores Inventarios
        <br><br>
        Se informa que una vez consultada la información del serie 
        ${props.assetSurplus.asset.serieBien == null ? 'no identificado' : props.assetSurplus.asset.serieBien}, el sistema identificó la placa 
        ${props.assetSurplus.asset.placa} identificado con el número (${props.user.numero_documento})
        <br><br>
        Se solicita generar la correspondiente placa de codigo de barras por ${razonReimpresion.name}, con el fin de realizar la legalización.
        <br><br>
        ${props.user.fullName}
        <br><br>
        Correo electronico: ${props.user.email}
        </p>` : '';
  }
  const templateTextResponsable = () => {
    let textoPlaca = "";
    if(window.sessionStorage.getItem('placaSearch') !=null && 
       window.sessionStorage.getItem('placaSearch') !=""){
       textoPlaca =  "con placa número " + window.sessionStorage.getItem('placaSearch') + " y ";
       }
    return props.assetSurplus.asset ? `
    <p style="color: #5f6273; font-weight: 400; font-size: 0.875rem;">
      Señor@ ${props.assetSurplus.asset.responsable},
      <br><br>
      Se informa que el bien ${textoPlaca} con número de serie ${props.assetSurplus.asset.serieBien == null ? 'no identificado' : props.assetSurplus.asset.serieBien}, fue reportado como sobrante por el usuario que aparece al pie de la firma. 
      <br><br>
      Por favor, comuníquese con la persona que reporta el sobrante para aclarar la situación.
      <br><br>
      ${props.user.fullName}
      <br><br>
      Cédula:${props.user.numero_documento}
      <br><br>
      ${props.dataUser.subDirLocDesc}
      <br><br>
      ${props.sectionalsUser.sectionals[1].name}
      <br><br>
      Correo electrónico: ${props.dataUser.person.email}
    </p>
  ` : '';
  }
  const templateAlertStadoActivo = (type = 1) => {
  
  return type == 1 ? `Señores Inventarios
  <br><br>
  Se informa que ha sido registrado un sobrante de placa ${props.assetSurplus.asset.placa == null ? 'no identificado' : props.assetSurplus.asset.placa} y ha sido notificado al responsable ${props.assetSurplus.asset.responsable}
  <br><br>
  Cordialmente,
  <br><br>
  ${props.user.fullName}
  <br><br>
  Cédula:${props.user.numero_documento}
  <br><br>
  ${props.dataUser.subDirLocDesc}
  <br><br>
  ${props.sectionalsUser.sectionals[1].name}
  <br><br>
  Correo electrónico: ${props.dataUser.person.email}
  `: `
  Señores Inventarios
  <br><br>
  Se informa que ha sido registrado un sobrante de serie ${props.assetSurplus.asset.serial == null ? 'no identificado' : props.assetSurplus.asset.serial} y ha sido notificado al responsable ${props.assetSurplus.asset.responsable}
  <br><br>
  Cordialmente,
  <br><br>
  ${props.user.fullName}
  <br><br>
  Cédula:${props.user.numero_documento}
  <br><br>
  ${props.dataUser.subDirLocDesc}
  <br><br>
  ${props.sectionalsUser.sectionals[1].name}
  <br><br>
  Correo electrónico: ${props.dataUser.person.email}
  `;
  }

  if (props.assetSurplus.asset && props.assetSurplus.isFetching == false && emailSended == false) {
    let params = {
      subject: asuntoMail + ` (${props.assetSurplus.asset.placa})`,
      emailEnd: email
    }

    //if (props.assetSurplus.asset.estadoActivo != null && props.assetSurplus.asset.estadoActivo != 'SS') {
    
    if (props.dataSearched.placa != null && props.dataSearched.placa != "") {
      params.subject = 'Se registro un bien sobrante'
      sendMail(params, templateAlertStadoActivo(), []).then()
    } else {
      params.subject = 'Se registro un bien sobrante'
      sendMail(params, templateAlertStadoActivo(2), []).then()
    }
    params.emailEnd = props.assetSurplus.asset.email;
    setTimeout(() => {
      sendMail(params, templateTextResponsable(), []).then()
    }, 3000);
    setEmailSended(true)
  }

  let dependencys = [
    { title: 'ADMINISTRACION DE SEDE', id: 1 },
    { title: 'ARCHIVO LOFOSCOPIA NACIONAL', id: 2 },
    { title: 'BRIGADA NACIONAL DE HOMICIDIOS', id: 3 },
    { title: 'DELEGADA CONTRA LA CRIMINALIDAD ORGANIZADA', id: 4 },
    { title: 'DELEGADA PARA LA SEGURIDAD CIUDADANA', id: 5 },
    { title: 'DELEGADA PARA LAS FINANZAS CRIMINALES', id: 6 },
    { title: 'DEPARTAMENTO DE ADMINISTRACION DE BIENES Y CONSTRUCCIONES', id: 7 },
    { title: 'DEPARTAMENTO DE ALTOS ESTUDIOS', id: 8 },
    { title: 'DEPARTAMENTO DE BIENES ALMACEN E INVENTARIOS', id: 9 }
  ];

  const toggleDialog = () => {
    const currentState = open;
    if (!open) {
      setSended(false);
      setRazonReimpresion(null);//TLA null por false
      setEmailAlert(' ');//TLA ' ' por false
      mailReplace.length > 0 ? setCcEmail([mailReplace]) : setCcEmail([]);
    }
    setOpen(!currentState)

  };
  const send = async () => {
    if (editorRef.current) {
      setSended(true)
      let params = {
        //subject: asuntoMail + `(${props.assetSurplus.asset.placa})`, //TLA
        subject: asuntoMail + `(${props.sectionalsUser.sectionals[1].name})`,
        emailEnd: email, 
      }
      console.log("antes de enviar")
      let result = await sendMail(params, editorRef.current.getContent(), ccmail)
      console.log("enviado mensaje")
      if (result) {
        await updateSurplus(props.user.numero_documento, { placa: props.assetSurplus.asset.placa, serie: props.assetSurplus.asset.serieBien })
        props.dispatch(fetchAssetSurplus({ placa: props.assetSurplus.asset.placa, serie: props.assetSurplus.asset.serial })) //TLA serieBien por serial
        setEmailAlert(result)
      } else {
        setEmailAlert('')
      }
    }
  };
  const getURLAsset = (assetFile) => {
    if (assetFile)
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/" +
        assetFile +
        ".svg"
      );
    else
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/no-image.svg"
      );
  };


  const validateEmailString = (input = '') => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(input)
  }

  const handleChangeTypeReimpresion = (event) => {
    let optionData = props.rePrintReasons.find(reimpresion => reimpresion.id == event.target.value)

    setRazonReimpresion(optionData)
  };


  const renderDialog = () => {
    return (
      <Dialog
        className="containerCorreoBienes2"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {emailAlert.length == 0 && razonReimpresion != null ? <>
          <DialogTitle>{"Enviar correo electrónico"}</DialogTitle>
          <DialogContent>
            <label className='labelStyle' htmlFor='placa'>Para</label>
            <TextField style={{ backgroundColor: 'transparent', color: '#5f6273', marginBottom: 7, borderRadius: '1rem' }} fullWidth id="placa" value={email} size={'small'} variant="outlined" onChange={e => setEmail(e.target.value)} disabled />
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              value={[...ccmail]}
              defaultValue={[...ccmail]}
              freeSolo
              onChange={(event, input) => {
                setCcEmail(input)
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant='outlined' style={{ marginLeft: '1rem', backgroundColor: validateEmailString(option) ? '#dae2fc' : '#ef4682', color: validateEmailString(option) ? '#445dc9' : '#ffff', border: 0 }} color={validateEmailString(option) ? 'primary' : 'secondary'} label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <>
                  <label className='labelStyle' htmlFor='CC'>CC</label>
                  <TextField className='inputCC' style={{ marginBottom: 7 }} onBlur={(e) => {
                    if (e.target.value.length > 0) {
                      setCcEmail(ccmail.concat(e.target.value))
                    }
                  }}
                    onKeyDown={e => {
                      if (e.keyCode === 188 && e.target.value) {
                        setCcEmail(ccmail.concat(e.target.value))
                      }
                    }} {...params} type='email' variant="outlined" placeholder="Correos" size={'small'} />
                </>
              )}
            />
            <label className='labelStyle' htmlFor='asunto'>Asunto</label>
            <TextField style={{ backgroundColor: 'transparent', color: '#5f6273', marginBottom: 7, borderRadius: '1rem' }} fullWidth id="asunto" value={asuntoMail + ` (${props.assetSurplus.asset ? props.assetSurplus.asset.placa : ''}) - por ${razonReimpresion.name}`} size={'small'} variant="outlined" disabled />
            <Editor
              apiKey={'4kbnibmrsojzegoh7cxijw4b8sjui7oxr9o3qxq3okpmeq5b'}
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={templateText()}
              init={{
                height: 350,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            {emailAlert.length > 0 && <Alert severity={'info'}>
              {emailAlert.message}
            </Alert>}
          </DialogContent>
          {sended === false ?
            <DialogActions className="actionsSended">
              <Button className="btnSecundary w-auto" onClick={toggleDialog} color="primary">
                Cancelar
              </Button>
              <Button className="btnPrimary w-auto" onClick={send} color="primary" autoFocus>
                Enviar
              </Button>
            </DialogActions>
            :
            <DialogActions className="actionsSended">
              <Button className="btnSecundary w-auto" onClick={toggleDialog} color="primary">
                Cancelar
              </Button>
              <Button className="btnPrimary w-auto" autoFocus>
                {emailAlert.length === 0 ? 'Enviando...' : 'Enviado'}
              </Button>
            </DialogActions>}
        </> : emailAlert.length == 0 ?
          <DialogContent>

            <DialogTitle>{"Motivo por el cual está solicitando la renovación de la placa adhesiva"}</DialogTitle>

            <DialogActions>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <ButtonBase className='ContainerButtonGroup'>
                    <Grid container direction='row'>
                      {props.rePrintReasons != undefined && props.rePrintReasons.length > 0 && props.rePrintReasons.map(transact => {
                        return (
                          <Grid key={transact.id} item xs={12} sm={4} container alignItems='center'>
                            <Grid item xs="auto" sm="auto" lg="auto">
                              <BlueRadio
                                checked={razonReimpresion != null && razonReimpresion.id == transact.id}
                                onChange={handleChangeTypeReimpresion}
                                value={transact.id}
                                name={`${transact.id}`}
                              />
                            </Grid>
                            <Grid item xs sm lg="auto">
                              <Typography className='fontAa' variant="caption" display="block" gutterBottom>{transact.name}</Typography>
                            </Grid>
                          </Grid>)
                      })}
                    </Grid>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <Button className="btnSecundary btnCancelar" onClick={toggleDialog} color="primary">
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </DialogContent> :
          <>
            <DialogTitle style={{ color: '#5f6273', fontWeight: '700', paddingBottom: 0, paddingLeft: '1rem' }}>
              {emailAlert.error ? 'Error al enviar correo' : 'Enviado correctamente'}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className='labelFlex' style={emailAlert.error ? {} : { marginBottom: '1rem' }}>{emailAlert.message}</Typography>
                </Grid>
                {emailAlert.error ?
                  <Grid item container>
                    <Grid item>
                      <b className='labelFlex'>Detalle del error:</b>
                    </Grid>

                    <Grid item>
                      <Typography className='labelFlex' style={{ paddingLeft: '1rem' }} paragraph>
                        {emailAlert.messageError}
                      </Typography>
                    </Grid>
                  </Grid>
                  : null}
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Button className='btnPrimary' style={{ margin: 'auto' }} onClick={toggleDialog} autoFocus>
                    Aceptar
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>}
      </Dialog>
    );
  };

  const searchLocation = (event, inputValue) => {
    let params = {
      texto: inputValue
    };
    props.dispatch(fetchLocations(params));
  }

  const selectHeadQuarter = (event, inputValue) => {
    /*let structureHQ = [{
      hg: "Edificio Echeverry (BARBOSA - ANTIOQUIA)",
      records: [
        { name: "Piso 1" },
        { name: "Piso 2" },
        { name: "Piso 3" },
        { name: "Piso 4" },
        { name: "Piso 5" }
      ]
    },{
      hg: "Bunker (Bogotá D.C - Bogotá D.C)",
      records: [
        { name: "Bloque A - Piso 1" },
        { name: "Bloque A - Piso 2" },
        { name: "Bloque A - Piso 3" },
        { name: "Bloque A - Piso 4" },
        { name: "Bloque B - Piso 1" },
        { name: "Bloque B - Piso 2" },
        { name: "Bloque B - Piso 3" },
        { name: "Bloque B - Piso 4" },
        { name: "Bloque B - Piso 5" },
      ]
    },{
      hg: "Zona Industrial (Bogotá D.C - Bogotá D.C)",
      records: [
        { name: "Piso 1" },
        { name: "Piso 2" },
        { name: "Piso 3" }
      ]
    }];*/
    let structHQ = props.locations.filter(hqBD => hqBD.fullname == inputValue);
    if (structHQ.length > 0) {
      setRecordsLocation(structHQ[0].id);
      setHeadQuarterChs(inputValue);
    } else {
      setHeadQuarterChs('');
    }

  }

  const renderDialogLocation = () => {
    //console.log("Estos son las ubicaciones", props.locations);
    return (
      <Dialog open={open3} disableEnforceFocus aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ubicación del bien </DialogTitle>
        <DialogContent>
          <AutoComplete
            id="searchHQ"
            freeSolo
            style={{ width: 400 }}
            open={listHeadQuarters}
            onOpen={() => {
              setListHeadQuarters(true);
            }}
            onClose={() => {
              setListHeadQuarters(false);
            }}
            loading={loading}
            options={props.locations.map((option) => option.fullname)}
            onInputChange={searchLocation}
            value={headquarterChs}
            onChange={selectHeadQuarter}
            renderInput={(params) => (
              <>
                <label className='labelUbication'>Sede</label>
                <TextField {...params} className='ubicacionInput' variant="outlined" style={{ background: '#f0f2fa', borderRadius: '2rem', padding: 0 }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }} />
              </>
            )}
          />
          <label className='labelUbication'>Ubicación: </label>
          <TextField className='ubicacionInput' style={{ background: '#f0f2fa', borderRadius: '2rem' }}
            fullWidth
            value={locationChs}
            onChange={e => setLocationChs(e.target.value)}
            variant="outlined"
            type="text"
          />
        </DialogContent>
        <DialogActions className="actionsMailDialog">
          <Button className="btnSecundary w-auto" onClick={() => {
            setHeadQuarterChs(saveHeadquarterChs)
            setLocationChs(saveLocationChs)
            setOpen3(false)
          }} disabled={headquarterChs == null} color="primary">
            Cancelar
          </Button>
          <Button className="btnPrimary w-auto" onClick={handleClose3} disabled={headquarterChs == null} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>);
  }
  const handleChangeSwitch2 = () => {
    setOpen3(true);
  };

  const toggleMenuEdit = (event, serie, placa, cont) => {
    const currentState = menuEdit;
    setMenuEdit(!currentState)
    if (menuEdit) {
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
        serie: serie,
        placa: placa,
        cont: cont
      });
    } else {
      setState({
        mouseX: null,
        mouseY: null,
        serie: null,
        placa: null,
        cont: null
      });
    }
  }


  const templateResponsable = (type = 1) => {
    return type == 1 ? `
        Señor servidor
        <br><br>
        Se le notifica que el bien con placa 
        ${props.assetSurplus.asset.placa} y serie 
        ${props.assetSurplus.asset.serieBien} ha cambiando su estado de: En tramite a tramitado por el usuario 
        ${props.user.fullName}
        <br><br>
        Contacto usuario
        <br><br>
        Correo electronico ${props.user.email}
      `: `
      Señor Inventario
        <br><br>
        Se le notifica que el bien con placa ${props.assetSurplus.asset.placa} y serie ${props.assetSurplus.asset.serieBien} ha cambiando su estado de: En tramite a tramitado
      `;
  }

  const updateOneSurplus = async () => {
    let params = {
      subject: 'Bien tramitado ' + `(${props.assetSurplus.asset.placa})`,
      emailEnd: props.user.email
    }
    /* console.log(props.assetSurplus); */
    await updateSurplus(props.user.numero_documento, { placa: state.placa, serie: state.serie })
    setEstdoBien('Tramitado');
    sendMail(params, templateResponsable(2), []).then()
    if (props.assetSurplus.responsable != null) {
      params.emailEnd = props.assetSurplus.email
      sendMail(params, templateResponsable(), []).then()
    }
    toggleMenuEdit(null, null, null, null)
  }
  return (
    <>
      <h1 className="tittleGris"> EVALUACIONES</h1>
      <div className="containerSobrante">
        {props.assetSurplus.asset && props.assetSurplus.isFetching == false ? (
          <div className={classes.root}>
            {renderDialogLocation()}
            <Paper className='papperSurplus'>
              <Grid container className='contenedorAssetGridMayor'>
                <Grid className='contenedorAssetGrid' item xs={12} sm={12} container>
                  <Grid className='columnaLogo' item xs sm={3} lg xl style={{ justifyContent: 'center' }} container direction="column">
                    <IconButton className="asset_icon" >
                      <img className={classes.img} alt="Imagen Bien" src={getURLAsset(props.assetSurplus.asset.category_icon)} />
                    </IconButton>
                    <Typography align="center" display="block" gutterBottom className='descripcionBien'> {props.assetSurplus.asset.descripcionBien}</Typography>
                  </Grid>

                  <Grid className='columnaInfo' item xs={12} sm={9} lg={9} xl={10} container direction="row">
                    <Grid item xs={12} sm lg xl container className='contenedorMedio' direction='row'>
                      <Grid item xs={4} sm={2} lg={2} xl={1} container direction='column'>
                        <Grid item>
                          <Typography style={{ color: '#5f6273' }} variant="caption">
                            <b className='bAsset'> Placa: </b>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography style={{ color: '#5f6273', lineHeight: '1' }} variant="caption" gutterBottom className={classes.text}>
                            <b className='bAsset'> Serie del bien: </b>
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography style={{ color: '#5f6273' }} variant="caption">
                            <b className='bAsset'>Dependencia: </b>
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography style={{ color: '#5f6273' }} variant="caption" display="block" gutterBottom>
                            <b className='bAsset'>Seccional:</b>
                          </Typography>
                        </Grid>

                        <IconButton onContextMenu={(e) => { toggleMenuEdit(e, props.assetSurplus.asset.serieBien, props.assetSurplus.asset.placa) }}
                          onClick={(e) => { toggleMenuEdit(e, props.assetSurplus.asset.serieBien, props.assetSurplus.asset.placa) }}
                          style={{ cursor: 'pointer', padding: '0', position: 'absolute', right: '1.5rem', top: '1.5rem' }}>
                          <FontAwesomeIcon style={{ height: '1.1rem', color: '#5f6273', fontSize: '1.813rem' }} icon={faEllipsisV} />
                        </IconButton>
                        <Menu
                          className="menuShadow"
                          keepMounted
                          open={state.mouseY !== null}
                          onClose={e => { toggleMenuEdit(e, null, null, null) }}
                          anchorReference="anchorPosition"
                          anchorPosition={
                            state.mouseY !== null && state.mouseX !== null
                              ? { top: state.mouseY, left: state.mouseX }
                              : undefined
                          }
                        >
                          <MenuItem className="menuItemColor" onClick={updateOneSurplus}>Cambiar a tramitado</MenuItem>
                        </Menu>
                      </Grid>

                      <Grid item xs={8} sm lg={5} xl container direction='column'>
                        <Grid item>
                          <Typography variant="caption" gutterBottom className="textCards">
                            {props.assetSurplus.asset.placa}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography className="textCards" variant="caption" gutterBottom>
                            {props.assetSurplus.asset.serieBien}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography className="textCards" variant="caption" gutterBottom>
                            {props.assetSurplus.asset.dependencia != null && props.assetSurplus.asset.dependencia.length > 1 ? props.assetSurplus.asset.dependencia : dependencys.find(i => i.id == props.assetSurplus.asset.dependencia)?.title}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography variant="caption" className="textCards">{props.assetSurplus.asset.seccional}</Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={4} sm={2} lg={2} xl={1} container direction='column'>
                        <Grid item>
                          <Typography style={{ color: '#5f6273' }} variant="caption">
                            <b className='bAsset'>Estado del bien: </b>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography style={{ color: '#5f6273' }} variant="caption" display="block" gutterBottom>
                            <b className='bAsset'>Responsable: </b>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={8} sm lg xl container direction='column'>

                        <Grid item>
                          <Typography variant="caption" gutterBottom
                            style={{
                              color: props.stages.stages.find(i => i.code == props.assetSurplus.asset.estadoActivo) ?
                                props.stages.stages.find(i => i.code == props.assetSurplus.asset.estadoActivo).color : '',
                              border: props.stages.stages.find(i => i.code == props.assetSurplus.asset.estadoActivo) ?
                                `1px solid ${props.stages.stages.find(i => i.code == props.assetSurplus.asset.estadoActivo).color}`
                                : '',
                              borderRadius: '7px', fontSize: '0.688rem', padding: '0 5px'
                            }}>
                            {props.stages.stages.find(i => i.code == props.assetSurplus.asset.estadoActivo) ?
                              props.stages.stages.find(i => i.code == props.assetSurplus.asset.estadoActivo).name
                              : null}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography gutterBottom className="textCards" variant="caption">
                            {props.assetSurplus.asset.responsable}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <hr className='hrStyle' />

                    <Grid item xs={12} container>
                      <Grid item xs={12} container>
                        <Grid item xs={3} sm={2} lg={2} xl={1}>
                          <Typography style={{ color: '#5f6273' }} variant="caption" display="block" gutterBottom>
                            <b className='bAsset'>Estado sobrante: </b>
                          </Typography>
                        </Grid>

                        <Grid item xs>
                          <Typography
                            gutterBottom
                            className="textCards">
                            <Button className="cardStateSurplus" style={estadoBien != 'Tramitado' ?
                              { backgroundColor: '#FAC73B' } : { backgroundColor: '#46CB4C' }}
                              size='small' onClick={() => { }}>
                              {estadoBien != 'Tramitado' ? 'En tramite' : estadoBien}
                            </Button>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container>
                        <Grid item xs={3} sm={2} lg={2} xl={1}>
                          <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                            <b className='bAsset'>Ubicación: </b>
                          </Typography>
                        </Grid>

                        <Grid item xs container>
                          <Grid item>
                            <Typography className="textCards" variant="caption" gutterBottom>
                              {headquarterChs != null && headquarterChs.length > 0 ? headquarterChs : null}
                              {' - '}
                              {locationChs}
                              {getnameLocation(props.assetSurplus.asset.idLocation)}
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Button className='btnEditar1'
                              onClick={handleChangeSwitch2} color='primary'>
                              Editar
                              <EditIcon style={{ fontSize: "0.688rem" }} />
                            </Button>
                            <Button style={{ marginLeft: '0.2rem' }} className='btnEditar2'
                              onClick={() => { handleChangeSwitch2(); console.log(open); }} color='primary'>
                              <EditIcon style={{ fontSize: "0.988rem" }} />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

{/*                   <Grid style={{ textAlign: 'end' }} item xs={12}>
                    <Button onClick={toggleDialog}
                      style={{ width: 'auto' }}
                      className='btnEnviar btnEnviar1'
                      startIcon={<AlternateEmailIcon />}>Enviar correo electrónico - Sobrante 
                    </Button>
                    <Button onClick={toggleDialog}
                      style={{ width: 'auto', lineHeight: '1' }}
                      className='btnEnviar btnEnviar2'
                      startIcon={<AlternateEmailIcon />}>Enviar correo electrónico
                    </Button>
                  </Grid> */}
                </Grid>
              </Grid>
            </Paper>
          </div>
        ) : props.assetSurplus.isFetching == false ? (
          <Alert icon={false} style={{ backgroundColor: '#ffff', color: '#5f6273', fontSize: '0.875rem', fontWeight: '500', borderRadius: '1rem' }}
            action={<></>}>
            <Grid container direction="row" justifycontent="center" alignItems="center">
              <Grid item xs={12} sm="auto" lg="auto" xl="auto" style={{ alignSelf: 'flex-start', textAlign: 'center' }}>
                <FontAwesomeIcon className="iconInfo fa-8x" icon={faInfoCircle} listItem />
              </Grid>
              <Grid item xs sm lg xl>
                <Typography align='left' paragraph>
                  El bien no está registrado en los inventarios de la entidad, a continuación, debe realizar el registro manual de la información que identifica el bien.
                </Typography>
                <Typography style={{ margin: '0.5rem 0' }} align='justify' paragraph>
                  ¿Desea proceder a realizar el registro?
                </Typography>
                <Grid container className="ReportSobranteFooter">
                  <Grid item>
                    <Button className="btnSecundary w-auto" onClick={() => props.history.push("/toleftover")}>
                      Volver
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button className="btnPrimary w-uto" style={{ marginLeft: '0.5rem' }} color="primary" onClick={() => props.history.push("/registerSurplusAsset")}>
                      Aceptar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Alert>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress className="circular-progress" />
          </div>
        )
        }
        {renderDialog()}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  // console.log(state);
  return ({
    assetSurplus: state.assetSurplus,
    historyList: state.inventoryHistory,
    params: state.getActualParams,
    withoutFillingOuts: state.withoutFillingOut,
    user: state.user,
    assignedAssets: state.assignedAssets,
    locations: state.locations.locations,
    assetsStatus: state.SUSILists.assetsStatus,
    changesTypes: state.SUSILists.changesTypes,
    missingReasons: state.SUSILists.missingReasons,
    sectionalsUser: state.sectionals,
    rePrintReasons: state.SUSILists.reprintReasons,
    dataUser: state.dataUserReducer.dataDirectory,
    dataSearched: state.surplusSearched,
    stages: state.stages,
    SyncSIAF: state.syncSIAF.isFetching,

  })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withRouter(AssetSurplus)
);
