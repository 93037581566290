import { Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { connect } from 'react-redux';
import { Alert } from "@material-ui/lab";
import { fetchAssetStages } from '../../redux/actions/assetStagesActions';
import { fetchInventoryHistory } from '../../redux/actions/inventoryHistoryActions';
import { fetchLocations } from '../../redux/actions/locationsActions';
import { fetchSectionalsUser } from '../../redux/actions/sectionalsActions';
import { fetchAssetsStatus, fetchChangesTypes, fetchMissingReasons, fetchSurplusAssetTypes } from '../../redux/actions/SUSIListsActions';
import { withRouter } from 'react-router-dom';
import { registerSurplus } from '../../redux/actions/assetSurplusAction';
import { Autocomplete } from '@material-ui/lab';
import { sendMail } from '../../redux/actions/sendMailActions';
class RegisterAssetSurplus extends React.Component {
    constructor(props) {
        super(props)

        /* this.TypeAssets = [
            { title: 'Mobiliario y Enseres', id: 1 },
            { title: 'Equipos tecnologicos', id: 2 },
            { title: 'Celulares', id: 3 },
            { title: 'Otro', id: 4 }
        ]; */
        this.dependencys = [
            { title: 'ADMINISTRACION DE SEDE', id: 1 },
            { title: 'ARCHIVO LOFOSCOPIA NACIONAL', id: 2 },
            { title: 'BRIGADA NACIONAL DE HOMICIDIOS', id: 3 },
            { title: 'DELEGADA CONTRA LA CRIMINALIDAD ORGANIZADA', id: 4 },
            { title: 'DELEGADA PARA LA SEGURIDAD CIUDADANA', id: 5 },
            { title: 'DELEGADA PARA LAS FINANZAS CRIMINALES', id: 6 },
            { title: 'DEPARTAMENTO DE ADMINISTRACION DE BIENES Y CONSTRUCCIONES', id: 7 },
            { title: 'DEPARTAMENTO DE ALTOS ESTUDIOS', id: 8 },
            { title: 'DEPARTAMENTO DE BIENES ALMACEN E INVENTARIOS', id: 9 }
        ];

        this.state = {
            tipos: this.props.surplusAssetTypes,
            otro: false,
            isMobiliario: false,
            tipoBienOtro: '',
            placa: this.props.surplusDataSearch.placa,
            descripcionBien: '',
            descripcionDependencia: '',
            contacto: '',
            serie: this.props.surplusDataSearch.serie,
            location: '',
            seccional: '',
            dependencia: '', 
            tipoBienCode: '',
            alertResult: '',
            openAlert: false,
            Send: false,
            validations: {
                descriptionLength: true,
                contactLength: true,
                serieLength: true,
                descripcionDependenciaLength: true,
            },
            paramsFetchSectionals: {
                user_susi_id: props.user.user_susi_id,
            }
        }
    }


    getInformation() {
        const params = {
            user_susi_id: this.props.user.user_susi_id
        };
        this.props.dispatch(fetchInventoryHistory(params));
    }

    componentDidMount() {
        console.log("TLA en assetregistersurplus 75");



        this.getInformation();
        this.props.dispatch(fetchSurplusAssetTypes());
        //this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
        this.props.dispatch(fetchAssetsStatus());
        this.props.dispatch(fetchChangesTypes());
        this.props.dispatch(fetchAssetStages());
        this.props.dispatch(fetchMissingReasons());

        this.props.dispatch(fetchLocations());
        this.props.dispatch(fetchSectionalsUser(this.state.paramsFetchSectionals));
    }
    toggleDialog() {
        const currentState = this.state.openAlert;
        this.setState({ openAlert: !currentState })
    };

    onValidate() {
        let validationState = Object.assign({}, this.state.validations)
        //validationState.serieLength = this.state.isMobiliario == false ? this.state.serie.length <= 30 && this.state.serie.length > 0 : true;
        validationState.serieLength = true;
        //let validPlaca = this.state.placa.length > 0;
        let validPlaca = true;
        let validDependence = isNaN(this.state.dependencia.id) == false;
        let validUbication = this.state.location > 0;
        let validTipoBien = isNaN(this.state.tipoBienCode) == false;
        let validTipoOtro = this.state.otro ? this.state.tipoBienOtro.length > 0 : true;
        validationState.contactLength = this.state.contacto.length >= 7 && this.state.contacto.length <= 20;
        let validDescripcion = this.state.descripcionBien.length > 0 && this.state.descripcionBien.length <= 100;
        validationState.descripcionDependenciaLength = this.state.descripcionDependencia.length > 0 && this.state.descripcionDependencia.length <= 100;
        if (validDescripcion) {
            validationState.descriptionLength = this.state.descripcionBien.trim().split(/\s+/).length >= 2;
        } else {
            validationState.descriptionLength = false;
        }
        this.setState({ validations: validationState });
        //quito la validación de dependencia por id && validDependence (TLA) y creo la validación de la descripción de la dependencia && validationState.descripcionDependenciaLength
        return validationState.serieLength && validPlaca  && validUbication && validTipoBien && validTipoOtro && validationState.contactLength && validationState.descriptionLength && validationState.descripcionDependenciaLength;
    }

    async onRegisterSurplus() {
        this.setState({dependencia: 0 })
        this.setState({ Send: true })
        let data = {
            reporterName: this.props.user.fullName,
            serie: this.state.serie,
            placa: this.state.placa,
            dependenceId: this.state.dependencia,
            seccional: 'CENTRAL NIV',
            reporter: this.props.user.numero_documento,
            descripcionBien: this.state.descripcionBien,
            descripcionDependencia: this.state.descripcionDependencia,
            tipoBien: this.state.tipoBienCode,
            contact: this.state.contacto,
            tipoBienOtro: this.state.tipoBienOtro,
            idLocation: this.state.location,
            email: this.props.user.email
        }

        let validation = this.onValidate()
       
        let message = validation ? await registerSurplus(this.props.user.numero_documento, data) 
            : 'Digitar la información de la descripción del bien, serie, ubicación fisica del bien, dependencia y número de contacto, Fijo (ext) o móvil.';
        this.setState({ alertResult: message.message ? message.message : message })
        if (validation && message.ok) {
            console.log("enviar correo de nuevo sobrante registrado"); //TLA mensaje de envio correo
            let params = {
                subject: `Nuevo Sobrante Registrado (${this.state.placa})`,
                emailEnd: this.props.user.CORREO_REPORTAR_SOBRANTES
                //process.env.REACT_APP_SMTP_MAIL_DESTINATION,
            }
            let content = `
            <p>
            Señores Inventarios
            <br><br>
            Se informa que ha sido registrado un sobrante 11111111  con el número de serie ${this.state.serie == null ? 'no identificado' : this.state.serie}<br><br>
            <br><br>
            ${this.props.user.fullName}
            <br><br>
            <br><br>
            Cédula:${this.props.user.numero_documento}
            <br><br>
            <br><br>
            ${this.props.sectionalsUser.sectionals[1].name}
            <br><br>
            ${this.props.dataUser.subDirLocDesc}
            <br><br>
            Correo electronico ${this.props.user.email}
            </p>
          `;
            await sendMail(params, content, []) 
        }
        this.toggleDialog()
        this.setState({ Send: false })
    }

    onSelectTipoBien = (event, input) => {
        if (input != null) {
            if (input.name.includes('OTRO')) {
                this.setState({ otro: true })
            } else {
                this.setState({ otro: false })
            }
            if (input.name.includes(this.props.surplusAssetTypes[2].name)) {
                this.setState({ isMobiliario: true })
            } else {
                this.setState({ isMobiliario: false })
                this.setState({ serie: '' })
            }
            this.setState({ tipoBienCode: input.id })

        } else {
            this.setState({ isMobiliario: false, otro: false })
            this.setState({ tipoBienCode: '' })
        }

    }

    renderDialogAlert() {
        return (
            <Dialog
                open={this.state.openAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ color: '#5F6273', fontSize: '1.25rem' }} id="alert-dialog-title">Señor usuario</DialogTitle>
                <DialogContent className='dialogAlertRegisterSurplus'>
                    <DialogContentText style={{ colot: '#5F6273', fontSize: '0.875rem' }} id="alert-dialog-description">
                        {this.state.alertResult}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='actionsAlertRegisterSurplus' style={{ padding: '5%' }}>
                    <Button className='btnPrimary' onClick={() => {
                        this.toggleDialog()
                        if (this.state.alertResult.includes('registrada' || 'actualizada')) {
                            this.props.history.push('/toleftover')
                        }
                    }} color="primary" autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }


    render() {
        if (this.props.surplusAssetTypes.length > 0 && this.props.locations.length > 0 && this.dependencys.length > 0) {
            return (
                <>
                    <Grid className='reportForm' container direction="column" justifyContent="center" alignItems="center">
                        <Grid item style={{ height: '50%', width: '100%' }}>
                            <h1 className='reportTittle'>EVALUACIONES</h1>
                            <Card className='cardRegistroSobrante'>
                                <CardHeader style={{ paddingLeft: '2rem' }} className='tittleCard tittleCard1' title={"Registrar Información del sobrante"} />
                                <CardHeader style={{ paddingLeft: '2rem' }} className='tittleCard tittleCard2' title={"Registrar Información sobrante"} />
                                <Grid className='containerMedio' container>
                                    <Grid xs sm lg={6} xl={6} item container>
                                        <Grid item xs={12}>
                                            <CardContent className='padding0'>
                                                <div className='labelFlex'>
                                                    <label>Tipo de bien:</label>
                                                    <div className='IndicadorImportancia'>*</div>
                                                </div>
                                                <Autocomplete
                                                    style={{ width: 'auto', padding: 0 }}
                                                    className='field'
                                                    id="cbx-TipoBien"
                                                    options={this.props.surplusAssetTypes.concat([{ id: 1, name: "OTRO", description: "", value: "" }])}
                                                    onChange={this.onSelectTipoBien}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => <TextField required fullWidth {...params} variant="outlined" />}
                                                />
                                            </CardContent>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CardContent className='padding0'>
                                                <div className='labelFlex'>
                                                    <label>Placa: </label>
                                                    <div className='IndicadorImportancia'></div>
                                                </div>
                                                <TextField
                                                    className='field' style={{ padding: 0 }}
                                                    fullWidth id="placa" value={this.state.placa} 
                                                    onChange={(e) => this.setState({ placa: e.target.value.trim() })} variant="outlined" />
                                            </CardContent>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CardContent className='padding0'>
                                                <div className='labelFlex'>
                                                    <label>Ubicación: </label>
                                                    <div className='IndicadorImportancia'>*</div>
                                                </div>
                                                <Autocomplete
                                                    style={{ width: 'auto', padding: 0 }}
                                                    className='field'
                                                    id="cbx-Ubicacion"
                                                    options={this.props.locations.map((option) => option.fullname)}
                                                    onChange={(event, input) => {
                                                        if (input.length > 0) {
                                                            let find = this.props.locations.filter(i => i.fullname.includes(input));
                                                            if (find.length > 0) {
                                                                this.setState({ location: find[0].id })
                                                            }
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField fullWidth {...params} variant="outlined" />}
                                                />
                                            </CardContent>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CardContent className='padding0'>
                                                <div className='labelFlex'>
                                                    <label>Teléfono de contacto: </label>
                                                    <div className='IndicadorImportancia'>*</div>
                                                </div>
                                                <TextField
                                                    className='field'
                                                    required fullWidth id="contacto" type="text" value={this.state.contacto} onChange={(e) => this.setState({ contacto: e.target.value })} variant="outlined" />
                                                {this.state.validations.contactLength == false &&
                                                    <Alert className='alertaRegistro' style={{ marginTop: '0.3rem' }} severity='error'>
                                                        El número de contacto debe contener al menos 7 dígitos Y menor a o igual 20 digitos
                                                    </Alert>}
                                            </CardContent>
                                        </Grid>
                                    </Grid>

                                    <Grid xs sm lg={6} xl={6} style={{ alignSelf: 'end' }} item container>

                                        <Grid item xs={12}>
                                            {this.state.otro &&
                                                <CardContent className='padding0'>
                                                    <div className='labelFlex'>
                                                        <label>Escriba el tipo de bien:</label>
                                                        <div className='IndicadorImportancia'>*</div>
                                                    </div>
                                                    <TextField
                                                        className='field'
                                                        required fullWidth id="otro" value={this.state.tipoBienOtro} onChange={(e) => this.setState({ tipoBienOtro: e.target.value })} variant="outlined" />
                                                </CardContent>
                                            }
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CardContent className='padding0'>
                                                <div className='labelFlex'>
                                                    <label>Descripción del bien: </label>
                                                    <div className='IndicadorImportancia'>*</div>
                                                </div>
                                                <TextField
                                                    className='field'
                                                    required fullWidth id="descbien" value={this.state.descripcionBien} onChange={(e) => this.setState({ descripcionBien: e.target.value })} variant="outlined" />
                                                {this.state.validations.descriptionLength === false &&
                                                    <Alert className='alertaRegistro' style={{ marginTop: '0.3rem' }} severity='error'>
                                                        La descripción del bien debe contener al menos 2 palabras y máximo 100 caracteres.
                                                    </Alert>}
                                            </CardContent>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {this.state.isMobiliario == false && <CardContent className='padding0'>
                                                <div className='labelFlex'>
                                                    <label>Serie: </label>
                                                    <div className='IndicadorImportancia'></div>
                                                </div>
                                                <TextField
                                                    className='field'
                                                    fullWidth id="serie" value={this.state.serie} onChange={(e) => this.setState({ serie: e.target.value })} variant="outlined" />
                                                {/* {this.state.validations.serieLength === false &&
                                                    <Alert className='alertaRegistro' style={{ marginTop: '0.3rem' }} severity='error'>
                                                        Debe diligenciar máximo 30 caracteres.
                                                    </Alert>} */}
                                            </CardContent>}

                                        </Grid>
                                        <Grid item xs={12}>
                                            <CardContent className='padding0'>
                                                <div className='labelFlex'>
                                                    <label>Dependencia: </label>
                                                    <div className='IndicadorImportancia'>*</div>
                                                </div>
                                                <TextField
                                                    className='field'
                                                    required fullWidth id="descdependencia" value={this.state.descripcionDependencia} onChange={(e) => this.setState({ descripcionDependencia: e.target.value })} variant="outlined" />
                                                {this.state.validations.descriptionLength === false &&
                                                    <Alert className='alertaRegistro' style={{ marginTop: '0.3rem' }} severity='error'>
                                                        La descripción de la dependencia debe contener al menos 7 caracteres y máximo 100.
                                                    </Alert>}
                                            </CardContent>
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <CardContent className='padding0'>
                                                <div className='labelFlex'>
                                                    <label>Dependencia: </label>
                                                    <div className='IndicadorImportancia'>*</div>
                                                </div>
                                                <Autocomplete
                                                    style={{ width: 'auto', padding: 0 }}
                                                    className='field'
                                                    id="cbx-Dependency"
                                                    options={this.dependencys}
                                                    onChange={(event, input) => {
                                                        this.setState({ dependencia: input })
                                                    }}
                                                    getOptionLabel={(option) => option.title}
                                                    renderInput={(params) => <TextField required fullWidth {...params} variant="outlined" />}
                                                />
                                            </CardContent>
                                        </Grid> */}
                                    </Grid>
                                </Grid>

                                <CardActions>
                                    <Button className='btnPrimary' style={{ margin: '1rem auto', width: 'auto' }} onClick={e => this.onRegisterSurplus()} color="primary">
                                        {this.state.Send ? 'Registrando...' : 'Registrar'}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid >
                    {this.renderDialogAlert()}
                </>
            );
        } else {
            return (<div style={{ display: "flex", justifyContent: "center", marginTop: '1rem' }}>
                <CircularProgress className="circular-progress" />
            </div>)
        }
    }
}
// props.locations.map((option) => option.fullname)
const mapStateToProps = state => ({
    surplusDataSearch: state.surplusSearched,
    surplusAssetTypes: state.SUSILists.surplusAssetTypes,
    historyList: state.inventoryHistory,
    params: state.getActualParams,
    withoutFillingOuts: state.withoutFillingOut,
    user: state.user,
    assignedAssets: state.assignedAssets,
    locations: state.locations.locations,
    assetsStatus: state.SUSILists.assetsStatus,
    changesTypes: state.SUSILists.changesTypes,
    missingReasons: state.SUSILists.missingReasons,
    sectionalsUser: state.sectionals,
    dataUser: state.dataUserReducer.dataDirectory,
    stages: state.stages,
    SyncSIAF: state.syncSIAF.isFetching,
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(
    withRouter(RegisterAssetSurplus)
);