import React, { useEffect, useRef, useState } from 'react';
import useStyles from '../../assets/jss/online-inventory/online-inventory';
import { createInscription, getInscription, closeInscription } from "../../redux/actions/inscriptionAction";
import { fetchInventoryHistory } from '../../redux/actions/inventoryHistoryActions';
import {AlertTitle } from "@material-ui/lab";
import InfiniteScroll from "react-infinite-scroll-component";
import { file_size_MB, susi_lists } from '../../config';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import {
  Paper, Grid, Typography, ButtonBase, ButtonGroup, Tabs, Tab,
  TextField,  Switch, Box, Radio, withStyles, FormControl, RadioGroup,
  FormControlLabel, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Avatar, Tooltip, Chip, TextareaAutosize, styled
} from '@material-ui/core';


import {IconButton, Button, Table, TableHead, TableRow, TableCell, TableBody,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { Fab } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import CommentIcon from '@material-ui/icons/Comment';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import AddCircleOutlineTwoToneIcon from "@material-ui/icons/AddCircleOutlineTwoTone";

import { updateAssignedAsset } from '../../redux/actions/assignedAssetActions';
import { getIdOptionSUSIList, getNameOptionSUSIList } from '../../redux/actions/SUSIListsActions';
import { updateUbication } from '../../redux/actions/ubicationsActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import AutoComplete from '@material-ui/lab/Autocomplete';

import { Alert, Autocomplete } from '@material-ui/lab';

import { fetchLocations } from '../../redux/actions/locationsActions';
import { DocumentService } from '../../services/generateDocumentService';
import { pdf } from '@react-pdf/renderer';
import { Editor } from '@tinymce/tinymce-react';
import { sendMail, sendEmailSoporte } from '../../redux/actions/sendMailActions';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {DOCUMENTS_FOLDER} from "../../config";
import {CompetitionParticipant} from './CompetitionParticipant';

import { jsPDF } from 'jspdf';
import { isEmpty, isNull } from 'lodash';
import { green } from '@material-ui/core/colors';

const userrole =  window.sessionStorage.getItem('userrole');
const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
    color: '#6ce1f5'
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const MaterialUISwitch = styled(Switch)(() => ({
  margin: '0 0.5rem',
  '& .MuiSwitch-switchBase': {
    color: '#3f66c4',
    '&.Mui-checked': {
      color: '#ef4682'
    },
  },
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'white',
  border: '2px solid #9d9ea5',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#fff',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.--%3E%3Cpath d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7l233.4-233.3c12.5-12.5 32.8-12.5 45.3 0z' fill='%236ce1f5'/%3E%3C/svg%3E\")",
    content: '""',
  },
  border: '3px solid #6ce1f5',
});


const AssignedAsset = (props) => {

  const classes = useStyles();

// TLA nuevo
const initialState = {
  competitionId: props.data.competition_id,
  presentationId: '1',
  presentationName: 'Poster-ePoster',
  categoryId: '',
  categoryName: '',
  thematicAreaId: '',
  memberIdcard: '',
  memberFullName: '',
  articleTitle: '',
  articleShortTitle: '',
  articleKeyword1: '',
  articleKeyword2: '',
  articleKeyword3: '',
  articleKeyword4: '',
  articleKeyword5: '',
  articleAuthors: [
    {
        authorId: '',
        authorName: '',
        authorLastname: '',
        authorRole: '',
        authorReceiveMail: '',
        authorSpeaker: '',
        authorMobile: '',
        authorEmail: '',
        authorCompany: '',
        authorConflict: '',
        authorConflictWich: '',
        authorORCID:'',
        filiations: [
            {
                companyId: '',
                companyName: '',
                companyService:'',
                companyDepartament: '',
                companyCity: '',
            }
        ]
    }
  ],
  articleObjetive: '',
  articleMethod:   '',
  articleResult:   '',
  articleConclusion: '',
  articleSubmitted: '',
  articleSubmittedWhere: '',
  inscriptionState: 0,
  tipos: '',
  otro: false,
  contenido: '',
  isMobiliario: false,
  tipoBienOtro: '',
  descripcionBien: '',
  descripcionDependencia: '',
  contacto: '',
  location: '',
  seccional: '',
  dependencia: '',
  tipoBienCode: '',
  messageAlert: '',
  openAlert: false,
  Send: false,
  validations: {
    descriptionLength: true,
    contactLength: true,
    serieLength: true,
    descripcionDependenciaLength: true,
    memberValid: 'Debe digitar el número del documento del Miembro ACHO para poder continuar con inscripción',
  },
  paramsFetchSectionals: {
    user_susi_id: props.user.user_susi_id,
  },
};


  let li = [];
  let assetStatusBD = "";
  if (props.data.competition_state === 1) {
    assetStatusBD = true;
  } else {
    assetStatusBD = false;
  }
  const initialFormAuthor = {
    authorId: '',
    authorName: '',
    authorLastname: '',
    authorRole: '',
    authorReceiveMail: '',
    authorSpeaker: '',
    authorMobile: '',
    authorEmail: '',
    authorCompany: '',
    authorConflict: '',
    authorConflictWich: '',
    authorORCID:''
  };
  const [formAuthor, setFormAuthor] = useState(initialFormAuthor);



  const [culminationDate, setculminationDate] = useState(new Date(`${props.user.culmination_date} / ${new Date().getFullYear()}`));
  const [assetStatus, setAssetStatus] = useState(assetStatusBD);
  const [assignedAssetID, setAssignedAssetID] = useState(props.data.competition_id);
  const [showDialogStatusBad, setDialogStatusBad] = useState(false);
  const [showDialogShare, setShowDialogShare] = useState(false);
  const [alertObservations, setAlertObservations] = useState(true);
  
  const [changeType, setChangeType] = useState(props.data.change_type_id);
  const [missingReason, setMissingReason] = useState(props.data.missing_reason_id);
  const [errorFormMissing, setErrorFormMissing] = useState('');
  const [observations, setObservations] = useState(props.data.observations);
  const [savingMissingReason, setSavingMissingReason] = useState(false);
  const [recordsLocation, setRecordsLocation] = useState([]);
  const [listHeadQuarters, setListHeadQuarters] = useState(false);
  const loading = listHeadQuarters && props.locations.length === 0;
  const [headquarterChs, setHeadQuarterChs] = useState(props.ubication);
  const [locationChs, setLocationChs] = useState(props.data.location_complement);
  const [switchOption2, setSwitchOption2] = useState(false);
  const [switchOption3, setSwitchOption3] = useState(false);
  const [switchOption4, setSwitchOption4] = useState(false);  
  const [showDialogTypeMissing, setShowDialogTypeMissing] = useState(false)
  const [textOption, setTextOption] = useState('')
  const [showDialogtheft, setShowDialogtheft] = useState(false)
  const [inscriptionPendiente, setInscriptionPendiente] = useState([]);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [terminar, setTerminar] = useState(false);
  //const [isOut, setIsOut] = useState(props.isOut);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(props.user.CORREO_REPORTAR_REIMPRESION_PLACA)
  const [ccmail, setCcEmail] = useState([])
  const [razonReimpresion, setRazonReimpresion] = useState(null)
  const [asuntoMail, setAsuntoMail] = useState(`Solicitud Renovación de plaqueta adhesiva (${props.data.placaBien})`)
  const [sended, setSended] = useState(false)
  const [enableFeaturesPrint, setEnableFeaturesPrint] = useState(false)
  const [emailAlert, setEmailAlert] = useState('')
  const [totalPalabras, setTotalPalabras] = useState(0)
  const [pdfFile, setPdfFile] = useState(null)
  const [pdfFile1, setPdfFile1] = useState(null)
  const editorRef = useRef(null);

  const [saveHeadquarterChs, setSaveHeadquarterChs] = useState(props.ubication);
  const [saveLocationChs, setSaveLocationChs] = useState(props.data.location_complement);
  //const [alertRuleNotify, setAlertRuleNotify] = useState(li.length);
  const [displayAlert, setDisplayAlert] = useState('none');

  const configDate = { year: 'numeric', month: 'long', day: 'numeric' };

  const [state, setState] = useState(initialState);
  const [temporalFiliations,setTemporalFiliations]=useState([]);
  const [isFiliationValid, setIsFiliationValid] = useState(true);
  const [temporalAuthors,setTemporalAuthors]=useState([]);
  const [isAuthorValid, setIsAuthorValid] = useState(true);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [thematic, setThematic] = useState(null);
  const [checkedAuthors, setCheckedAuthors] = useState({});
  const [checkedReceiveMail, setCheckedReceiveMail] = useState({});
  const [checkedSpeaker, setCheckedSpeaker] = useState({});
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfUrl1, setPdfUrl1] = useState(null);
  const [pdfUrl2, setPdfUrl2] = useState(null);
  const [openPendient, setOpenPendient] = useState(null);
  const [openConvoca, setOpenConvoca] = useState(false);
  const [titleAlert, setTitleAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [tabIndex0, setTabIndex0] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeAuthor = (event) => {
    const { id, value } = event.target;
    setFormAuthor(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleTabChange0 = (event, newIndex) => {
    setTabIndex0(newIndex);
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const colorTexto = totalPalabras > 350 ? 'red' : 'success';
console.log("ESTADO INICIAL USESTATE en assignedasset ********");
console.log(state);
console.log("props");
console.log(props.propsDad);

//TLA nuevo
const yesnot = [
  { id: '1', label: 'Sí' },
  { id: '2', label: 'No' },
];

const handleBuscar = () => {
  console.log("EN BUSCAR");
  console.log(state);
  
  const miembroExiste = props.propsDad.members.members.find(p => p.memberIdcard == state.memberIdcard && p.memberState == 1);
  
  console.log(miembroExiste);
  
  if (miembroExiste) {
    setState(prevState => ({
      ...prevState,
      memberFullName: miembroExiste.memberName + ' ' + miembroExiste.memberLastname,
      validations: {
        ...prevState.validations,
        memberValid: ''
      }
    }), () => {
      console.log("Estado actualizado:", state);
    });
    console.log("existe");
  } else {
    setState(prevState => ({
      ...prevState,
      validations: {
        ...prevState.validations,
        memberValid: 'No se encontró un miembro ACHO activo'
      },
      memberFullName: '',
      memberIdcard: null
    }), () => {
      console.log("Estado actualizado:", state);
      console.log("Nuevo valor de memberValid:", state.validations.memberValid);
    });
    setTitleAlert('VALIDACIÓN MIEMBRO ACHO');
    setMessageAlert('No se encontró un miembro ACHO activo');
    handleChangeStatus();

  }
  
  console.log("ESTADO ACTUALIZADO");
  console.log(state);


};
const handleChange = (e) => {
  const { name, value } = e.target;
  if (name === 'articleTitle' && value.replace(/\s/g, '').length > 200) {
    return; // Si la longitud excede el límite, no actualizamos el estado
  }
const wordCounts = {
  articleObjetive: state.articleObjetive ? state.articleObjetive.trim().split(/\s+/).filter(Boolean).length : 0,
  articleMethod: state.articleMethod ? state.articleMethod.trim().split(/\s+/).filter(Boolean).length : 0,
  articleResult: state.articleResult ? state.articleResult.trim().split(/\s+/).filter(Boolean).length : 0,
  articleConclusion: state.articleConclusion ? state.articleConclusion.trim().split(/\s+/).filter(Boolean).length : 0,
};
  const totalWords = Object.values(wordCounts).reduce((total, count) => total + count, 0);
  setTotalPalabras(totalWords);
  // Validar la suma de palabras
  if (totalWords > 400 &&
     (name === 'articleObjetive' || name === 'articleMethod' 
      || name === 'articleResult' || name === 'articleConclusion')
     ) {
    setTitleAlert('VALIDACIÓN LONGITUD DEL TRABAJO ');
    setMessageAlert('Se ha alcanzado el limite de palabras máximo 400');
    handleChangeStatus();
    //alert('Se ha alcanzado el límite de palabras (400).', 'Alerta de límite de palabras');
    return; // Si la suma de palabras excede el límite, no actualizamos el estado
  }


  setState(prevState => ({
    ...prevState,
    [name]: value
  }));
};

useEffect(() => {
  console.log("Estado actualizado:", state);
}, [state]);

  const handleBlur = () => {
    handleBuscar();
  };
  
useEffect(() => {
    if (openPrint) {
      downloadPdf();
    }
}, [openPrint]);

useEffect(() => {
  if (openConvoca) {
    downloadFile();
  }
}, [openConvoca]);

  const toggleDialog = () => {

  const currentState = open;
    if (!open) {
      setSended(false);
      setEmailAlert('');
      setRazonReimpresion(true);
      setCcEmail([]);
    }
    setOpen(!currentState);

  };

   


  const toggleDialogConvoca = () => {
    const currentState = openConvoca;
    setOpenConvoca(!currentState);
  };

  const toggleDialogPrint = () => {
    const currentState = openPrint;
    setOpenPrint(!currentState);
  };

  // Mensajes icono de alarma
  if (props.data.estadoBien == 'En tramite de reposición') {
    li.push('Responsabilidad en proceso');
  }
  if (changeType == getIdOptionSUSIList('Faltante', props.changesTypes)) {
    li.push('Novedad: Faltante');
  }
  if (assetStatus == true) {
    li.push('Estado del bien: Regular');
  }
  if (new Date(props.data.date_checklist).getFullYear() < new Date().getFullYear()) {
    li.push('Verificación vigente: sin reportar');
  }

  /**
   * Maneja el evento de hacer clic en el check de Estado
  */
  const handleChangeStatus = async () => {
      setDialogStatusBad(true);
    // if (assignedAssetID) {
    //   updateAssignedAsset({
    //     id: assignedAssetID,
    //     asset_status: (!assetStatus) ? getIdOptionSUSIList('Regular', props.assetsStatus) : getIdOptionSUSIList('Bueno', props.assetsStatus),
    //     change_type: changeType
    //   });
    // } else {
    //   console.error('Se esta intentando actualizar un estado de un bien que no existe');
    // }
    // setAssetStatus(!assetStatus);
    // props.getCountWithoutUpdate()
  };


  const addTextWithDynamicPosition = (content, x, y, fontSize) => {
    const pdfDoc = new jsPDF();
  
    pdfDoc.setFontSize(fontSize);
    pdfDoc.setFont('Times New Roman', 'normal');
  
    const textWidth = pdfDoc.getTextWidth(content);
  
    pdfDoc.text(content, x, y);
  
    return { xEnd: x + textWidth, yEnd: y }; // Devuelve la posición X y Y donde termina el texto
  };

  const downloadPdf = async () => {
    console.log("antes de archivo");
    console.log(temporalAuthors);
    console.log(state);
    var articlePdf = await pdf(DocumentService.generateDocument([
      {
        competitionId  :   state.competitionId,
        presentationId :   state.presentationId,
        presentationName:  state.presentationName,
        categoryId     :   state.categoryId,
        categoryName   :   state.categoryName,
        thematicAreaId :   state.thematicAreaId,
        idUser         :   props.user.user_susi_id,
        memberIdcard:      state.memberIdcard,
        articleSubmitted:  state.articleSubmitted,
        articleSubmittedWhere: state.articleSubmittedWhere,
        articleKeyword1  : state.articleKeyword1,
        articleKeyword2  : state.articleKeyword2,
        articleKeyword3  : state.articleKeyword3,
        articleKeyword4  : state.articleKeyword4,
        articleKeyword5  : state.articleKeyword5,
        articleTitle     : state.articleTitle,
        articleShortTitle: state.articleShortTitle,
        articleObjetive  : state.articleObjetive,
        articleMethod    : state.articleMethod,
        articleResult    : state.articleResult,
        articleConclusion: state.articleConclusion,
        articleAuthors   : temporalAuthors,
        estadoBien: props.data.estadoBien,
        assetStatus: assetStatus,
        ubicacion: headquarterChs,
        lastCheck: props.data.date_checklist,
        dependencia: props.data.dependencia,
        seccional: props.data.nombreSeccional,
        funcionarioSolicitante: props.data.funcionarioSolicitante,
        features: temporalFiliations,
        responsable: props.user.fullName,
        consecutivo: terminar,
        inscriptionId: state.inscriptionId
      }
    ], false, true,false)).toBlob();

    var csvURL = window.URL.createObjectURL(articlePdf);
    setPdfUrl(csvURL);

    var summaryPdf = await pdf(DocumentService.generateDocument([
      {
        competitionId  :   state.competitionId,
        presentationId :   state.presentationId,
        presentationName:  state.presentationName,
        categoryId     :   state.categoryId,
        categoryName   :   state.categoryName,
        thematicAreaId :   state.thematicAreaId,
        idUser         :   props.user.user_susi_id,
        memberIdcard:      state.memberIdcard,
        articleSubmitted:  state.articleSubmitted,
        articleSubmittedWhere: state.articleSubmittedWhere,
        articleKeyword1  : state.articleKeyword1,
        articleKeyword2  : state.articleKeyword2,
        articleKeyword3  : state.articleKeyword3,
        articleKeyword4  : state.articleKeyword4,
        articleKeyword5  : state.articleKeyword5,
        articleTitle     : state.articleTitle,
        articleShortTitle: state.articleShortTitle,
        articleObjetive  : state.articleObjetive,
        articleMethod    : state.articleMethod,
        articleResult    : state.articleResult,
        articleConclusion: state.articleConclusion,
        summary          : true,
        estadoBien: props.data.estadoBien,
        assetStatus: assetStatus,
        ubicacion: headquarterChs,
        lastCheck: props.data.date_checklist,
        dependencia: props.data.dependencia,
        seccional: props.data.nombreSeccional,
        funcionarioSolicitante: props.data.funcionarioSolicitante,
        features: props.data.features,
        responsable: props.user.fullName,
        consecutivo: terminar,
        inscriptionId: state.inscriptionId
      }
    ], false, enableFeaturesPrint, true)).toBlob();
    var csvURL1 = window.URL.createObjectURL(summaryPdf);
    setPdfUrl1(csvURL1);

    // var pdfBlob = await articlePdf.toBlob();

    var newPdfFile  = new File([articlePdf], 'article.pdf', { type: 'application/pdf' });
    var newPdfFile1 = new File([summaryPdf], 'summary.pdf', { type: 'application/pdf' });
    setPdfFile(newPdfFile);
    setPdfFile1(newPdfFile1);

    // let tempLink = window.document.createElement('a');
    // tempLink.href = csvURL;
    // tempLink.setAttribute('download', 'ACHO - ' + props.data.placaBien + '.pdf');
    // tempLink.click();
  }

  const handleChangeType = async (event) => {
    if (event.target.value == getIdOptionSUSIList('Faltante', props.changesTypes)) {
      setShowDialogTypeMissing(true);
    }
    setChangeType(event.target.value);
    props.getCountWithoutUpdate()
    if (assignedAssetID && event.target.value == getIdOptionSUSIList('Verificado', props.changesTypes)) {
      updateAssignedAsset({
        id: assignedAssetID,
        assetStatus: (assetStatus) ? getIdOptionSUSIList('Regular', props.assetsStatus) : getIdOptionSUSIList('Bueno', props.assetsStatus),
        change_type: event.target.value
      });
    } else if (event.target.value != getIdOptionSUSIList('Faltante', props.changesTypes)) {
      console.error('Se esta intentando actualizar un estado de un bien que no existe');
    }
  };

  const handlepresentation = (event) => {
    const selectedpresentation =  props.propsDad.presentation.find(presentation => presentation.presentationId == event.target.value);
    setState({
        ...state,
        presentationId: event.target.value,
        presentationName: selectedpresentation ? selectedpresentation.presentationName : ''
    });
};

    const handleCategory = (event) => {
      const selectedCategory = props.propsDad.categories.find(category => category.categoryId === event.target.value);
      setState({
          ...state,
          categoryId: event.target.value,
          categoryName: selectedCategory ? selectedCategory.categoryName : ''
      });
    };

    const handleArticleSubmitted = (event) => {
      const selectedArticleSubmitted = yesnot.find(submitted => submitted.id === event.target.value);
      setState({
          ...state,
          articleSubmitted: event.target.value,
          articleSubmittedName: selectedArticleSubmitted ? selectedArticleSubmitted.label : ''
      });
    };

    const handleAuthorConflict = (event) => {
      const selectedAuthorConflict = yesnot.find(conflict => conflict.id === event.target.value);
      setState({
          ...state,
          authorConflict: event.target.value,
          authorConflictName: selectedAuthorConflict ? selectedAuthorConflict.label : ''
      });
    };


  const handleMissingReason = (event) => {
    let nameSUSIList = getNameOptionSUSIList(event.target.value, props.missingReasons)
    props.getCountWithoutUpdate()
    setMissingReason(event.target.value);
    setErrorFormMissing("");
    if (nameSUSIList == 'Perdida') {
      setTextOption('Se considera la pérdida de un bien, cuando de forma voluntaria o involuntaria se ocasiona el extravió físico del mismo. Esta novedad debe ser comunicada al jefe inmediato, y de igual forma podrá iniciar la reclamación ante el seguro, reposición voluntaria o consignación ante el tesoro nacional.')
    } else {
      setTextOption('Se considera que un bien ha sido hurtado, cuando el mismo ha sido tomado de forma ilegítima sin su consentimiento por un tercero. Esta novedad debe ser comunicada al jefe inmediato, y de igual forma podrá iniciar la reclamación ante el seguro, reposición voluntaria o consignación ante el tesoro nacional.')
    }
    setShowDialogtheft(false) //TLA deshabilito 
    //updateAssignedAsset({id: assignedAssetID, missing_reason: event.target.value});
  };

  const handleChangeSwitch2 = async (event) => {
    const id = event.currentTarget.id;
    setThematic(id);
    setDisplayAlert('none')
    // this.props.propsDad.myInscriptions.dispatch(fetchInventoryHistory({ idUser: props.user.user_susi_id }));
    if (props.propsDad.myInscriptions && props.propsDad.myInscriptions.length > 0) {
      let pendiente = await props.propsDad.myInscriptions.find(pendiente => pendiente.inscriptionState == 0);
      console.log("encontro");
      console.log(pendiente);
      if(pendiente){setOpenPendient(true);}else{
        setSwitchOption2(!switchOption2);
        setOpen3(true);

      }
    }else{
      setSwitchOption2(!switchOption2);
      setOpen3(true);
    }
  };

  const handleChangeSwitch3 = () => {
    setTemporalFiliations([]);
    
    setSwitchOption3(!switchOption3);
    setOpen4(true);
    setDisplayAlert('none')
  };

  const handleChangeSwitch4 = () => {
    setSwitchOption4(!switchOption4);
    setOpen5(true);
    setDisplayAlert('none')
  };




  const handleClose = () => {
  //  props.getCountWithoutUpdate()
    setDialogStatusBad(false);
  };

  const closeDialogTypeMissing = () => {
    setAlertObservations(true)
    setShowDialogTypeMissing(false);
    setChangeType(props.data.change_type_id)
  };

  const notifyAlert = () => {
    if (li.length > 0) {
      return (
        <ul style={{ margin: 0, padding: 0 }}>
          {li.map((item, count) => {
            return (
              <li key={count}>{item}</li>
            )
          })}
        </ul>
      );
    } else {
      return ''
    }
  }

  const handleClose3 = async () => {
    //console.log('recordsLocation: ',recordsLocation,' headquarterChs: ',headquarterChs);
    if (recordsLocation != null && headquarterChs.length > 0) {
      await updateUbication({ idAsset: assignedAssetID, complement: locationChs, idLocation: recordsLocation })
      setOpen3(false);
      setSaveHeadquarterChs(headquarterChs)
      setSaveLocationChs(locationChs)
      props.getCountWithoutUpdate()
      setDisplayAlert('none')
    } else {
      setDisplayAlert('flex')
    }
  };

  const handleClose4 = async () => {
    //console.log('recordsLocation: ',recordsLocation,' headquarterChs: ',headquarterChs);
    if (recordsLocation != null && headquarterChs.length > 0) {
      await updateUbication({ idAsset: assignedAssetID, complement: locationChs, idLocation: recordsLocation })
      setOpen4(false);
      setSaveHeadquarterChs(headquarterChs)
      setSaveLocationChs(locationChs)
      props.getCountWithoutUpdate()
      setDisplayAlert('none')
    } else {
      setDisplayAlert('flex')
    }
  };

  const handleClose5 = async () => {
    //console.log('recordsLocation: ',recordsLocation,' headquarterChs: ',headquarterChs);
    if (recordsLocation != null && headquarterChs.length > 0) {
      await updateUbication({ idAsset: assignedAssetID, complement: locationChs, idLocation: recordsLocation })
      setOpen5(false);
      setSaveHeadquarterChs(headquarterChs)
      setSaveLocationChs(locationChs)
      props.getCountWithoutUpdate()
      setDisplayAlert('none')
    } else {
      setDisplayAlert('flex')
    }
  };


  const handleObservations = (event) => {
    setObservations(event.target.value)
  }

  const handleSaveReasonMissing = async () => {
    let threeWords = []
    if (observations != null) {
      threeWords = observations.trim().split(/(\s)/).filter(i => i.trim().length > 0);
    }
    if (!savingMissingReason) {
      if (!missingReason) {
        setErrorFormMissing("Debe seleccionar una razón de Faltante");
        return;
      } else if (!observations) {
        setErrorFormMissing("Debe ingresar observaciones");
        return;
      } else if (threeWords.length < 3) {
        setErrorFormMissing("Minimo debe tener 3 palabras");
        return;
      } else {
        setErrorFormMissing("");
      }

      const savedRecord = await updateAssignedAsset({
        id: assignedAssetID,
        change_type: susi_lists.newness.missing,
        missing_reason: missingReason,
        observations: observations
      });
      if (savedRecord) {
        setSavingMissingReason(false);
        setShowDialogTypeMissing(false);
      }
      props.getCountWithoutUpdate()
      setAlertObservations(true)
    }
  }
 const handleDialogShare = () => {
    setShowDialogShare(true);
 } 

  const validateEmailString = (input = '') => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(input)
  }

  const handleCloseDialogShare = () => {
    setShowDialogShare(false);
  }

  const searchLocation = (event, inputValue) => {
    let params = {
      texto: inputValue
    };
    props.propsDad.dispatch(fetchLocations(params));
  }

  const selectHeadQuarter = (event, inputValue) => {
    if (inputValue == null) inputValue = headquarterChs
    let structHQ = props.locations.filter(hqBD => hqBD.fullname == inputValue);
    if (structHQ.length > 0) {
      setRecordsLocation(structHQ[0].id);
      setHeadQuarterChs(inputValue);
    }
  }


const downloadFile = async () => {
  const pdfPath = process.env.PUBLIC_URL + DOCUMENTS_FOLDER +  'CONVOCATORIA_A.pdf';
  console.log("convocatoria");
  console.log(pdfPath);
  // Descargar el archivo PDF como un Blob
  const response = await fetch(pdfPath);
  const blob = await response.blob();

  // Crear una URL para el Blob
  const blobUrl2 = URL.createObjectURL(blob);

  // Actualizar el estado con la URL del Blob
  if(blobUrl2) {setPdfUrl2(blobUrl2)};
}


const renderDialogConvoca = () => {

  return (
    <div>
    <Dialog  
      PaperProps={{
        style: {
          paddingBottom: '0 !important',
          // overflowY: 'auto',
          maxHeight: '90vh',
          width: '80vw',          // Ancho al 100% de la ventana del navegador
          maxWidth: 'none !important',  // Sin límite de ancho máximo
          opacity: '1 !important',              // Desactivar la opacidad
          // scrollbarWidth: 'auto',  // Ancho del scrollbar
          // scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
        }
      }} 
      fullWidth={false}
      maxWidth={false}
      className='dialogReounded dialogUbication' 
      open={openConvoca} 
      aria-labelledby="form-dialog-title"
    >
        <DialogTitle className='dialogTttle' id="alert-dialog-title" >
                CONVOCATORÍA 
        </DialogTitle>
        
        <DialogContent style={{ paddingLeft: '2rem' }}>
          <DialogContentText id="alert-dialog-description">
            {pdfUrl2 && <iframe src={pdfUrl2} width="100%" height="600px"></iframe>}
          </DialogContentText>         
        </DialogContent>
        <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
          <Button className='btnPrimary' onClick={toggleDialogConvoca} color="primary">
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}




  /* renderDialog */

  const renderDialogPrint = () => {


    return (
      <div>
      <Dialog  
        PaperProps={{
          style: {
            paddingBottom: '0 !important',
            // overflowY: 'auto',
            maxHeight: '90vh',
            width: '80vw',          // Ancho al 100% de la ventana del navegador
            maxWidth: 'none !important',  // Sin límite de ancho máximo
            opacity: '1 !important',              // Desactivar la opacidad
            // scrollbarWidth: 'auto',  // Ancho del scrollbar
            // scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
          }
        }} 
        fullWidth={false}
        maxWidth={false}
        className='dialogReounded dialogUbication' 
        open={openPrint} 
        aria-labelledby="form-dialog-title"
      >

          {terminar == true ? 
              <DialogTitle className='dialogTttle' id="alert-dialog-title" >
                  El Soporte de Inscripción será generado una vez de clic en el botón INSCRIBIR
              </DialogTitle>
              :
              <DialogTitle className='dialogTttle' id="alert-dialog-title" >
                  Su Inscripción no puede ser aceptada porque contiene errores
              </DialogTitle>
          }
          <DialogContent style={{ paddingLeft: '2rem' }}>
            <DialogContentText id="alert-dialog-description">
              <label>Favor verificar que toda la información se encuentre correcta</label>
              {pdfUrl && <iframe src={pdfUrl} width="100%" height="600px"></iframe>}
            </DialogContentText>
            
          </DialogContent>
          <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
            <Button className='btnPrimary' onClick={toggleDialogPrint} color="primary">
              Cancelar
            </Button>
            <Button className='btnPrimary' onClick={addInscription} disabled={!terminar} color={terminar ? "success" : "secondary"} autoFocus>
              Inscribir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const renderDialogShare = () => {
    return (
      <Dialog className='dialogReounded' open={showDialogShare}>
        <DialogTitle id="form-dialog-title">Compartir Bien</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione como quiere compartir el bien
          </DialogContentText>
          <List>
            <ListItem button onClick={() => alert("Vamos a compartir como imagen")} key="image">
              <ListItemAvatar>
                <Avatar>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Como Imagen" />
            </ListItem>
            <ListItem button onClick={() => alert("Vamos a compartir como HTML")} key="html">
              <ListItemAvatar>
                <Avatar>
                  <CommentIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Como HTML" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogShare} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleCloseDialogShare} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const renderDialogStatusBad = () => {
    return (<Dialog className='dialogReounded estadoBien'
      open={showDialogStatusBad}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      style={{ padding: '1rem', borderRadius: '20px!important', boxShadow: '0!important' }}
    >
      <DialogTitle id="form-dialog-title" color='red'>{titleAlert}</DialogTitle>
      <DialogContent style={{ padding: '40px 39px 15px' }}>
        <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
        {messageAlert}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignSelf: 'center', marginBottom: '0.938rem' }}>
        <Button className='btnError' variant='outlined' onClick={handleClose}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>);
  }

  const renderDialogChangeTypeLost = () => {
    return (
      <Dialog className='dialogReounded dialogChangeLost' open={showDialogTypeMissing} aria-labelledby="form-dialog-title">
        <DialogContent style={{ borderRadius: '1rem' }}>
          <p className='tituloCards'>Apreciad@ servidor@</p>
          <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
          Si el bien no está en su poder y no tiene control sobre él, seleccione aquí la razón de la ausencia.
          </DialogContentText>
          <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
          Cuando finalice el reporte de su inventario, podrá dirigirse al módulo Bienes <span style={{ fontWeight: 'bold' }}>No Verificados Físicamente</span> para reportar la ausencia del bien.
          </DialogContentText>

          <FormControl component="fieldset" style={{ width: '100%' }}>
            <Grid style={{ paddingLeft: '1rem' }} direction="column" container>
              <Grid item container>
                <RadioGroup aria-label="missing_reason" name="missing_reason" value={missingReason} onChange={handleMissingReason}>
                  {props.missingReasons.map((optMissingReason) => (
                    <FormControlLabel
                      key={optMissingReason.value}
                      value={optMissingReason.id}
                      control={<BlueRadio />}
                      checked={missingReason == optMissingReason.id}
                      label={optMissingReason.name} />
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item container direction="column">
                <Grid item container>
                  <label style={{ fontSize: '0.875rem', color: '#5f6273' }}>Observaciones</label>
                  <div style={{ color: 'red', paddingLeft: '0.2rem' }}>{' *'}</div>
                </Grid>
                <Grid item container>
                  <TextareaAutosize
                    onClick={() => {
                      if (alertObservations) {
                        setAlertObservations(false)
                        setTextOption('Indique de forma concreta la razón de la pérdida o hurto del bien a relacionar dentro de estas novedades.')
                        setShowDialogtheft(true)
                      }
                    }}
                    minRows={6}
                    cols={5}
                    autoFocus
                    id="name"
                    type="text"
                    value={observations == null ? ' ' : observations}
                    onChange={handleObservations}
                    className='textArea' />
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
          {errorFormMissing != '' ? (<Alert style={{ marginTop: '0.5rem' }} severity="error">{errorFormMissing}</Alert>) : null}

          <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
          <span style={{ fontWeight: 'bold' }}>Nota:</span>  No aplica para los bienes que asignó a otro servidor temporalmente en calidad de préstamo. Artículo 32. Resolución 01166 de 2021.
          </DialogContentText>

        </DialogContent>
        <DialogActions style={{ alignSelf: 'center', marginBottom: '1.938rem' }}>
          <Button onClick={closeDialogTypeMissing} style={{ width: '100px' }} className='btnSecundary' color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveReasonMissing} style={{ width: '100px' }} className='btnPrimary' color="primary">
            {savingMissingReason ? "Guardando ..." : "Aceptar"}
          </Button>
        </DialogActions>
      </Dialog>);
  }

  const agregarFiliacion = () => {
    const newFiliation = {
      companyName: document.getElementById('companyName').value,
      companyService: document.getElementById('companyService').value,
      companyDepartament: document.getElementById('companyDepartament').value,
      companyCity: document.getElementById('companyCity').value
    };
    if (newFiliation.companyName && newFiliation.companyDepartament) {  
        setTemporalFiliations(prevFiliations => [...prevFiliations, newFiliation]);
        // document.getElementById('companyName').value = '';
        // document.getElementById('companyDepartament').value = '';
        // Reiniciar el estado de validación
        console.log("graba filiations");
        console.log(temporalFiliations);
        setIsFiliationValid(true);
        setOpen5(false);
    } else {
      // Mostrar mensaje de alerta
      setTitleAlert('VALIDACIÓN DE FILIACIÓN');
      setMessageAlert('Error: Por favor, completa todos los campos.');
      handleChangeStatus();
      
      
      // Establecer el estado de validación como falso
      setIsFiliationValid(false);
    }
};

const eliminarFiliacion = (indexToRemove) => {
  setTemporalFiliations(prevFiliations => 
    prevFiliations.filter((_, idx) => idx !== indexToRemove)
  );
};

// const [selectedDepartamento, setSelectedDepartamento] = useState(props.propsDad.city[0].nombre);
// const [selectedCiudad, setSelectedCiudad] = useState(props.propsDad.city[0].ciudades[0]);

const [selectedDepartamento, setSelectedDepartamento] = useState('');
const [selectedCiudad, setSelectedCiudad] = useState('');


const handleChangeDepartamento = (event) => {
  const selected = event.target.value;
  setSelectedDepartamento(selected);
  setSelectedCiudad(props.propsDad.city.find(dep => dep.nombre === selected).ciudades[0]);
};

const handleChangeCiudad = (event) => {
  setSelectedCiudad(event.target.value);
};

useEffect(() => {
  if (props.propsDad.city && props.propsDad.city.length > 0) {
    setSelectedDepartamento(props.propsDad.city[0].nombre);
    setSelectedCiudad(props.propsDad.city[0].ciudades[0]);
  }
}, [props.propsDad.city]);


// TLA nuevo
const renderDialogFiliations = () => {
  

  return (

<Dialog  
  PaperProps={{
    style: {
      paddingBottom: '0 !important',
      //overflowY: 'auto',
      maxHeight: '90vh',
      width: '80vw',          // Ancho al 100% de la ventana del navegador
      maxWidth: 'none !important',  // Sin límite de ancho máximo
      opacity: '1 !important',              // Desactivar la opacidad
      scrollbarWidth: 'auto',  // Ancho del scrollbar
      scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
    }
  }} 
  fullWidth={false}
  maxWidth={false}
  className='dialogReounded dialogUbication' 
  open={open5} 
  aria-labelledby="form-dialog-title"
>

<DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.25rem', color: '#5f6273', margin: 0, marginTop: '0rem' }}>DATOS FILIACIÓN</DialogTitle>
<DialogContent style={{ paddingBottom: '0 !important', overflowY: 'hidden', maxHeight: '80vh'  }}>
<Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
  <label className='labelBold' >Institución:</label>
  <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth id="companyName"  variant="outlined" />
  </Grid>
</Grid>
<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >Servicio:</label>
    <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth id="companyService"  variant="outlined" />
    </Grid>
</Grid>
<Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={12} xl={12} container alignItems='center'>
    <label className='labelBold'>Departamento:</label>
  </Grid>
  <Grid item xs={12} sm={12} lg={12} xl={12}>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
  </Grid>
</Grid>

<Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={12} xl={12} container alignItems='center'>
    <label className='labelBold'>Ciudad:</label>
  </Grid>
  <Grid item xs={12} sm={12} lg={12} xl={12}>
    <select
      value={selectedCiudad}
      id="companyCity"
      onChange={handleChangeCiudad}
      style={{ width: '100%', fontSize: '1rem', height: '28px' }}
    >
      {props.propsDad.city &&
        props.propsDad.city.find(dep => dep.nombre === selectedDepartamento)?.ciudades.map((ciudad, index) => (
          <option key={index} value={ciudad}>
            {ciudad}
          </option>
        ))}
    </select>
  </Grid>
</Grid> 
 {/* TLA nuevo */}

</DialogContent>
<DialogActions style={{ justifyContent: 'center', margin: '1rem', marginTop: '1rem' }}>
<Button style={{ width: '100px' }} className='btnPrimary' onClick={() => {
//  setHeadQuarterChs(saveHeadquarterChs)
//  setLocationChs(saveLocationChs)
 setOpen5(false)
}} color="primary">
 Cancelar
</Button>
<Button style={{ width: '100px' }} className='btnPrimary' 
onClick={agregarFiliacion} 
color="primary">
 Guardar
</Button>
</DialogActions>
</Dialog>

);
}

useEffect(() => {
  console.log('temporalAuthors:', temporalAuthors);
}, [temporalAuthors]);

// TLA nuevo
const agregarAuthor = () => {
  const newAuthor = {
    authorId:         formAuthor.authorId,
    authorName:       formAuthor.authorName,
    authorLastname:   formAuthor.authorLastname,
    authorRole:        false,
    authorReceiveMail: false,
    authorSpeaker:     false,
    authorMobile:     formAuthor.authorMobile,
    authorEmail:      formAuthor.authorEmail,
    authorCompany:    formAuthor.authorCompany,
    authorORCID:      formAuthor.authorORCID,
    filiations:         temporalFiliations,
    authorConflict:     state.authorConflict,
    authorConflictWich: formAuthor.authorConflictWich
  };
  let errors = [];

  if (!newAuthor.authorId) {
    errors.push('Sin identificación del autor.');
  }
  if (!newAuthor.authorName) {
    errors.push('Sin nombres del autor.');
  }
  if (!newAuthor.authorLastname) {
    errors.push('Sin apellidos del autor.');
  }
  if (!newAuthor.authorMobile) {
    errors.push('Falta número de contacto celular');
  }
  if (!newAuthor.authorEmail) {
    errors.push('Falta correo electrónico de contacto');
  }
  // if (!newAuthor.authorCompany) {
  //   errors.push('Sin institución a la que pertenece');
  // }

  if (temporalFiliations.length < 1){
    errors.push('No se ha registrado ninguna filiación');
  }

  if (errors.length == 0) {  
      console.log("nuevo autor");
      console.log(newAuthor);
      setTemporalAuthors(prevAuthors => [...prevAuthors, newAuthor]);
      console.log(temporalAuthors);
      setFormAuthor(initialFormAuthor);
      setIsAuthorValid(true);
      setOpen4(false);
  } else {
    // Concatenar todos los errores en una sola cadena de texto
    const errorMessage = errors.join('\n');
    
    // Mostrar mensaje de alerta con todos los errores
    setTitleAlert('VALIDACIÓN DE AUTORES');
    setMessageAlert(errorMessage);
    handleChangeStatus();
    // alert(errorMessage);
    
    setIsAuthorValid(false);
  }
};

const eliminarAuthor = (indexToRemove) => {
  setTemporalAuthors(prevAuthors => 
    prevAuthors.filter((_, idx) => idx !== indexToRemove)
  );
};



const renderDialogAuthors = () => {

  return (

// {/* <Dialog  style={{ paddingBottom: '0 !important', overflowY: 'auto',  */}
<Dialog  
  PaperProps={{
    style: {
      paddingBottom: '0 !important',
      //overflowY: 'auto',
      maxHeight: '90vh',
      width: '80vw',          // Ancho al 100% de la ventana del navegador
      maxWidth: 'none !important',  // Sin límite de ancho máximo
      opacity: '1 !important',              // Desactivar la opacidad
      scrollbarWidth: 'auto',  // Ancho del scrollbar
      scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
    }
  }} 
  fullWidth={false}
  maxWidth={false}
  className='dialogReounded dialogUbication' 
  open={open4} 
  aria-labelledby="form-dialog-title"
>
<DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem', color: '#5f6273', margin: 0, marginTop: '0rem' }}>DATOS AUTORES</DialogTitle>
<DialogContent style={{ paddingBottom: '0 !important', overflowY: 'hidden', maxHeight: '98vh'  }}>


<Box sx={{ width: '100%', height: '95vh', overflow: 'hidden' }}>
      <Tabs value={tabIndex} onChange={handleTabChange} centered
      TabIndicatorProps={{ style: { backgroundColor: '#4caf50' } }}
      classes={{ indicator: classes.indicator }}>
        <Tab label="Datos Generales"   className={classes.tab}/>
        <Tab label="Filiaciones"       className={classes.tab}/>
        <Tab label="Conflicto Interés" className={classes.tab}/>
      </Tabs>

 {tabIndex===0 && (
  <>
      <Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
  <label className='labelBold' >Cédula del autor:</label>
  <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field' type='number' 
  fullWidth id="authorId"  variant="outlined" inputProps={{ maxLength: 14 }} 
  value={formAuthor.authorId}
  onChange={handleChangeAuthor}/>
  </Grid>
</Grid>
<Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
  <label className='labelBold' >Nombres:</label>
  <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field' fullWidth id="authorName"  variant="outlined" 
    value={formAuthor.authorName}
    onChange={handleChangeAuthor}/>
  </Grid>
</Grid>
<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >Apellidos:</label>
    <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth id="authorLastname"  variant="outlined" 
      value={formAuthor.authorLastname}
      onChange={handleChangeAuthor}/>
    </Grid>
</Grid>
<Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <label className='labelBold' >Número de celular</label>
      </Grid>
      <Grid item xs={12} sm={12}>
          <TextField className='field' type='number' fullWidth id="authorMobile" 
          variant="outlined"  inputProps={{ maxLength: 10 }} 
          value={formAuthor.authorMobile}
          onChange={handleChangeAuthor}/>
      </Grid>
</Grid>
<Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
  <label className='labelBold' >Correo Electrónico:</label>
  <TextField style={{ marginBottom: '0.5rem' }} className='field' type='email' fullWidth id="authorEmail"  variant="outlined" 
    value={formAuthor.authorEmail}
    onChange={handleChangeAuthor}/>
  </Grid>
</Grid>

<Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
  <label className='labelBold' >ORCID:</label>
  <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth id="authorORCID"  variant="outlined" 
    value={formAuthor.authorORCID}
    onChange={handleChangeAuthor}/>
  </Grid>
</Grid>
</>
)}

{tabIndex===1 && (
  <>
  {/* TLA nuevo */}
  {/* <Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
  <label className='labelBold' >Institución:</label>
  <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth id="authorCompany"  variant="outlined" 
    value={formAuthor.authorCompany}
    onChange={handleChangeAuthor}/>
  </Grid>
</Grid>   */}
<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container  justifyContent='flex-start' alignItems='center'>
    {/* <IconButton aria-label="Borrar" size="large" onClick={handleChangeSwitch4}>
      <AddCircleOutlineTwoToneIcon color="rgb(0, 166, 206)" fontSize="large" />
    </IconButton> */}

    <Fab color="success" size="small" aria-label="add" onClick={handleChangeSwitch4}>
        <AddIcon color="rgb(0, 166, 206)" />
    </Fab>
    <label className='labelBold' >Filiaciones:</label>

  </Grid>
  </Grid>

 
  <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Número</TableCell>
                <TableCell>Institución</TableCell>
                <TableCell>Servicio</TableCell>
                <TableCell>Departamento</TableCell>
                <TableCell>Ciudad</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {temporalFiliations.length > 0 ? (
                temporalFiliations.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: '10%' }}>{index}</TableCell>
                    <TableCell style={{ width: '40%' }}>{item.companyName}</TableCell>
                    <TableCell style={{ width: '40%' }}>{item.companyService}</TableCell>
                    <TableCell style={{ width: '40%' }}>{item.companyDepartament}</TableCell>
                    <TableCell style={{ width: '40%' }}>{item.companyCity}</TableCell>
                    <TableCell style={{ width: '10%' }}>
                    <IconButton aria-label="Borrar" size="small" onClick={() => eliminarFiliacion(index)}>
                      <DeleteIcon color="secondary" fontSize="large" />
                    </IconButton>

                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">No se han agredado Filiaciones</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  </>
)}

{tabIndex===2 && (
  <>
  
      {/* TLA nuevo */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm lg xl container direction='column' justifyContent='center'>
            <label className='labelBold'>El autor declara algún conflicto de interes: </label>
            <RadioGroup aria-label="Speakerdo" name="authorConflict" value={state.authorConflict} onChange={handleAuthorConflict}>
                  {yesnot &&  yesnot.map((authorConflict) => (
                    <FormControlLabel
                      key={authorConflict.id}
                      value={authorConflict.id}
                      control={<BlueRadio />}
                      checked={state.authorConflict == authorConflict.id}
                      label={authorConflict.label} />
                  ))}
           </RadioGroup>
        </Grid>
      </Grid>
{state.authorConflict == 1 ? (
<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >Por favor describa el conflicto de interes:</label>
    </Grid>
    <textarea 
        style={{ marginBottom: '0.5rem', width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
        className='field' 
        id="authorConflictWich" name="authorConflictWich"
        value={formAuthor.authorConflictWich}
        onChange={handleChangeAuthor}
        // placeholder="Escribe el titulo del trabajo..."
        rows={2}
        cols={50}
    ></textarea>
</Grid>
): null}
  
  </>
)}

</Box>




  




</DialogContent>
<DialogActions style={{ justifyContent: 'center', margin: '1rem', marginTop: 0 }}>
<Button style={{ width: '100px' }} className='btnPrimary' onClick={() => {
  // setHeadQuarterChs(saveHeadquarterChs)
  // setLocationChs(saveLocationChs)
 setOpen4(false)
}} color="primary">
 Cancelar
</Button>
<Button style={{ width: '100px' }} className='btnPrimary' 
onClick={agregarAuthor} 
color="primary">
 Guardar
</Button>
</DialogActions>
</Dialog>

);
}




// TLA nuevo
const handleFile1Change = (e) => {
  setSelectedFile1(e.target.files[0]);  // Almacena el archivo seleccionado en el estado
};
const handleFile2Change = (e) => {
  setSelectedFile2(e.target.files[0]);  // Almacena el archivo seleccionado en el estado
};
const handleFile3Change = (e) => {
  setSelectedFile3(e.target.files[0]);  // Almacena el archivo seleccionado en el estado
};
const handleFile4Change = (e) => {
  setSelectedFile4(e.target.files[0]);  // Almacena el archivo seleccionado en el estado
};

const addInscription = async () =>{
      downloadPdf();
      const formData = new FormData();
      formData.append('pendienteId',props.propsDad.pendienteId);
      formData.append('idUser',props.user.user_susi_id);
      formData.append('articlePdf', pdfFile);
      formData.append('summaryPdf', pdfFile1);
      console.log("cierra inscripción");
      await closeInscription(formData);   
      //ENVIAR CORREO
      let email = window.sessionStorage.getItem('_displayMail');
      let params = {
      subject : ''.toString('utf8'),
      emailEnd: email,
      userId  : props.user.user_susi_id, 
      contenido: '',
      // inscriptionId: dataInscription['inscriptionId'];
      }
      let result = await sendMail(params)
      setTitleAlert('SOPORTE DE INSCRIPCIÓN');
      setMessageAlert('Ha sido enviado a su correo registrado el soporte de inscripción. Favor verifica');
      handleChangeStatus();
      setTemporalFiliations([]);
      setTemporalAuthors([]);
      setOpenPrint(false)
      setOpen3(false);
      if(props.propsDad.pendienteId){
        this.props.history.push({
          pathname: '/toreport'
        });  
        
      }
}

const updateState = (newState) => {
  return new Promise((resolve) => {
    setState((prevState) => {
      const updatedState = { ...prevState, ...newState };
      resolve(updatedState);
      return updatedState;
    });
  });
};

const addRegister = async () => {
  console.log("STATE EN GUARDAR");
  console.log(state);
  const formData = new FormData();
  const authorsObject = temporalAuthors.reduce((acc, author) => {
    acc[author.authorId] = author;
    return acc;
  }, {});

  setState({
    ...state,
    articleAuthors: authorsObject, // Si articleAuthors es un arreglo con un solo objeto, usa corchetes para crear un nuevo arreglo con temporalAuthors
  })
  const updatedState = await updateState({ articleAuthors: authorsObject });

  console.log("AUTORES");
  console.log(temporalAuthors);
  console.log(authorsObject);
  console.log(updatedState);
  // }
    // Agrega archivos al FormData
  formData.append('articleImage1', selectedFile1 ? selectedFile1 : null);
  formData.append('articleImage2', selectedFile2 ? selectedFile2 : null);
  formData.append('articleCarta' , selectedFile3 ? selectedFile3 : null);
  formData.append('articleEthic' , selectedFile4 ? selectedFile4 : null);

// Descargar el archivo y convertirlo en un Blob
//const blob = await (await fetch(state.pdfUrl)).blob();
//const blob1 = await (await fetch(state.pdfUrl1)).blob();
// Crear el objeto File a partir del Blob
//const pdfFile  = new File([blob], 'articlePdf.pdf', { type: 'application/pdf' });
//const pdfFile1 = new File([blob1], 'summaryPdf.pdf', { type: 'application/pdf' });
//  const pdfFile  = new File([await (await fetch(state.pdfUrl)).blob()], 'articlePdf.pdf', { type: 'application/pdf' });
//  const pdfFile1 = new File([await (await fetch(state.pdfUrl1)).blob()], 'summaryPdf.pdf', { type: 'application/pdf' });
  formData.append('pendienteId',props.propsDad.pendienteId);
  formData.append('articlePdf', pdfFile);
  formData.append('summaryPdf', pdfFile1);
  formData.append('competitionId', updatedState.competitionId);
  formData.append('presentationId', updatedState.presentationId);
  formData.append('categoryId', state.categoryId);
  formData.append('thematicAreaId', thematic);
  formData.append('idUser',props.user.user_susi_id);
  formData.append('memberIdcard',      updatedState.memberIdcard);
  formData.append('articleSubmitted',  updatedState.articleSubmitted);
  formData.append('articleSubmittedWhere',  updatedState.articleSubmittedWhere);
  formData.append('articleTitle',      updatedState.articleTitle);
  formData.append('articleShortTitle', updatedState.articleShortTitle);
  formData.append('articleKeyword1',   updatedState.articleKeyword1);
  formData.append('articleKeyword2',   updatedState.articleKeyword2);
  formData.append('articleKeyword3',   updatedState.articleKeyword3);
  formData.append('articleKeyword4',   updatedState.articleKeyword4);
  formData.append('articleKeyword5',   updatedState.articleKeyword5);
  formData.append('articleAuthors',    JSON.stringify(updatedState.articleAuthors));
  formData.append('articleObjetive',   updatedState.articleObjetive);
  formData.append('articleMethod',     updatedState.articleMethod);
  formData.append('articleResult',     updatedState.articleResult);
  formData.append('articleConclusion', updatedState.articleConclusion);
  formData.append('articleSubmitted',  updatedState.articleSubmitted);
  formData.append('articleSubmittedWhere', updatedState.articleSubmittedWhere);
  formData.append('pathfiles', process.env.PUBLIC_URL + DOCUMENTS_FOLDER);
  formData.append('inscriptionId', updatedState.inscriptionId);

  console.log("formdata");
  console.log(formData);
  const newRegister = {
    competitionId  :   updatedState.competitionId,
    presentationId :   updatedState.presentationId,
    categoryId     :   state.categoryId,
    thematicAreaId :   thematic,
    idUser         :   props.user.user_susi_id,
    memberIdcard:      updatedState.memberIdcard,
    articleSubmitted: updatedState.articleSubmitted,
    articleSubmittedWhere: updatedState.articleSubmittedWhere,
    articleTitle:      updatedState.articleTitle,
    articleShortTitle: updatedState.articleShortTitle,
    articleKeyword1:   updatedState.articleKeyword1,
    articleKeyword2:   updatedState.articleKeyword2,
    articleKeyword3:   updatedState.articleKeyword3,
    articleKeyword4:   updatedState.articleKeyword4,
    articleKeyword5:   updatedState.articleKeyword5,
    articleAuthors:    temporalAuthors,
    articleObjetive:   updatedState.articleObjetive,
    articleMethod:     updatedState.articleMethod,
    articleResult:     updatedState.articleResult,
    articleConclusion: updatedState.articleConclusion,
    articleImage1:     selectedFile1 && selectedFile1.name ? selectedFile1.name : null,
    articleImage2:     selectedFile2 && selectedFile2.name ? selectedFile2.name : null,
    articleCarta :     selectedFile3 && selectedFile3.name ? selectedFile3.name : null,
    articleEthic:      selectedFile4 && selectedFile4.name ? selectedFile4.name : null,
    
  };

  let errors = [];

  if (!newRegister.memberIdcard) {
    errors.push('Sin identificación del miembro ACHO .');
  }
  if (!newRegister.categoryId) {
    errors.push('No ha seleccionado categoría .');
  }  
  if (!newRegister.articleTitle) {
    errors.push('Sin título del trabajo a inscribir');
  }
  if (!newRegister.articleShortTitle) {
    errors.push('Sin título corto del trabajo a inscribir');
  }
  if (!newRegister.articleKeyword1 & !newRegister.articleKeyword2 & !newRegister.articleKeyword3
     & !newRegister.articleKeyword4 & !newRegister.articleKeyword5) {
    errors.push('No hay ninguna palabra clave');
  }

  if (!newRegister.articleObjetive) {
    errors.push('No ha incluido objetivos');
  }
  if (!newRegister.articleMethod) {
    errors.push('No ha incluido la sección de materiales y métodos');
  }
  if (!newRegister.articleResult) {
    errors.push('No se incluyo resultados');
  }
  if (!newRegister.articleConclusion) {
    errors.push('No se incluyo conclusiones');
  }

  if (temporalAuthors.length < 1){
    errors.push('No se ha registrado ningun author');
  }

  if (errors.length == 0 ) {  
      console.log("INSCRIPCION");
      console.log(newRegister);
      setTerminar(true);

//Graba el registro      TLA nuevo graba
     formData.append('inscriptionState', 0); 
      let dataInscription= await createInscription(formData)   
      if (dataInscription) {
         console.log("registro actualizado");
         console.log(dataInscription);
         state.inscriptionId = dataInscription.inscriptionId;
      }  
      toggleDialogPrint();

  } else {
    state.inscriptionState=0;
    setTerminar(false);
    formData.append('inscriptionState', 0);
    await createInscription(formData);
    toggleDialogPrint();
    // Concatenar todos los errores en una sola cadena de texto
    const errorMessage = errors.join('\n');
    
    // Mostrar mensaje de alerta con todos los errores
    //alert(errorMessage);
    setTitleAlert('VALIDACIÓN INSCRIPCIÓN');
    setMessageAlert(errorMessage);
    handleChangeStatus();
    setIsAuthorValid(false);
  }
  
};


const verificarRegister = async () => {

  let errors = [];

  if (!state.memberIdcard) {
    errors.push('Sin identificación del miembro ACHO .');
  }
  if (!state.articleTitle) {
    errors.push('Sin título del trabajo a inscribir');
  }
  if (!state.articleShortTitle) {
    errors.push('Sin título corto del trabajo a inscribir');
  }
  if (!state.articleKeyword1 & !state.articleKeyword2 & !state.articleKeyword3
     & !state.articleKeyword4 & !state.articleKeyword5) {
    errors.push('No hay ninguna palabra clave');
  }

  if (!state.articleObjetive) {
    errors.push('No ha incluido objetivos');
  }
  if (!state.articleMethod) {
    errors.push('No ha incluido la sección de materiales y métodos');
  }
  if (!state.articleResult) {
    errors.push('No se incluyo resultados');
  }
  if (!state.articleConclusion) {
    errors.push('No se incluyo conclusiones');
  }

  if (temporalAuthors.length < 1){
    errors.push('No se ha registrado ningun author');
  }

  if (!state.presentationId){
    errors.push('No se ha registrado en que va a participar');
  }

  if (!state.categoryId){
    errors.push('No se ha registrado ninguna categoría');
  }

  if (errors.length > 0 ) {  
    // Concatenar todos los errores en una sola cadena de texto
    const errorMessage = errors.join('\n');
    //alert(errorMessage);
    setTitleAlert('VALIDACIÓN INSCRIPCIÓN');
    setMessageAlert(errorMessage);
    handleChangeStatus();
    setIsAuthorValid(false);
  }
  toggleDialogPrint();
};


const handleCheckboxChange = (authorId) => {
  const updatedCheckedAuthors = { ...checkedAuthors, [authorId]: !checkedAuthors[authorId] };
  setCheckedAuthors(updatedCheckedAuthors);

  const updatedAuthors = temporalAuthors.map(author => {
    if (author.authorId == authorId) {
      return { ...author, authorRole: !checkedAuthors[authorId] };
    }
    return author;
  });
  setTemporalAuthors(updatedAuthors);
};

const handleReceiveMailChange = (authorId) => {
  const updatedCheckedReceiveMail = { ...checkedReceiveMail, [authorId]: !checkedReceiveMail[authorId] };
  setCheckedReceiveMail(updatedCheckedReceiveMail);

  const updatedAuthors = temporalAuthors.map(author => {
    if (author.authorId == authorId) {
      return { ...author, authorReceiveMail: !checkedReceiveMail[authorId] };
    }
    return author;
  });
  setTemporalAuthors(updatedAuthors);
};

const handleSpeakerChange = (authorId) => {
  const updatedCheckedSpeaker = { ...checkedSpeaker, [authorId]: !checkedSpeaker[authorId] };
  setCheckedSpeaker(updatedCheckedSpeaker);

  const updatedAuthors = temporalAuthors.map(author => {
    if (author.authorId == authorId) {
      return { ...author, authorSpeaker: !checkedSpeaker[authorId] };
    }
    return author;
  });
  setTemporalAuthors(updatedAuthors);
};



// TLA nuevo
const renderDialogRegister = () => {
  

return (

<div>
<Dialog  
  PaperProps={{
    style: {
      paddingBottom: '0 !important',
      //overflowY: 'auto',
      maxHeight: '97vh',
      width: '80vw',          // Ancho al 100% de la ventana del navegador
      maxWidth: 'none !important',  // Sin límite de ancho máximo
      opacity: '1 !important',              // Desactivar la opacidad
      scrollbarWidth: 'auto',  // Ancho del scrollbar
      scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
    }
  }} 
  fullWidth={false}
  maxWidth={false}
  className='dialogReounded dialogUbication' 
  open={open3} 
  aria-labelledby="form-dialog-title"
>
{/* <DialogTitle className='dialogTttle !important' id="alert-dialog-title" style={{ fontSize:'40px', textAlign:'center' }}>FORMULARIO DE INSCRIPCIÓN {' '.repeat(10)}(PALABRAS={totalPalabras})</DialogTitle> */}
<DialogTitle className='dialogTitle !important' 
id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'center', 
  color: green, alignItems:'center'}}>
      FORMULARIO DE INSCRIPCIÓN 
      <span style={{ marginLeft: '5px', color: green }}> (Palabras={totalPalabras})</span>
</DialogTitle>
<DialogContent style={{ paddingBottom: '0 !important', overflowY: 'hidden', maxHeight: '80vh'  }}>

      <Tabs value={tabIndex0} onChange={handleTabChange0} centered
      TabIndicatorProps={{ style: { backgroundColor: '#4caf50' } }}
      classes={{ indicator: classes.indicator }}>
        <Tab label="Aval"       className={classes.tab}/>
        <Tab label="General"    className={classes.tab}/>
        <Tab label="Titulo"     className={classes.tab}/>
        <Tab label="1- Resumen" className={classes.tab}/>
        <Tab label="2- Resumen" className={classes.tab}/>
        <Tab label="Autores"    className={classes.tab}/>
        <Tab label="Anexos"     className={classes.tab}/>
      </Tabs>

{tabIndex0 === 0 && (
<>
  <Grid container spacing={1}>
      <Grid item xs={12} sm={9}>
        <label className='labelBold' htmlFor="memberIdcard">Cédula de un miembro activo ACHO que avala la inscripción</label>
      </Grid>
      <Grid item xs={12} sm={3}>
          <TextField className='field' type='number' fullWidth 
            id="memberIdcard"  name="memberIdcard" 
            value={state.memberIdcard} 
            onChange={handleChange} 
            onBlur={handleBlur} required
            style={{ width: '100%' }} 
            variant="outlined"  inputProps={{ maxLength: 14 }} />
      </Grid>
  </Grid>
  <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
      <label className='labelBold' >Nombres miembro activo ACHO </label><br></br>
      </Grid>
      <Grid item xs={12} sm={12}>
      <label className='labelMiembro' >{state.memberFullName}</label>
      </Grid>
  </Grid>
</>
)}


{state.validations && state.validations.memberValid == '' && (
  <>

{tabIndex0 === 1 && (
<>
<Grid container spacing={1}>
        <Grid item xs={12} sm lg xl container direction='column' justifyContent='center'>
            <label className='labelBold'>Categoria: </label>
            <RadioGroup aria-label="categories" name="categoryId" value={state.categoryId} onChange={handleCategory}>
                  {props.propsDad.categories &&  props.propsDad.categories.map((categoria) => (
                    <FormControlLabel
                      key={categoria.categoryId}
                      value={categoria.categoryId}
                      control={<BlueRadio />}
                      checked={state.categoryId == categoria.categoryId}
                      label={categoria.categoryName} />
                  ))}
           </RadioGroup>
        </Grid>
</Grid>
<Grid container spacing={1}>
        <Grid item xs={12} sm lg xl container direction='column' justifyContent='center'>
            <label className='labelBold'>El trabajo ha sido Presentado en otro lado anteriormente: </label>
            <RadioGroup aria-label="Speakerdo" name="articleSubmitted" value={state.articleSubmitted} onChange={handleArticleSubmitted}>
                  {yesnot &&  yesnot.map((articleSubmitted) => (
                    <FormControlLabel
                      key={articleSubmitted.id}
                      value={articleSubmitted.id}
                      control={<BlueRadio />}
                      checked={state.articleSubmitted == articleSubmitted.id}
                      label={articleSubmitted.label} />
                  ))}
           </RadioGroup>
        </Grid>
</Grid>
{state.articleSubmitted == 1 ? (
<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >En donde se presentó anteriormente:</label>
    </Grid>
    <textarea 
        style={{ marginBottom: '0.5rem', width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
        className='field' 
        id="articleSubmittedWhere" name="articleSubmittedWhere"
        onChange={handleChange}
        // placeholder="Escribe el titulo del trabajo..."
        rows={1}
        cols={50}
    ></textarea>
</Grid>
): null}

<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
      <label className='labelBold' >Palabras Clave:</label>
    </Grid>
    <Grid item xs={6} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
     <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth 
     value={state.articleKeyword1} 
     onChange={handleChange} id="articleKeyword1" name="articleKeyword1"  variant="outlined" />
    </Grid>
    <Grid item xs={6} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
     <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth 
     value={state.articleKeyword2}
     onChange={handleChange} id="articleKeyword2" name="articleKeyword2" variant="outlined" />
    </Grid>
    <Grid item xs={6} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
     <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth 
     value={state.articleKeyword3}
     onChange={handleChange} id="articleKeyword3" name="articleKeyword3" variant="outlined" />
    </Grid>
    <Grid item xs={6} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
     <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth 
     value={state.articleKeyword4}
     onChange={handleChange} id="articleKeyword4" name="articleKeyword4" variant="outlined" />
    </Grid>
    <Grid item xs={6} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
     <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth 
     value={state.articleKeyword5}
     onChange={handleChange} id="articleKeyword5" name="articleKeyword5" variant="outlined" />
    </Grid>
</Grid>

</>
)}

{tabIndex0===2 && (
  <>
      {/* TLA nuevo */}

<Grid container spacing={3}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >Titulo del Trabajo:</label>
    {state.articleTitle && (
    <label className='labelBold' >
      {' '.repeat(10)} (Caracteres restantes {200 - state.articleTitle.replace(/\s/g, '').length})
    </label>
    )}
    </Grid>
    <textarea 
        style={{ marginBottom: '1rem', width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
        className='field' 
        id="articleTitle" name="articleTitle" value={state.articleTitle}
        onChange={handleChange}
        // placeholder="Escribe el titulo del trabajo..."
        rows={2}
        cols={50}
    ></textarea>

</Grid>
<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >Titulo corto del trabajo:</label>
    {state.articleShortTitle && (
    <label className='labelBold' >
      {' '.repeat(10)} (Caracteres restantes {50 - state.articleShortTitle.replace(/\s/g, '').length})
    </label>
    )}
    </Grid>
    <textarea 
        style={{ marginBottom: '0.5rem', width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
        className='field' 
        id="articleShortTitle" name="articleShortTitle" value={state.articleShortTitle}
        onChange={handleChange}
        // placeholder="Escribe el titulo del trabajo..."
        rows={1}
        cols={50}
    ></textarea>

 </Grid>
  </>
)}

{tabIndex0===3 && (
  <>
<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >Objetivos:</label>
    </Grid>
    <textarea 
        style={{ marginBottom: '0.5rem', width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
        className='field' 
        id="articleObjetive" name="articleObjetive" value={state.articleObjetive}
        onChange={handleChange}
        rows={6}
        cols={50}
    ></textarea>
</Grid>

<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >Materiales y Métodos:</label>
    </Grid>
    <textarea 
        style={{ marginBottom: '0.5rem', width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
        className='field' 
        id="articleMethod" name="articleMethod" value={state.articleMethod}
        onChange={handleChange}
        rows={6}
        cols={50}
    ></textarea>
</Grid>

  </>
)}
{/* <Editor
              apiKey='4kbnibmrsojzegoh7cxijw4b8sjui7oxr9o3qxq3okpmeq5b'
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={''}
              init={{
                width: '100%',
                height: 350,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Times New Roman,Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            /> */}


{tabIndex0===4 && (
  <>
<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >Resultados:</label>
    </Grid>
    <textarea 
        style={{ marginBottom: '0.5rem', width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
        className='field' 
        id="articleResult" name="articleResult" value={state.articleResult}
        onChange={handleChange}
        rows={6}
        cols={50}
    ></textarea>

</Grid>
<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >Conclusiones:</label>
    </Grid>
    <textarea 
        style={{ marginBottom: '0.5rem', width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
        className='field' 
        id="articleConclusion" name="articleConclusion" value={state.articleConclusion}
        onChange={handleChange}
        rows={6}
        cols={50}
    ></textarea>
</Grid>

  </>
)}

{tabIndex0===5 && (
<>
{/* TLA nuevo */}
<Grid container spacing={3}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start' alignItems='center'>
      <Fab color="success" size="small" aria-label="add" onClick={handleChangeSwitch3}>
        <AddIcon color="rgb(0, 166, 206)" />
      </Fab>
      <label className='labelBold' >Autores:</label>

  </Grid>
 </Grid>

 
  <Grid container justifyContent="center" alignItems="center" spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cédula</TableCell>
                <TableCell>Nombres</TableCell>
                <TableCell>Apellidos</TableCell>
                <TableCell>Celular</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Principal</TableCell>
                <TableCell>Rec.Corr.</TableCell>
                <TableCell>Speaker</TableCell>
                {/* <TableCell>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
            {temporalAuthors.length > 0 ? (
                temporalAuthors.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.authorId}</TableCell>
                    <TableCell>{item.authorName}</TableCell>
                    <TableCell>{item.authorLastname}</TableCell>
                    <TableCell>{item.authorMobile}</TableCell>
                    <TableCell>{item.authorEmail}</TableCell>
                    {/* <TableCell>{item.authorCompany}</TableCell> */}
                    <TableCell>
                        <Checkbox color="success"
                          checked={checkedAuthors[item.authorId] || false}
                          onChange={() => handleCheckboxChange(item.authorId)}
                        />
                    </TableCell>
                    <TableCell>
                        <Checkbox color="success"
                          checked={checkedReceiveMail[item.authorId] || false}
                          onChange={() => handleReceiveMailChange(item.authorId)}
                        />
                    </TableCell>
                    <TableCell>
                        <Checkbox color="success"
                          checked={checkedSpeaker[item.authorId] || false}
                          onChange={() => handleSpeakerChange(item.authorId)}
                        />
                    </TableCell>                    

                    <TableCell>
                    <IconButton aria-label="Borrar" size="small" onClick={() => eliminarAuthor(index)}>
                      <DeleteIcon color="secondary" fontSize="large" />
                    </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">No se han agregado autores</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>

</>
)}

{tabIndex0===6 && (
  <>
<Grid container spacing={1}>
             <Grid item xs sm="auto" lg="auto" xl={2}>
                 <Grid item style={{ display: 'flex', alignSelf: 'center' }}>
                    <label className='labelBold'>1. Subir primer gráfico y/o tabla:</label>
                    <label htmlFor="fileInput1" style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faUpload} className='uploadIcon' />
                      <input 
                        id="fileInput1"
                        type="file"
                        accept='application/pdf,image/jpeg,image/bmp'
                        style={{ display: 'none' }}
                        onChange={handleFile1Change} 
                      />
                    </label>
                    {selectedFile1 && <p>{selectedFile1.name}</p>}
                  </Grid>
           </Grid>
</Grid>
<Grid container spacing={1}>
             <Grid item xs sm="auto" lg="auto" xl={2}>
                 <Grid item style={{ display: 'flex', alignSelf: 'center' }}>
                    <label className='labelBold'>2. Subir segundo gráfico y/o tabla:</label>
                    <label htmlFor="fileInput2" style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faUpload} className='uploadIcon' />
                      <input 
                        id="fileInput2"
                        type="file"
                        accept='application/pdf,image/jpeg,image/bmp'
                        style={{ display: 'none' }}
                        onChange={handleFile2Change} 
                      />
                    </label>
                    {selectedFile2 && <p>{selectedFile2.name}</p>}
                  </Grid>
           </Grid>
</Grid>

{state.categoryId == 1 ? (
 <Grid container spacing={1}>          
           <Grid item xs sm="auto" lg="auto" xl={2}>
                 <Grid item style={{ display: 'flex', alignSelf: 'center' }}>
                    <label className='labelBold'>3. Subir Carta firmada por el paciente o su representante legal:</label>
                    <label htmlFor="fileInput3" style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faUpload} className='uploadIcon' />
                      <input 
                        id="fileInput3"
                        type="file"
                        accept='application/pdf,image/jpeg,image/bmp'
                        style={{ display: 'none' }}
                        onChange={handleFile3Change} 
                      />
                    </label>
                    {selectedFile3 && <p>{selectedFile3.name}</p>}
                  </Grid>
           </Grid>
</Grid>):null}

{state.categoryId == 3 ? (
              <Grid container spacing={1}>
                  <Grid item style={{ display: 'flex', alignSelf: 'center' }}>
                  <label className='labelBold'>4. Subir certificado comité de ética:</label>
                  <label htmlFor="fileInput4" style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faUpload} className='uploadIcon' />
                    <input 
                      id="fileInput4"
                      type="file"
                      accept='application/pdf,image/jpeg,image/bmp'
                      style={{ display: 'none' }}
                      onChange={handleFile4Change} 
                    />
                  </label>
                  {selectedFile4 && <p>{selectedFile4.name}</p>}
                </Grid>
            </Grid>
            ) : null}
  </>
)}
  </>
)}

</DialogContent>
<DialogActions style={{ justifyContent: 'center', margin: '1rem', marginTop: 0 }}>
 <Button style={{ width: '100px' }} className='btnPrimary' onClick={() => {
   setHeadQuarterChs(saveHeadquarterChs)
   setLocationChs(saveLocationChs)
   setOpen3(false)
 }} color="primary">
   Cancelar
 </Button>
 <Button
  style={{ width: '100px' }}
  className='btnPrimary'
  disabled={state.memberFullName == "" || isEmpty(state.memberFullName)
  || isEmpty(temporalAuthors)} // Corregido aquí
  onClick={addRegister}
  color="primary"
>
  Guardar
</Button>
</DialogActions>
</Dialog>
</div>
);
}


  /* Alert lost */

  const renderDialogtheftLost = () => {
    return (
      <Dialog className='dialogReounded dialogChangeLost' open={showDialogtheft} aria-labelledby="form-dialog-title">
        <DialogContent className='labelFlex' style={{ borderRadius: '1rem' }}>
          Señor usuario <br /><br />{textOption}
        </DialogContent>
        <DialogActions style={{ alignSelf: 'center', marginBottom: '1.5rem' }}>
          <Button onClick={() => setShowDialogtheft(false)} style={{ width: '100px' }} className='btnPrimary' color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>);
  }

  /* Email */

  const templateText = () => {
    return `
      <p style="color: #5f6273; font-weight: 400; font-size: 0.875rem;">
        Señores Inventarios
        <br><br>
        Se informa que una vez consultada la información del serie ${props.data.serieBien == null ? 'no identificado' : props.data.serieBien}, el sistema identificó la placa ${props.data.placaBien} a nombre de ${props.user.fullName}
        <br><br>
        Se solicita generar la correspondiente placa de codigo de barras por ${razonReimpresion.name}, con el fin de realizar la legalización.
        <br><br>
        ${props.user.fullName}
        <br><br>
        Correo electronico ${props.propsDad.user.email}
      </p>
    `;
  }

 
    const sendSoporte = async () => {
        let params = {
          subject: 'Solicitud de Información',
          emailEnd: email,
          contenido: this.state.contenido,
          // inscriptionId: dataInscription['inscriptionId'];
          }
          let result = await sendEmailSoporte(params)
          if (result) {
            // setEmailAlert(result)
            alert('Ha sido enviada una solicitud de soporte, pronto será atendida')
          } else {
            // setEmailAlert('')
            alert('No ha sido posible enviar su solicitud. Favor intenta de nuevo')
          }
          toggleDialog();
    };
  

  const send = async () => {
    if (editorRef.current) {
      setSended(true)
      let params = {
        subject: asuntoMail,
        emailEnd: email
      }
      //let result = await sendMail(params, editorRef.current.getContent(), ccmail)
      const nuevoContenido = "Este es el nuevo contenido que quiero establecer en el editor.";
     // editorRef.current.setContent(nuevoContenido);
      console.log("contenido");
      //console.log(editorRef.current.getContent());
      let result = await sendMail(params, {nuevoContenido}, ccmail)
      if (result) {
        setEmailAlert(result)
      } else {
        setEmailAlert('')
      }
    }
  };

  
const ExistRegister = () => {
  return (
    <div>AssignedAsset</div>
  )
}


  

  const handleChangeTypeReimpresion = (event) => {
    let optionData = props.propsDad.rePrintReasons.find(reimpresion => reimpresion.id == event.target.value)

    setRazonReimpresion(optionData)
  };

  useEffect(() => {
    if (razonReimpresion != null) {
      let seacrh = asuntoMail.split(' - ')
      let asuntoNuevo = seacrh[1] != undefined ?
        asuntoMail.replace(` - ${seacrh[1]}`, ` - por ${razonReimpresion.name}`) :
        asuntoMail + ` - por ${razonReimpresion.name}`

      setAsuntoMail(asuntoNuevo)
    }

  }, [razonReimpresion])

const handleDialogPendient = () => {
    setOpenPendient(true);
} 

  const handleCloseDialogPendient = (event) => {
    const id = event.currentTarget.id;
    if(id==1){
      setState([]);
    }else{
    
    let inscriptionPendiente = props.propsDad.myInscriptions.find(pendiente => pendiente.inscriptionState == 0);
    if(inscriptionPendiente){
      setState(inscriptionPendiente);
    }
      console.log("carga");
      console.log(inscriptionPendiente);
      console.log(state);
    }
    console.log("QUEDA PENDIENTE");
    console.log(state);
    setOpenPendient(false);
    setSwitchOption2(!switchOption2);
    setOpen3(true);
  }



  const renderDialogPendient = () => {
      return (
        <Dialog className='dialogReounded' open={openPendient}>
          <DialogTitle id="form-dialog-title">INSCRIPCIÓN PENDIENTE</DialogTitle>
          <DialogContent>
            <DialogContentText>
              En este momento usted tiene una inscripción que no ha sido terminada. Favor seleccionar la acción a seguir?
            </DialogContentText>
            {/* <List>
              <ListItem button onClick={() => alert("Vamos a compartir como imagen")} key="image">
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Como Imagen" />
              </ListItem>
              <ListItem button onClick={() => alert("Vamos a compartir como HTML")} key="html">
                <ListItemAvatar>
                  <Avatar>
                    <CommentIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Como HTML" />
              </ListItem>
            </List> */}
          </DialogContent>
          <DialogActions>
            <Button className='btnPrimary' id={1} onClick={handleCloseDialogPendient} color="secondary">
              Eliminar
            </Button>
            <Button className='btnPrimary' id={2} onClick={handleCloseDialogPendient} color="primary">
              Completar
            </Button>
          </DialogActions>
        </Dialog>
      );
  }  


  const renderDialogEmail = () => {
    return (
      <Dialog className='dialogReounded DialogEmail'
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {emailAlert.length == 0 && razonReimpresion != null ? <>
          <DialogTitle style={{ color: '#5f6273', fontWeight: '700', paddingBottom: 0, paddingLeft: '1rem' }}>{"Enviar correo electrónico"}</DialogTitle>
          <DialogContent id='DialogEmail'>
            <label className='labelStyle'>Para:</label>
            <TextField type="password" style={{ backgroundColor: 'transparent', color: '#5f6273' }} fullWidth id="placa" value={email} size={'small'} variant="outlined" onChange={e => setEmail(e.target.value)}  disabled />

            <label className='labelStyle'>CC</label>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              value={ccmail}
              onChange={(event, input) => {
                setCcEmail(input)
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant='outlined' style={{ backgroundColor: validateEmailString(option) ? '#dae2fc' : '#ef4682', color: validateEmailString(option) ? '#445dc9' : '#ffff', border: 0 }} color={validateEmailString(option) ? 'primary' : 'secondary'} label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField style={{ marginBottom: 7, color: '#5f6273' }}
                  className='select-filtros'
                  onBlur={(e) => {
                    if (e.target.value.length > 0) {
                      setCcEmail(ccmail.concat(e.target.value))
                    }
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 188 && e.target.value) {
                      setCcEmail(ccmail.concat(e.target.value))
                    }
                  }} {...params} type='email' variant="outlined" size={'small'} />
              )}
            />

            <label className='labelStyle'>Asunto</label>
            <TextField style={{ color: '#5f6273', backgroundColor: 'transparent' }} fullWidth id="asunto" value={'Solicitud de Información'} size={'small'} variant="outlined" disabled />

            <p style={{ color: '#5f6273', margin: '0.5rem 0' }}>Contenido</p>
            <textarea style={{ color: '#5f6273', backgroundColor: 'transparent' }} 
            fullWidth 
            value={this.state.contenido || ''} // contenido viene del estado
            onChange={(e) => this.setState({ contenido: e.target.value })} // actualiza el estado
            // placeholder="Escribe el titulo del trabajo..."
            rows={6}
            cols={50}
            >

            </textarea>
            {/* <Editor
              apiKey='3kene6jho40n97htmyaa9sspemiuioexws0ko6m36go2k62i'
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={''}
              init={{
                height: 350,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            /> */}
            {emailAlert.length > 0 && <Alert severity={'info'}>
              {emailAlert.message}
            </Alert>}
          </DialogContent>

          {sended === false ?
            <DialogActions className='assetDialogActions1'>
              <Button className='btnPrimary' onClick={toggleDialog} color="primary">
                Cancelar
              </Button>
              <Button className='btnPrimary' onClick={sendSoporte} color="primary" autoFocus>
                Enviar
              </Button>
            </DialogActions>
            :
            <DialogActions className='assetDialogActions2'>
              <Button className='btnSecundary' onClick={toggleDialog} color="primary">
                Cancelar
              </Button>
              <Button autoFocus className='btnPrimary'>
                {emailAlert.length === 0 ? 'Enviando...' : 'Enviado'}
              </Button>
            </DialogActions>}
        </> : emailAlert.length == 0 ? <DialogContent className='dialogMotivo'>
          <DialogTitle>{"Motivo por el cual está solicitando la renovación de la placa adhesiva"}</DialogTitle>
          <DialogActions>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <ButtonBase className='ContainerButtonGroup'>
                  <Grid container direction='row'>
                    {props.propsDad.rePrintReasons.length > 0 && props.propsDad.rePrintReasons.map(transact => {
                      return (
                        <Grid key={transact.id} item xs={12} sm={4} container alignItems='center'>
                          <Grid item xs="auto" sm="auto" lg="auto">
                            <BlueRadio
                              checked={razonReimpresion != null && razonReimpresion.id == transact.id}
                              onChange={handleChangeTypeReimpresion}
                              value={transact.id}
                              name={`${transact.id}`}
                            />
                          </Grid>
                          <Grid item xs sm lg="auto">
                            <Typography className='fontAa' variant="caption" display="block" gutterBottom>{transact.name}</Typography>
                          </Grid>
                        </Grid>)
                    })}
                  </Grid>
                </ButtonBase>
              </Grid>
              <Grid item>
                <Button className="btnSecundary btnCancelar" onClick={toggleDialog} color="primary">
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent> :
          <>
            <DialogTitle style={{ color: '#5f6273', fontWeight: '700', paddingBottom: 0, paddingLeft: '1rem' }}>
              {emailAlert.error ? 'Enviado correctamente' : 'Enviado correctamente'}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className='labelFlex' style={emailAlert.error ? {} : { marginBottom: '1rem' }}>{emailAlert.message}</Typography>
                </Grid>
                {emailAlert.error ?
                  <Grid item container>
                    <Grid item>
                      <b className='labelFlex'>Detalle del error:</b>
                    </Grid>

                    <Grid item>
                      <Typography className='labelFlex' style={{ paddingLeft: '1rem' }} paragraph>
                        {emailAlert.messageError}
                      </Typography>
                    </Grid>
                  </Grid>
                  : null}
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Button className='btnPrimary' style={{ margin: 'auto' }} onClick={toggleDialog} autoFocus>
                    Aceptar
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        }
      </Dialog>
    );
  };

  
// Pantalla principal 
  const ruta = process.env.PUBLIC_URL +  '/imagenEvento.jpg';

  useEffect(() => {
    if (props.propsDad.pendienteId > 0) {
      console.log("edicion");
      // Buscar en la lista myInscriptions
      const inscriptionPendiente = props.propsDad.myInscriptions.find(
        (item) => item.inscriptionId == props.propsDad.pendienteId
      );
     
      
      // Si se encuentra una inscripción, actualizar el estado
      if (inscriptionPendiente) {
        console.log(inscriptionPendiente);
        setInscriptionPendiente(inscriptionPendiente);
        console.log("TLA inscripcion buscada ****************************");
        console.log(inscriptionPendiente);
        setTemporalAuthors(inscriptionPendiente.authors);
        setThematic(inscriptionPendiente.thematicAreaId);
        setState(inscriptionPendiente); // Si necesitas actualizar el estado aquí
        setOpen3(true);
      }
    }
  }, []);
  



 return (
    <div className={classes.root + ' contenedorAsset'} >
      {/* <Paper variant="outlined" style={{ padding: '1rem 0', borderRadius: '2rem', border: 0, margin: 0, maxWidth: 'none', boxShadow: '3px 3px 20px 0px #d6e0f4', position: 'relative' }} className={changeType && headquarterChs && locationChs ? classes.paper : props.isOut ? classes.paperOut : classes.paper}></Paper> */}
      <Paper  style={{ padding: '0.5rem 0', borderRadius: '2rem', border: 0, margin: 0, maxWidth: 'none', boxShadow: '3px 3px 20px 0px ', position: 'relative' }} className={changeType && headquarterChs && locationChs ? classes.paper : props.isOut ? classes.paperOut : classes.paper}>
        <Grid container className='contenedorAssetGridMayor'>
          <Grid className='contenedorAssetGrid' item xs={12} sm={12} container>
            {/* <Grid className='columnaLogo' item xs sm={3} lg={2} style={{ padding: '0 0.5rem' }} justifyContent='center' container direction="column">
              <IconButton className="asset_icon" >
                <img className={classes.img} alt="Imagen Bien" src={props.url_imagen} />
              </IconButton>
              <Typography align="center" variant="caption" display="block" gutterBottom className='descripcionBien'> {props.data.descripcionBien}</Typography>
            </Grid> */}
<Grid className='columnaLogo' item xs sm={3} lg={2} style={{ padding: '0 0.5rem' }} justifyContent='center' container direction="column">
  <div style={{ width: '350px', height: '450px' }}>
    <img className={classes.img} alt="Imagen Evento" src={ruta} style={{ width: '100%', height: '100%' , opacity: '0.3' }} />
  </div>
  <Typography align="center" variant="caption" display="block" gutterBottom className='descripcionBien'> {props.data.descripcionBien}</Typography>
</Grid>
            <Grid item className='iconsAsset iconsAssetTop' xs sm>
              <IconButton style={{ padding: '5px' }} onClick={toggleDialog}>
                <FontAwesomeIcon style={{ color: '#80849d' }} icon={faBarcode} size="1x" />
              </IconButton>

              <IconButton style={{ padding: '5px' }} onClick={toggleDialogPrint} aria-label="delete">
                <PictureAsPdfIcon style={{ color: '#80849d' }} />
              </IconButton>

              <Tooltip style={{ background: '#ef4682' }} title={notifyAlert()} disableHoverListener={li.length <= 0}>
                <IconButton style={{ padding: '0px' }} color={'inherit'} aria-label="add an alarm">
                  <NotificationsActiveIcon style={{ color: li.length > 0 ? '#ef4682' : '#8487a0' }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid className='columnaInfo' item xs={12} sm={9} lg container>
              <Grid item xs container className='contenedorMedio'>
                <Grid item xs={12} container direction='row'>
                  <Grid item container xs={12}>
                    <Grid item align="center">
                        <Typography variant="caption" className="textCards" style={{fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                          {props.data.competition_title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography labelBold className="textCards" variant="caption" style={{fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                          {props.data.competition_description}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sm lg xl container direction='column'>
                      <Grid item className='dependenciaXs'>
                        <Typography variant="caption" className="textCards">
                          {props.data.competition_aimed_at}
                        </Typography>
                      </Grid>
                    </Grid>

                  </Grid>

                  <Grid item xs sm lg xl container >
                     <Grid item xs={4} sm={2} lg={2} xl={2}>
                        <Typography labelBold className="textCards" variant="caption" style={{fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                          Dirigido a:
                        </Typography>
                      </Grid>
                    {/* <Grid item xs={4} sm={2} lg={2} xl={2}>
                      <Typography noWrap variant="caption" labelBold>
                        Dirigido a:yyyy
                      </Typography>
                    </Grid> */}

                      <Grid item xs sm lg xl>
                        <Typography variant="caption" className="textCards" style={{fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                          {props.data.competition_aimed_at}
                        </Typography>
                      </Grid>
                  </Grid>
                  <FontAwesomeIcon style={{ height: '1.1rem', color: '#5f6273', fontSize: '1.813rem', position: 'absolute', right: '1.5rem', top: '1.5rem' }} icon={faEllipsisV} />
                </Grid>


                {/* <hr className='hrStyle' /> */}
                <Grid item xs={12} container >
                <Grid item xs={5} sm={3} lg={3} xl={3}>
                        <Typography labelBold className="textCards" variant="caption" style={{fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                          Inscripciones desde:
                        </Typography>
                    </Grid>
                    <Grid item xs={7} sm={9} lg={9} xl={9}>
                      <Typography variant="caption" className="textCards" style={{fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                        Julio 2 de 2024
                      </Typography>
                    </Grid>                </Grid>
                <Grid item xs={12} container >
                     <Grid item xs={5} sm={3} lg={3} xl={3}>
                        <Typography labelBold className="textCards" variant="caption" style={{fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                          Cierre:
                        </Typography>
                    </Grid>
                    <Grid item xs={7} sm={9} lg={9} xl={9}>
                      <Typography variant="caption" className="textCards" style={{fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                        Septiembre 16 de 2024
                      </Typography>
                    </Grid>
                </Grid>

                {/* <Grid item xs sm lg xl container >
                <Grid item>
                        <Typography variant="caption" labelBold>
                          <b > Inscripciones desde: Mayo 15 de 2024</b>
                        </Typography>
                </Grid>
                <Grid item>
                        <Typography style={{ color: '#5f6273' }} variant="caption">
                          <b className='bAsset'> - Cierre: Septiembre 15 de 2024 </b>
                        </Typography>
                </Grid>
                </Grid> */}
                    {/* <Typography
                      className="textCards" variant="caption" gutterBottom>
                      {headquarterChs != null && headquarterChs.length > 0 ? <>
                        {headquarterChs}<br />
                      </> : null}
                      {locationChs} */}


                      {/* <Button className='btnEditar1'
                        style={{ width: '100px' }}
                        id={1}
                        onClick={handleChangeSwitch2}
                        variant="outlined"
                        color={(headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? 'primary' : 'secondary')} size="small"
                      >
                        <p style={{ margin: 0, fontSize: '1.688rem', fontWeight: '600', textTransform: 'none' }}>
                          {headquarterChs.length > 0 ? 'Hematología' : locationChs != null ? 'Hematología' : 'Hematología'}
                        </p>
                        <EditIcon style={{ fontSize: "1.688rem" }} />
                      </Button> */}



                {/* <Grid item xs={12} container >
                  <br></br>
                </Grid> */}

                <Grid item xs={12} container>
                  <Grid item xs={3} sm={3} lg={3} xl={3}>
                    <Typography style={{ color: '#5f6273', fontWeight: 'bold' }} variant="h4" color="textSecondary" >
                      Inscribete en:
                    </Typography>
                  </Grid>
                <Grid item xs={3} sm={2} lg={2} xl={2}>
<ButtonGroup variant="text" aria-label="Basic button group">
<Button 
  style={{ 
    width: '120px', 
    minWidth: '120px', 
    height: 'auto', 
    padding: '10px',
    marginRight: '50px', 
  }}
  id={1}
  onClick={handleChangeSwitch2}
  variant="text" //outlined text 
  color={('error')} 
  size="large !important"
  disabled = "props.data.competition_state !=1"
>
  <span style={{ 
    fontSize: '1.4rem', 
    fontWeight: '800', 
    textTransform: 'none',
  }}>
    {'Hematología'}
  </span>
  <EditIcon style={{ fontSize: "1.5rem", marginLeft: '5px' }} />
</Button>
<Button 
  style={{ 
    width: '120px', 
    minWidth: '120px', 
    height: 'auto', 
    padding: '10px',
    marginRight: '60px', 
  }}
  id={2}
  disabled = "props.data.competition_state !=1"
  onClick={handleChangeSwitch2}
  variant="text"
  color={('error')}
  size="large !important"
>
  <span style={{ 
    fontSize: '1.4rem', 
    fontWeight: '800', 
    textTransform: 'none',
  }}>
    {'Oncología'}
  </span>
  <EditIcon style={{ fontSize: "1.5rem", marginLeft: '5px' }} />
</Button>
{/* <Button 
  style={{ 
    width: '120px', 
    minWidth: '120px', 
    height: 'auto', 
    padding: '10px',
  }}
  id={3}
  onClick={handleChangeSwitch2}
  variant="text"
  color={('secondary')}
  size="large !Important"
>
  <span style={{ 
    fontSize: '1.4rem', 
    fontWeight: '800', 
    textTransform: 'none',
  }}>
    {'Manejo Multidisciplinario'}
  </span>
  <EditIcon style={{ fontSize: "1.5rem", marginLeft: '5px' }} />
</Button> */}
</ButtonGroup>




                      {/* <Button className='btnEditar1'
                        style={{
                          marginLeft: '0.2rem', padding: 0, width: 'auto', height: 'rem',
                          border: `1px solid ${headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? '#445dc9' : '#ef4682'}`
                        }}
                        id={2}
                        onClick={handleChangeSwitch2}
                        variant="outlined"
                        color={(headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? 'primary' : 'secondary')} size="small"
                        >
                        <p style={{ margin: 0, fontSize: '0.688rem', fontWeight: '600', textTransform: 'none' }}>
                          {headquarterChs.length > 0 ? 'Oncología' : locationChs != null ? 'Oncología' : 'Oncología'}
                        </p>
                        <EditIcon style={{ fontSize: "0.688rem" }} />
                      </Button> */}
                      {/* <Button className='btnEditar1'
                        style={{
                          marginLeft: '0.2rem', padding: 0, width: 'auto', height: '1.375rem',
                          border: `1px solid ${headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? '#445dc9' : '#ef4682'}`
                        }}
                        id={3}
                        onClick={handleChangeSwitch2}
                        variant="outlined"
                        color={(headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? 'primary' : 'secondary')} size="small"
                      >
                        <p style={{ margin: 0, fontSize: '0.688rem', fontWeight: '600', textTransform: 'none' }}>
                          {headquarterChs.length > 0 ? 'Hemato-Oncología Pediatrica' : locationChs != null ? 'Hemato-Oncología Pediatrica' : 'Hemato-Oncología Pediatrica'}
                        </p>
                        <EditIcon style={{ fontSize: "0.688rem" }} />
                      </Button> */}
                      {/* <Button className='btnEditar1'
                        style={{
                          marginLeft: '0.2rem', padding: 0, width: 'auto', height: '1.375rem',
                          border: `1px solid ${headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? '#445dc9' : '#ef4682'}`
                        }}
                        id={4}
                        onClick={handleChangeSwitch2}
                        variant="outlined"
                        color={(headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? 'primary' : 'secondary')} size="small"
                      >
                        <p style={{ margin: 0, fontSize: '0.688rem', fontWeight: '600', textTransform: 'none' }}>
                          {headquarterChs.length > 0 ? 'Manejo Multidisciplinario' : locationChs != null ? 'Manejo Multidisciplinario' : 'Manejo Multidisciplinario'}
                        </p>
                        <EditIcon style={{ fontSize: "0.688rem" }} />
                      </Button>
                      <Button className='btnEditar2'
                        style={{ marginLeft: '0.2rem', padding: 0, width: 'auto', height: '1.375rem' }}
                        onClick={handleChangeSwitch2}
                        color={(headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? 'primary' : 'secondary')} size="small"
                      >
                        <EditIcon style={{ fontSize: "0.988rem" }} />
                      </Button> */}
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} container direction='row' className='estadosContainer'>
                  <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column' style={{ alignSelf: 'center' }}>
                    <Grid item>
                      <Typography variant="caption">
                        <b className='bAsset'>Estado del bien: </b>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={9} lg={4} xl={4} container justifyContent='space-between' alignContent='center'>
                    <ButtonBase color="primary" className='estado' aria-label="add to shopping cart">
                      <Typography className='fontAa' variant="caption" display="block" gutterBottom>Bueno</Typography>
                      <FormControlLabel
                        control={<MaterialUISwitch sx={{ m: 0 }} />}
                        checked={assetStatus}
                        onChange={handleChangeStatus}
                      />
                      <Typography className='fontAa' variant="caption" display="block" gutterBottom>Regular</Typography>
                    </ButtonBase>
                  </Grid> */}

                  {/* <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column' style={{ alignSelf: 'center' }}>
                    <Grid item>
                      <Typography variant="caption">
                        <b className='bAsset'>¿Verificado físicamente?:</b>
                      </Typography>
                    </Grid>
                  </Grid> */}

                  {/* <Grid item xs={8} sm lg={4} xl={4} container direction='column' justifyContent='center'>
                    <ButtonBase style={{ justifyContent: 'flex-start' }} className='estadoFaltante' color="inherit" display="flex">
                      <BlueRadio
                        checked={changeType == getIdOptionSUSIList('Verificado', props.changesTypes)}
                        onChange={handleChangeType}
                        value={getIdOptionSUSIList('Verificado', props.changesTypes)}
                        name="radio-button-demo"
                      />
                      <Typography className='fontAa' variant="caption" display="block" gutterBottom>Si</Typography>

                      <RedRadio
                        checked={changeType == getIdOptionSUSIList('Faltante', props.changesTypes)}
                        onChange={handleChangeType}
                        value={getIdOptionSUSIList('Faltante', props.changesTypes)}
                        name="radio-button-demo"
                      />
                      <Tooltip title={observations != null ? observations : ' '} disableHoverListener={observations == null || changeType != getIdOptionSUSIList('Faltante', props.changesTypes)}>
                        <Typography className='fontAa' variant="caption" display="block" gutterBottom>
                          No{changeType == getIdOptionSUSIList('Faltante', props.changesTypes) ? ': ' + getNameOptionSUSIList(missingReason, props.missingReasons) : ''}
                        </Typography>
                      </Tooltip>
                    </ButtonBase>
                  </Grid> */}
                {/* </Grid> */}

                <Grid item container alignItems='center' justifyContent='space-between'>
                  {/* <Grid item xs={4} sm={2} lg={2}>
                    {props.data.date_checklist != null && new Date(props.data.date_checklist).getFullYear() == new Date().getFullYear() ?
                      <CheckCircleOutlineIcon style={{ color: '#46cb4c', fontSize: '1.5rem', marginRight: '0.5rem' }} /> // verde
                      : props.data.date_checklist !== null && new Date() >= new Date(`${1}/${1}/${culminationDate.getFullYear()}`) && new Date() < culminationDate ?
                        <CheckCircleOutlineIcon style={{ color: '#EAB130', fontSize: '1.5rem', marginRight: '0.5rem' }} /> // amarillo
                        :
                        <CheckCircleOutlineIcon style={{ color: '#ef4682', fontSize: '1.5rem', marginRight: '0.5rem' }} /> // rojo
                    }
                    <Typography variant="caption" className="textCards">
                      Última verificación:
                    </Typography>
                  </Grid> */}

                  {/* <Grid item xs sm>
                    <Typography variant="caption" className="textCards">
                      {props.data.date_checklist != null ? new Date(props.data.date_checklist).toLocaleDateString("es-ES", configDate) : 'No Registrado'}
                    </Typography>
                  </Grid> */}
<Grid item xs={12} sm={12} lg={12} xl={12} container className='iconsAsset iconsAssetBottom' justifyContent='center' alignItems='center'>
  <Grid item xs sm="auto" lg="auto" xl={6}>
    <IconButton style={{ padding: '0px' }} onClick={toggleDialog}>
      <Typography variant="body1" style={{ marginRight: '8px', fontFamily: 'Times', fontWeight: 'bold', fontSize: '14px', color: '#000' }}>Mayor Información</Typography>
      <AlternateEmailIcon fontSize="large" style={{ color: '#00A6CE' }} icon={faBarcode} size="1x" />
    </IconButton>
  </Grid>

  <Grid item xs sm="auto" lg="auto" xl={6}>
    <IconButton style={{ padding: '0px' }} onClick={toggleDialogConvoca} aria-label="delete">
      <Typography variant="body1" style={{ marginRight: '8px', fontFamily: 'Times', fontWeight: 'bold', fontSize: '14px', color: '#000' }}>Convocatoría</Typography>
      <PictureAsPdfIcon fontSize="large" style={{ color: '#00A6CE' }} />
    </IconButton>
  </Grid>
</Grid>
                  {/* <Grid item xs={12} sm={12} lg={12} xl={12} container className='iconsAsset iconsAssetBottom' justifyContent='flex-end'>
                    <Grid item xs sm="auto" lg="auto" xl={6}>
                      <IconButton style={{ padding: '0px' }} onClick={toggleDialog}>
                        <Typography variant="caption" style={{ marginRight: '8px' }}>Mayor Información</Typography>
                        <AlternateEmailIcon fontSize="large" style={{ color: '#80849d' }} icon={faBarcode} size="1x" />
                      </IconButton>
                    </Grid>

                    <Grid item xs sm="auto" lg="auto" xl={6}>
                      <IconButton style={{ padding: '0px' }} disabled onClick={toggleDialogPrint} aria-label="delete">
                        <Typography variant="caption" style={{ marginRight: '8px' }}>Convocatoría</Typography>
                        <PictureAsPdfIcon fontSize="large" style={{ color: '#80849d' }} />
                      </IconButton>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {renderDialogStatusBad()}
      {renderDialogChangeTypeLost()}
      {renderDialogRegister()}
      {renderDialogAuthors()}
      {renderDialogFiliations()}
      {renderDialogShare()}
      {renderDialogEmail()}
      {renderDialogPrint()}
      {renderDialogConvoca()}
      {renderDialogPendient()}
      {renderDialogtheftLost()}

    </div >
  );

  
};

export default AssignedAsset