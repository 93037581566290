import {
  FETCH_SCORE_ERROR,
  FETCH_SCORE_SUCCESS,
  FETCH_SCORE_REQUEST,
} from "../types/index";
import {REACT_APP_BACKEND_URL} from "../../config";

import axios from "axios";

export const fetchScore = (params) => (dispatch) => {
  let paramsStrings = "";
  for (const param in params) {
    if (paramsStrings.length > 0) {
      paramsStrings += "&";
    }
    paramsStrings += param + "=" + params[param];
  }

  dispatch({ type: FETCH_SCORE_REQUEST });
  axios.get(REACT_APP_BACKEND_URL + "/getScore.php?" + paramsStrings).then((resp) => {
      dispatch({
        type: FETCH_SCORE_SUCCESS,
        payload: {
          score: resp.data
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: FETCH_SCORE_ERROR,
        error: e.toString(),
      });
    });
};
