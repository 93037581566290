import { FETCH_AUTH_USER_ERROR, FETCH_AUTH_USER_REQUEST, FETCH_AUTH_USER_SUCCESS } from "../types"
import {REACT_APP_BACKEND_URL} from "../../config";

import axios from 'axios';

export const fetchLogin = (params) => (dispatch) => {
    console.log("TLA params de login");
    console.log(params);
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
      };

    dispatch({ type: FETCH_AUTH_USER_REQUEST })
    axios.post(REACT_APP_BACKEND_URL + 'getUsuario.php', params,{
        headers: headers
      }).then(res => {
        //TLA seguimiento
        console.log("TLA res.data ",res.data[0]);
        dispatch({
            type: FETCH_AUTH_USER_SUCCESS,
            payload: {
//                token: res.data.access_token,
//                culmination_date: res.data.CULMINATION_DATE,
//                limit_file_size: res.data.LIMIT_FILE_SIZE

                token: res.data.clave,
                culmination_date: new Date(),
                limit_file_size: 1024
            }
        });
        console.log("datos a asignar usuario session");
        console.log(res.data);
        if (res.data[0].idUser) {     
            console.log("asigna variables sessionStorage")
            window.sessionStorage.setItem('_displayName', res.data[0].name);
            window.sessionStorage.setItem('_fullName', res.data[0].lastname + " " + res.data[0].name);
            window.sessionStorage.setItem('_displayMail', res.data[0].email);
            window.sessionStorage.setItem('_userToken', res.data[0].password);
            window.sessionStorage.setItem('_numeroDocumento', res.data[0].idUser);
            window.sessionStorage.setItem('userrole', res.data[0].role);
            window.sessionStorage.setItem('userCompany', res.data[0].companyId);
            window.sessionStorage.setItem('scopeRole', res.data[0].scopeRole);
            window.sessionStorage.setItem('evaluationType', res.data[0].evaluationType);
            window.sessionStorage.setItem('CORREO_REPORTAR_REIMPRESION_PLACA', res.data[0].email);
            window.sessionStorage.setItem('CORREO_REPORTAR_SOBRANTES', res.data[0].email);
            window.sessionStorage.setItem('NOTI_CULMINATION_DATE', new Date());
            window.sessionStorage.setItem('SESION_CULMINATION_TIME', new Date());
            window.sessionStorage.setItem('LIMIT_FILE_SIZE', 1024);
            window.sessionStorage.setItem('CORREO_GESTION_FALTANTES', res.data[0].email);

        // if (res.data.access_token) {     
        //     window.sessionStorage.setItem('_displayName', res.data.displayName);
        //     window.sessionStorage.setItem('_fullName', res.data.fullName);
        //     window.sessionStorage.setItem('_displayMail', res.data.mail);
        //     window.sessionStorage.setItem('_userToken', res.data.access_token);
        //     window.sessionStorage.setItem('_numeroDocumento', res.data.numero_documento);
        //     window.sessionStorage.setItem('CORREO_REPORTAR_REIMPRESION_PLACA', res.data.CORREO_REPORTAR_REIMPRESION_PLACA);
        //     window.sessionStorage.setItem('CORREO_REPORTAR_SOBRANTES', res.data.CORREO_REPORTAR_SOBRANTES);
        //     window.sessionStorage.setItem('NOTI_CULMINATION_DATE', res.data.NOTI_CULMINATION_DATE);
        //     window.sessionStorage.setItem('SESION_CULMINATION_TIME', new Date());
        //     window.sessionStorage.setItem('LIMIT_FILE_SIZE', res.data.LIMIT_FILE_SIZE);
        //     window.sessionStorage.setItem('CORREO_GESTION_FALTANTES', res.data.CORREO_GESTION_FALTANTES);
            console.log("antes de ir");
            //dispatch(dataUser(res.data.numero_documento)).then(() => {
            //console.log("cuando llega");
            window.location.reload();
            // });
            // dispatch(dataUser(res.data.numero_documento)); //TLA cambio
            // console.log("cuando llega");
            // window.location.reload();  
        } else {

            window.sessionStorage.removeItem('_userToken');
        }
    }).catch(e => {
        console.error('Error al iniciar sesión: ',e);
        dispatch({
            type: FETCH_AUTH_USER_ERROR,
            payload: {
                errorMessage: e.toString()
            }
        })
    })
}
