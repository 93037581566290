import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {
  Tabs, Tab, Box, Typography, Card, CardContent, LinearProgress, Grid, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import {
  PieChart, Pie, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { makeStyles } from '@mui/styles';
import { fetchDashboard } from "../../redux/actions/dashboardActions";
// import CategoryIcon from '@mui/icons-material/Category';

const useStyles = makeStyles({
  card: {
    marginBottom: 20,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  icon: {
    fontSize: 40,
  },
  progress: {
    height: 10,
    borderRadius: 5,
  },
  largeText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  select: {
    minWidth: 120,
  },
});

const categories = [
  { id: 1, nombre: 'Categoría 1', registrados: 400, pendientes: 100 },
  { id: 2, nombre: 'Categoría 2', registrados: 300, pendientes: 50 },
  { id: 3, nombre: 'Categoría 3', registrados: 500, pendientes: 150 },
];

const titulosCard = [
  { id: 1, title: 'Inscripciones' },
  { id: 2, title: 'Área temática' },
  { id: 3, title: 'Categoría' },
];

// const dataLine = [
//   { month: 'Jan', registrados: 30, pendientes: 10 },
//   { month: 'Feb', registrados: 20, pendientes: 5 },
//   { month: 'Mar', registrados: 50, pendientes: 15 },
//   { month: 'Apr', registrados: 75, pendientes: 20 },
//   { month: 'May', registrados: 40, pendientes: 10 },
//   { month: 'Jun', registrados: 60, pendientes: 25 },
//   { month: 'Jul', registrados: 90, pendientes: 30 },
//   { month: 'Aug', registrados: 100, pendientes: 35 },
//   { month: 'Sep', registrados: 70, pendientes: 25 },
//   { month: 'Oct', registrados: 80, pendientes: 30 },
//   { month: 'Nov', registrados: 60, pendientes: 20 },
//   { month: 'Dec', registrados: 90, pendientes: 35 },
// ];

const colors = ['#0088FE', 'red', '#00C49F', '#FFBB28', '#FF8042'];
const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

const years = ['2022', '2023', '2024']; // Lista de años disponibles para selección

const Dashboard = (props) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [globalValue, setGlobalValue] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [selectedYear, setSelectedYear] = useState('2022'); // Año seleccionado para el gráfico de líneas

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleCardClick = (categoria) => {
    setSelectedCategory(categoria);
    setGlobalValue(categoria.registrados);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    props.dispatch(fetchDashboard());
  }, [])

  const dataLine =  props.dashboard.dashboard.dataLine && 
  props.dashboard.dashboard.dataLine.map((item) => ({
  year        : item.año,
  month       : meses[item.mes-1],
  registrados : item.inscritos,
  pendientes  : item.pendientes,
  }));

  const pieData1 =  props.dashboard.dashboard.estadoInscripcion && 
    props.dashboard.dashboard.estadoInscripcion.map((item, index) => ({
    name: item.estadoInscripcion,
    value: item.cantidad,
    color: colors[index % colors.length],
  }));
  const pieData2 = props.dashboard.dashboard.category && 
  props.dashboard.dashboard.category.map((item, index) => ({
  name : item.categoryName,
  value: item.cantidad,
  color: colors[index % colors.length],
  }));

  const pieData3 = props.dashboard.dashboard.thematicAreas && 
  props.dashboard.dashboard.thematicAreas.map((item, index) => ({
    name: item.estadoInscripcion,
    value: item.cantidad,
    color: colors[index % colors.length], // Asigna el color correspondiente desde el arreglo colors
  }));
  // Función para personalizar las etiquetas
  const renderCustomizedLabel1 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
   
    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderCustomizedLabel2 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
 
    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderCustomizedLabel3 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
 
    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


  return (
    <Box sx={{ width: '100%', height: '80vh', overflow: 'auto' }}>
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Inscripciones" />
        <Tab label="Evaluaciones" />
        <Tab label="Calificaciones" />
      </Tabs>
      <Box p={3}>
        {tabIndex === 0 && (
          <>
            <Grid container spacing={3}>
              {titulosCard.map((categoria) => (
                <Grid item xs={12} sm={6} md={4} key={categoria.id}>
                  <Card className={classes.card} sx={{ height: '30%' }} onClick={() => handleCardClick(categoria)}>
                    <CardContent>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          {/* <CategoryIcon className={classes.icon} /> */}
                        </Grid>
                        <Grid item>
                          <Typography variant="h5">{categoria.title}</Typography>
                          <Typography className={classes.largeText}>{categoria.registrados}</Typography>
                        </Grid>
                      </Grid>
                      
                      <Box mt={2}>

                      {categoria.id === 1 && (
                        props.dashboard.dashboard.estadoInscripcion && 
                        Array.isArray(props.dashboard.dashboard.estadoInscripcion) && 
                        props.dashboard.dashboard.estadoInscripcion.length > 0 ? (
                          props.dashboard.dashboard.estadoInscripcion.map((item, index) => (
                            <Box key={item.id} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <Box display="flex" flexBasis="50%" flexShrink={0} flexGrow={0}>
                              <Typography variant="body1" style={{ width: '100%' }}>
                                {item.estadoInscripcion}
                              </Typography>
                            </Box>
                            <Box display="flex" flexBasis="30%" flexShrink={0} flexGrow={0} justifyContent="flex-end">
                              <Typography variant="body1">
                                {item.cantidad}
                              </Typography>
                            </Box>
                            <Box width={16} height={16} bgcolor={colors[index % colors.length]} ml={2}></Box>
                          </Box>
                          ))
                        ) : (
                          <Typography variant="body1">No hay datos disponibles</Typography>
                        )
                      )}

                      {categoria.id === 2 && (
                        props.dashboard.dashboard.category && 
                        Array.isArray(props.dashboard.dashboard.category) && 
                        props.dashboard.dashboard.category.length > 0 ? (
                          props.dashboard.dashboard.category.map((item, index) => (

                            <Box key={item.id} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <Box display="flex" flexBasis="50%" flexShrink={0} flexGrow={0}>
                              <Typography variant="body1" style={{ width: '100%' }}>
                                {item.categoryName}
                              </Typography>
                            </Box>
                            <Box display="flex" flexBasis="30%" flexShrink={0} flexGrow={0} justifyContent="flex-end">
                              <Typography variant="body1">
                                {item.cantidad}
                              </Typography>
                            </Box>
                            <Box width={16} height={16} bgcolor={colors[index % colors.length]} ml={2}></Box>
                          </Box>
                          ))
                        ) : (
                          <Typography variant="body1">No hay datos disponibles</Typography>
                        )
                      )}

                      {categoria.id === 3 && (
                        props.dashboard.dashboard.thematicAreas && 
                        Array.isArray(props.dashboard.dashboard.thematicAreas) && 
                        props.dashboard.dashboard.thematicAreas.length > 0 ? (
                          props.dashboard.dashboard.thematicAreas.map((item, index) => (
                            <Box key={item.id} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <Box display="flex" flexBasis="50%" flexShrink={0} flexGrow={0}>
                              <Typography variant="body1" style={{ width: '100%' }}>
                                {item.area_description}
                              </Typography>
                            </Box>
                            <Box display="flex" flexBasis="30%" flexShrink={0} flexGrow={0} justifyContent="flex-end">
                              <Typography variant="body1">
                                {item.cantidad}
                              </Typography>
                            </Box>
                            <Box width={16} height={16} bgcolor={colors[index % colors.length]} ml={2}></Box>
                          </Box>
                          ))
                        ) : (
                          <Typography variant="body1">No hay datos disponibles</Typography>
                        )
                      )}
                      </Box>
                      <Box mt={2}>
                        <LinearProgress variant="determinate" value={(categoria.registrados / 500) * 100} className={classes.progress} />
                      </Box>
                    </CardContent>
                  </Card>
                  {categoria.id === 1 && (
                  <Card className={classes.card}>
                    <CardContent>
                      {/* <Typography variant="h6">General</Typography> */}
                      <ResponsiveContainer width="100%" height={300}>
                        
                        <PieChart>
                          <Pie
                            data={pieData1}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onClick={() => handleCardClick(categoria)}
                            label={renderCustomizedLabel1}
                          >
                            {[{ name: 'Registrados', value: categoria.registrados }, { name: 'Pendientes', value: categoria.pendientes }].map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={['blue', 'red'][index]} />
                            ))}
                          </Pie>
                        </PieChart>

                      </ResponsiveContainer>
                    </CardContent>
                  </Card>
                  )}
                  {categoria.id === 2 && (
                  <Card className={classes.card}>
                    <CardContent>
                      {/* <Typography variant="h6">Por área</Typography> */}
                      <ResponsiveContainer width="100%" height={300}>

                        <PieChart>
                          <Pie
                            data={pieData2}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onClick={() => handleCardClick(categoria)}
                            label={renderCustomizedLabel2}
                          >
                          {pieData2 && pieData2.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </Card>
                  )}

                {categoria.id === 3 && (
                  <Card className={classes.card}>
                    <CardContent>
                      {/* <Typography variant="h6">Por temática</Typography> */}
                      <ResponsiveContainer width="100%" height={300}>

                        <PieChart>
                          <Pie
                            data={pieData3}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onClick={() => handleCardClick(categoria)}
                            label={renderCustomizedLabel3}
                          >
                          {pieData3 && pieData3.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </Card>
                  )}

                </Grid>
              ))}
            </Grid>
            {/* <Box mt={3}>
              <FormControl className={classes.select}>
                <InputLabel>Año</InputLabel>
                <Select
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box> */}
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6">Seguimiento Inscripciones</Typography>
                <Box width="100%" height={300}>
                  <ResponsiveContainer>
                    <LineChart
                      data={dataLine}
                      margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="registrados" stroke="#8884d8" activeDot={{ r: 8 }} />
                      <Line type="monotone" dataKey="pendientes" stroke="#82ca9d" activeDot={{ r: 8 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
            <Typography variant="h4">Global Value: {globalValue}</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  // assetSurplus: state.assetSurplus,
  // historyList: state.inventoryHistory,
  params: state.getActualParams,
  // withoutFillingOuts: state.withoutFillingOut,
  user: state.user,
  // assignedAssets: state.assignedAssets,
  // locations: state.locations.locations,
  // assetsStatus: state.SUSILists.assetsStatus,
  // changesTypes: state.SUSILists.changesTypes,
  // missingReasons: state.SUSILists.missingReasons,
  // reprintReasons: state.SUSILists.reprintReasons,
  // sectionalsUser: state.sectionals,
  // stages: state.stages,
  // SyncSIAF: state.syncSIAF.isFetching,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  Dashboard
);

