import React from "react";
import { connect } from "react-redux";
import { fetchAssetsStatus, fetchChangesTypes, fetchMissingReasons, fetchTransactTypeDocument, fetchTypeDocument, fetchTypeReplacementStatus, fetchTypeTransact, getNameOptionSUSIList } from "../../redux/actions/SUSIListsActions";

import { Alert, AlertTitle } from "@material-ui/lab";

import { CircularProgress, Grid } from "@material-ui/core";

import InfiniteScroll from "react-infinite-scroll-component";
import { fetchAssetStages } from "../../redux/actions/assetStagesActions";
import { updateParamSearch } from "../../redux/actions/paramsSearchAction";
import { fetchAllNotifications } from "../../redux/actions/notificationsActions";

import ReportMissingAsset from "./ReportMissingAsset";
import { fetchReportMissings } from "../../redux/actions/reportMissingActions";
import { fetchSectionalsUser } from "../../redux/actions/sectionalsActions";
import { fetchLocations } from "../../redux/actions/locationsActions";
import { fetchCategory } from "../../redux/actions/SUSIListsActions";

class ReportMissingAssetsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsFetchAssets: {
        identification: props.user.numero_documento,
        page: this.props.reportMissings.page,
        sectional: "",
        estado: "",
        descripcionBien: "",
        dependencia: "",
        placa: "",
        novedad: "",
        orderDescripcion: "",
        orderPlaca: "",
        orderSerie: "",
      },
      paramsFetchSectionals: {
        user_susi_id: props.user.user_susi_id,
      },
      heightWindow: window.innerHeight - 200,
    };
    this.fetchMore = this.fetchMore.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchCategory());
    this.props.dispatch(fetchReportMissings(this.state.paramsFetchAssets, true));
    this.props.dispatch(fetchAssetsStatus());
    this.props.dispatch(fetchChangesTypes());
    this.props.dispatch(fetchAssetStages());
    this.props.dispatch(fetchMissingReasons());
    this.props.dispatch(fetchLocations());
    this.props.dispatch(fetchTypeTransact());
    this.props.dispatch(fetchTransactTypeDocument());
    this.props.dispatch(fetchTypeDocument());
    this.props.dispatch(fetchTypeReplacementStatus());
    this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1 }));
    this.props.dispatch(fetchSectionalsUser(this.state.paramsFetchSectionals));
  }

  componentDidUpdate() {
    this.props.dispatch(updateParamSearch(this.state.paramsFetchAssets));
  }

  getURLAsset(assetFile) {
    if (assetFile)
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/" +
        assetFile +
        ".svg"
      );
    else
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/no-image.svg"
      );
  }

  getStateName(stateId, cont) {
    let stateCount = 0
    for (let i = 0; i < this.props.reportMissings.novedades.length; i++) {
      const element = this.props.reportMissings.novedades[i];

      if (element.ASAS_MIRE_ID == stateId) {
        stateCount += element.CANTIDAD
      }
    }

    let stateSusi = getNameOptionSUSIList(stateId, this.props.missingReasons)

    if (cont != 0) {
      let diferent =
        this.props.reportMissings.reportMissings[cont - 1].novedadId !==
        this.props.reportMissings.reportMissings[cont].novedadId;
      return diferent ? `BIENES REPORTADOS POR ${stateSusi.toUpperCase()}` : "";
    }
    /*       
        return diferent ? `BIENES REPORTADOS POR ${stateSusi.toUpperCase()} (${stateCount})` : "";
        return `BIENES REPORTADOS POR ${stateSusi.toUpperCase()} (${stateCount})`;
     */
    return `BIENES REPORTADOS POR ${stateSusi.toUpperCase()}`;
  }

  updateParamsAssets(secc, dep, est, desBien, placa, novedad, serie, orders = []) {
    this.setState(
      {
        paramsFetchAssets: {
          identification: this.props.user.numero_documento,
          page: 1,
          sectional: secc,
          dependencia: dep,
          estado: est,
          descripcionBien: desBien,
          placa: placa,
          novedad: novedad,
          serie,
          orderDescripcion: orders[0],
          orderPlaca: orders[1],
          orderSerie: orders[2],
          orderRegional: orders[3],
        },
      },
      () => {
        this.props.reportMissings.assets = undefined
        this.props.dispatch(fetchReportMissings(this.state.paramsFetchAssets));
        this.props.dispatch(updateParamSearch(this.state.paramsFetchAssets));
      }
    );
  }


  /* static getDerivedState */

  handleScroll(e) {
    const top = Math.trunc(e.target.scrollHeight - e.target.scrollTop);
    const bottom = e.target.clientHeight;

    if (bottom === top) {
      let jasper = Object.assign({}, this.state.paramsFetchAssets);
      jasper.page = this.props.reportMissings.page + 1;
      this.props.dispatch(fetchReportMissings(jasper));
    }
  }

  fetchMore() {
    setTimeout(() => {
      let jasper = Object.assign({}, this.state.paramsFetchAssets);
      jasper.page = this.props.reportMissings.page + 1;
      this.props.dispatch(fetchReportMissings(jasper));
      this.props.dispatch(updateParamSearch(jasper));
    }, 1500);
  }

  render() {
    window.addEventListener('resize', () => {
      if ((window.innerHeight - 200) != this.state.heightWindow) {
        this.setState({ heightWindow: window.innerHeight - 200 })
      }
    });

    return (
      <Grid container>
        <Grid item xs={12}>
          {!this.props.reportMissings.mount && this.props.reportMissings.reportMissings.length > 0 && 
          this.props.missingReasons.length > 0 && this.props.susiList.typeTransact.length > 0 && 
          this.props.susiList.replacementStatus.length > 0 ? (
              <InfiniteScroll
                dataLength={this.props.reportMissings.reportMissings.length}
                next={this.fetchMore}
                className="InfiniteScroll"
                hasMore={this.props.reportMissings.hasMore}
                height={this.state.heightWindow}
                loader={
                  <div style={{ display: "flex", justifyContent: "center", marginTop: '1rem' }}>
                    <CircularProgress className="circular-progress" />
                  </div>
                }
              >
                {this.props.reportMissings.reportMissings.map((asset, cont) => {
                  return (
                    <div key={asset.id}>
                      <h5 className="seccional">{this.getStateName(asset.novedadId, cont)}</h5>
                      <ReportMissingAsset
                        key={asset.placaBien + cont}
                        data={asset}
                        url_imagen={this.getURLAsset(asset.icon)}
                        propsDad={this.props}
                      />
                    </div>
                  )
                })}
              </InfiniteScroll>
            ) : this.props.reportMissings.isFetching ?
              <div style={{ display: "flex", justifyContent: "center", marginTop: '1rem' }}>
                <CircularProgress className="circular-progress" />
              </div>
              : !this.props.reportMissings.isFetching && this.props.reportMissings.reportMissings.length === 0 ? (
                <Grid item xs={12} className="container" style={{ padding: "20px" }}>
                  <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    No hay resultados para la busqueda.
                  </Alert>
                </Grid>
              ) :
                <div style={{ display: "flex", justifyContent: "center", marginTop: '1rem' }}>
                  <CircularProgress className="circular-progress" />
                </div>}
        </Grid>
      </Grid>
    )

  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    missingReasons: state.SUSILists.missingReasons,
    reportMissings: state.reportMissingReducer,
    susiList: state.SUSILists
  }
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ReportMissingAssetsList);
