import React from 'react';
import { Provider } from 'react-redux';
import axios from 'axios';
import './App.css';

import Main from './pages/Main/page';
import store from './redux/store';

import CssBaseline from '@mui/material/CssBaseline';

import { green } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { lime, purple } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: lime,
    secondary: purple,
  },
});

// intercept all request and add authorization token header
axios.interceptors.request.use(
  (req) => {
     // Add configurations here
     let token =  window.sessionStorage.getItem('_userToken');
     //  add authorization header only request to backend
     if(token !== null && req.url.includes(process.env.REACT_APP_BACKEND_URL)) {
      req.headers = {
        ...req.headers,
        Authorization: 'Bearer ' + token
      };
     }
     return req;
  },
  (err) => {
     return Promise.reject(err);
  }
);

function App() {  
  return (

    <Provider store={ store }>     

    <ThemeProvider theme={theme}>
      {/* <CssBaseline />
      <main color='primary'>This app is using the dark mode</main>
      <Button variant="contained">Primary</Button>
      <Button variant="contained" color="secondary" sx={{ ml: 2 }}> */}
        {/* Secondary */}
      {/* </Button> */}
      <Main/>
    </ThemeProvider>


              
    </Provider>

  );
}

export default App;
