//import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      margin: 'auto',
      /* height: 220, */
      marginBottom: 10
    },
    paperOut:{
      backgroundColor: '#ffffa3',
      padding: theme.spacing(1),
      margin: 'auto',
      /* height: 220, */
      maxWidth: 1000,
      marginBottom: 10
    },
    text: {
      padding: 0,
      margin: 0,
      
    },
    image: {
      width: 128,
      height: 128,
    },
    image2: {
      width: 20,
      height: 20,
    },
    img: {
      margin: 'auto',
      display: 'block',
      height: '2.5rem'
    },
    tab: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#4caf50', // Letra verde
      transition: 'all 0.3s ease',
      '&:hover': {
        color: '#f44336', // Letra roja al pasar el mouse por encima
      },
      '&.Mui-selected': {
        color: '#4caf50', // Letra verde cuando está seleccionado
        backgroundColor: '#e8f5e9', // Fondo verde claro cuando está seleccionado
        borderRadius: '8px', // Borde redondeado
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra para resaltar el tab
        fontWeight: 'bold', // Fuente en negrita
      },
      '&.Mui-disabled': {
        color: '#000 !important', // Letra negra cuando está desactivado
        fontWeight: 'bold !important', // Negrita cuando está desactivado
      },
    },
    indicator: {
      backgroundColor: '#f44336', // Indicador rojo
    },
  }));
  
  export default useStyles;