import { FETCH_MEMBER_REQUEST, FETCH_MEMBER_SUCCESS, FETCH_MEMBER_ERROR } from "../types";
import {REACT_APP_BACKEND_URL} from "../../config";

import axios from 'axios';

export const fetchMember = (params={}) => (dispatch) => { 
    //params.text = "";        
    //if(params.texto){   
        dispatch({type: FETCH_MEMBER_REQUEST});
        //console.log("Esta es la URL del servidor de Locations: ", process.env.REACT_APP_BACKEND_URL+'/locations');   
        axios.get(REACT_APP_BACKEND_URL+'/getMembers.php')
            .then( res => {
                // console.log("ESTA ES LA RESPUESTA DE LOCATIONS", res.data);
                dispatch({
                    type: FETCH_MEMBER_SUCCESS,
                    payload: {
                        members: res.data,
                        
                    }
                })
            })
            .catch(error => {
                console.log('Error en busqueda de miembros ACHO: ',error.toString());
                dispatch({
                    type: FETCH_MEMBER_ERROR,
                    payload: {
                        error: error.toString()
                    }
                })
            })
    //}        
}